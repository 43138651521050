export const authConfig = (hostname = window.location.hostname) => {
  switch (true) {
    case hostname.includes('beehive') || hostname.includes('dchp') || hostname.includes('localhost'):
      return {
        dev: {
          applicationId: 'f906530b-8392-4e52-b911-6b4c102ebde7',
          redirect: '/',
          authority:
            'https://ascensionidqa.b2clogin.com/ascensionidqa.onmicrosoft.com',
          scopes: [
            'https://ascensionidqa.onmicrosoft.com/member-experience-api/app.access',
            'https://ascensionidqa.onmicrosoft.com/member-experience-api/user_impersonation'
          ],
          loginPolicy: 'B2C_1A_Signup_SignIn_Member_qa'
        },
        qa: {
          applicationId: 'f906530b-8392-4e52-b911-6b4c102ebde7',
          redirect: '/',
          authority:
            'https://ascensionidqa.b2clogin.com/ascensionidqa.onmicrosoft.com',
          scopes: [
            'https://ascensionidqa.onmicrosoft.com/member-experience-api/app.access',
            'https://ascensionidqa.onmicrosoft.com/member-experience-api/user_impersonation'
          ],
          loginPolicy: 'B2C_1A_Signup_SignIn_Member_qa'
        },
        uat: {
          applicationId: '6c2ca298-6aae-4733-8d1c-8e13c195e195',
          redirect: '/',
          authority:
            'https://ascensionidqa.b2clogin.com/ascensionidqa.onmicrosoft.com',
          scopes: [
            'https://ascensionidqa.onmicrosoft.com/member-experience-api-uat/app.access',
            'https://ascensionidqa.onmicrosoft.com/member-experience-api-uat/user_impersonation'
          ],
          loginPolicy: 'B2C_1A_Signup_SignIn_Member_uat'
        },
        prod: {
          applicationId: '9add3ef0-a1da-4da4-b334-b9d2574a63f0',
          redirect: '/',
          authority: 'https://id.ascension.org/id.ascension.org',
          scopes: [
            'https://id.ascension.org/member-experience-api/app.access',
            'https://id.ascension.org/member-experience-api/user_impersonation'
          ],
          loginPolicy: 'B2C_1A_Signup_SignIn_Member'
        }
      };
    case hostname.includes('smarthealth'):
      return {
        dev: {
          applicationId: '420525a9-1430-4706-9359-38f7276b048c',
          redirect: '/',
          authority:
            'https://ascensionidqa.b2clogin.com/ascensionidqa.onmicrosoft.com',
          scopes: [
            'https://ascensionidqa.onmicrosoft.com/member-experience-api/app.access',
            'https://ascensionidqa.onmicrosoft.com/member-experience-api/user_impersonation'
          ],
          loginPolicy: 'B2C_1A_Signup_SignIn_Member_qa'
        },
        qa: {
          applicationId: '420525a9-1430-4706-9359-38f7276b048c',
          redirect: '/',
          authority:
            'https://ascensionidqa.b2clogin.com/ascensionidqa.onmicrosoft.com',
          scopes: [
            'https://ascensionidqa.onmicrosoft.com/member-experience-api/app.access',
            'https://ascensionidqa.onmicrosoft.com/member-experience-api/user_impersonation'
          ],
          loginPolicy: 'B2C_1A_Signup_SignIn_Member_qa'
        },
        uat: {
          applicationId: '420525a9-1430-4706-9359-38f7276b048c',
          redirect: '/',
          authority:
            'https://ascensionidqa.b2clogin.com/ascensionidqa.onmicrosoft.com',
          scopes: [
            'https://ascensionidqa.onmicrosoft.com/member-experience-api/app.access',
            'https://ascensionidqa.onmicrosoft.com/member-experience-api/user_impersonation'
          ],
          loginPolicy: 'B2C_1A_Signup_SignIn_Member_uat'
        },
        prod: {
          applicationId: '420525a9-1430-4706-9359-38f7276b048c',
          redirect: 'auth://org.ascension.memberexperience/420525a9-1430-4706-9359-38f7276b048c',
          authority: 'https://id.ascension.org/id.ascension.org',
          scopes: [
            'https://id.ascension.org/member-experience-api/app.access',
            'https://id.ascension.org/member-experience-api/user_impersonation'
          ],
          loginPolicy: 'B2C_1A_Signup_SignIn_Member'
        }
      };
    default:
      console.error('Failed to get auth config!');
      return null;
  }
};
export const manageAccountConfig = {
  qa: {
    url: 'https://ascensionid-userprofile-editor-qa.pub.cloud-02.pcf.ascension.org/'
  },
  prod: {
    url: 'https://profile.ascension.org'
  }
};

export const baseApiConfig = {
  dev: {
    url: 'https://dev-api.ascension.org',
    memberKey: 'FCULowINknGSjHCS1rOzzLNV3tLJcft8',
    memberKeyTenantConfig: '1qisoPkeADhtXo0GWhFKA46cw2rg1Lgz',
    providerKey: 'oO4Ac1E17E8Ucfj4Bp1enGuANqejSqHW',
    chatTarget: 'ABS_TestChat'
  },
  qa: {
    url: 'https://test-api.ascension.org',
    memberKey: 'FCULowINknGSjHCS1rOzzLNV3tLJcft8',
    memberKeyTenantConfig: '1qisoPkeADhtXo0GWhFKA46cw2rg1Lgz',
    providerKey: 'oO4Ac1E17E8Ucfj4Bp1enGuANqejSqHW',
    chatTarget: 'ABS_TestChat'
  },
  uat: {
    url: 'https://uat-api.ascension.org',
    memberKey: 'FCULowINknGSjHCS1rOzzLNV3tLJcft8',
    memberKeyTenantConfig: '1qisoPkeADhtXo0GWhFKA46cw2rg1Lgz',
    providerKey: 'oO4Ac1E17E8Ucfj4Bp1enGuANqejSqHW',
    chatTarget: 'ABS_TestChat'
  },
  prod: {
    url: 'https://prod-api.ascension.org',
    memberKey: 'GIrTeGrmYLbw39YGedqzDbevt7oxeANN',
    searchKey: '',
    chatTarget: 'ABS_Chat'
  }
};

export const gaConfig = {
  dev: 'G-1BN3GMPGJ5',
  qa: 'G-CRNT84X4L0',
  uat: 'G-7Z6ZM4D6JV',
  prod: 'G-DZTVW032BF'
};

// TODO: ADD STRIPE CONFIG / REFACTOR CONFIG STRUCTURE
export const stripeConfig = {
  nonProd:
    'pk_test_51J19egCzdYVqjhwjg2dU99ubxgnerMOKNZ5fnSHkb9uLZ47F2hg7CoXd0RLSkYnw3AhnFhoZAcW8j5hR4JFwOSjo00QZcQuD30',
  prod: 'pk_live_51J19egCzdYVqjhwjd3rAwjsQ2rrcjaxbJsApllQNlvp9MT9TkewVqqGe0s9rmeMPZwV8OEZtiYGDyn4MgADGXTYF00K4Vubnxm'
};

// TODO: ADD AMPLITUDE PROD APIKEY 
export const amplitudeConfig = {
  nonProd:
    '6ba891b4595be6e587d39aaee1ae8abc',
  prod: '36a9a14b885f3dbfef8124bbf2449884'
};


export const featureFlags = {
  nonProd: {
    isAutoPayEnabled: true
  },
  uat: {
    isAutoPayEnabled: true
  },
  prod: {
    isAutoPayEnabled: false
  }
};

export const getEnvVars = (host = window.location.hostname) => {
  switch (true) {
    case host.includes('localhost'):
      return {
        apiUrl: baseApiConfig.dev.url,
        memberKey: baseApiConfig.dev.memberKey,
        memberKeyTenantConfig: baseApiConfig.dev.memberKeyTenantConfig,
        providerKey: baseApiConfig.dev.providerKey,
        authConfig: authConfig().dev,
        gaTrackingId: gaConfig.dev,
        stripePubKey: stripeConfig.nonProd,
        amplitudeApiKey: amplitudeConfig.nonProd,
        chatTarget: baseApiConfig.dev.chatTarget,
        featureFlags: featureFlags.nonProd.isAutoPayEnabled,
        manageAccountTarget: manageAccountConfig.qa.url
      };
    case host.includes('-dev'):
      return {
        apiUrl: baseApiConfig.dev.url,
        memberKey: baseApiConfig.dev.memberKey,
        memberKeyTenantConfig: baseApiConfig.dev.memberKeyTenantConfig,
        providerKey: baseApiConfig.dev.providerKey,
        authConfig: authConfig().dev,
        gaTrackingId: gaConfig.dev,
        stripePubKey: stripeConfig.nonProd,
        amplitudeApiKey: amplitudeConfig.nonProd,
        chatTarget: baseApiConfig.dev.chatTarget,
        featureFlags: featureFlags.nonProd.isAutoPayEnabled,
        manageAccountTarget: manageAccountConfig.qa.url
      };
    case host.includes('-qa'):
      return {
        apiUrl: baseApiConfig.qa.url,
        memberKey: baseApiConfig.qa.memberKey,
        memberKeyTenantConfig: baseApiConfig.qa.memberKeyTenantConfig,
        providerKey: baseApiConfig.qa.providerKey,
        authConfig: authConfig().qa,
        gaTrackingId: gaConfig.qa,
        stripePubKey: stripeConfig.nonProd,
        amplitudeApiKey: amplitudeConfig.nonProd,
        chatTarget: baseApiConfig.qa.chatTarget,
        featureFlags: featureFlags.nonProd.isAutoPayEnabled,
        manageAccountTarget: manageAccountConfig.qa.url
      };
    case host.includes('-uat'):
      return {
        apiUrl: baseApiConfig.uat.url,
        memberKey: baseApiConfig.uat.memberKey,
        memberKeyTenantConfig: baseApiConfig.uat.memberKeyTenantConfig,
        providerKey: baseApiConfig.uat.providerKey,
        authConfig: authConfig().uat,
        gaTrackingId: gaConfig.uat,
        stripePubKey: stripeConfig.nonProd,
        amplitudeApiKey: amplitudeConfig.nonProd,
        chatTarget: baseApiConfig.uat.chatTarget,
        featureFlags: featureFlags.uat.isAutoPayEnabled,
        manageAccountTarget: manageAccountConfig.qa.url
      };
    case host.includes(
      'dchp-member.com' ||
      'beehive-web.pub.cloud-03.pcf.ascension.org'
    ):
      return {
        apiUrl: baseApiConfig.prod.url,
        memberKey: baseApiConfig.prod.memberKey,
        authConfig: authConfig().prod,
        gaTrackingId: gaConfig.prod,
        stripePubKey: stripeConfig.prod,
        amplitudeApiKey: amplitudeConfig.prod,
        chatTarget: baseApiConfig.prod.chatTarget,
        featureFlags: featureFlags.prod.isAutoPayEnabled,
        manageAccountTarget: manageAccountConfig.prod.url
      };
    default:
      console.error('Failed to get env config!');
      return null;
  }
};
