import React from 'react';
import { useSelector } from 'react-redux';
import RowContainer from '../../common/CustomComponents/RowContainer';
import { usePageTitle } from '../../common/hooks/usePageTitle';

const MemberSupport = () => {
  const tenantLayouts = useSelector(state => state.tenant.tenantLayout);
  const memberSupportLayout = tenantLayouts && tenantLayouts.moreSupportPage;

  usePageTitle(memberSupportLayout && memberSupportLayout.pageTitle);

  return (
    memberSupportLayout && (
      <div
        className={memberSupportLayout.className}
        id={memberSupportLayout.id}
      >
        {memberSupportLayout.sections.map((item, index) => {
          return <RowContainer key={index} {...item} />;
        })}
      </div>
    )
  );
};

export default MemberSupport;
