import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { CircularProgress, Grid, IconButton, ListItem } from '@mui/material';
import SubMenu from '../../CustomComponents/SubMenu';
import TextComponent from '../../CustomComponents/TextComponent';
import CustomIcon from '../../CustomComponents/CustomIcon';
import { useDispatch, useSelector } from 'react-redux';
import { getClaimDetails } from '../../../../redux/claims/actions';

const ClaimDetails = ({ claim, options, ...rest }) => {
  const claimsDetailsLoading = useSelector(state => state?.claims?.claimDetailsLoading);
  const claimsDetails = useSelector(state => state?.claims?.claimDetails);

  const [subMenu, setSubMenu] = useState(false);
  const [subMenuAnchor, setSubMenuAnchor] = React.useState(null);
  const dispatch = useDispatch();

  useEffect(()=> {
    if(claim){
        dispatch(getClaimDetails(claim.claimNumber, localStorage.getItem('tenantHandle')));
    }
  },[]);

  const openSubMenu = (anchorEl) => {
    setSubMenu(true);
    setSubMenuAnchor(anchorEl);
};

const closeSubMenu = () => {
  setSubMenu(false);
};
  return (
    <>{claim &&
    <ListItem {...rest}>
    <Grid container className="claimsDetailMainWrapper">      
      <Grid container alignItems="center">
          <Grid item sm={2} className='claimsDetailsDateWrapper'>
            <div className='claimsDetailsDateBox'>
              <TextComponent className='monthText'>
                {moment(claim.serviceDateTo).format('MMM').toUpperCase()}
              </TextComponent>
              <TextComponent className='claimsDetailsDateSeviceTO'>
                {moment(claim.serviceDateTo).format('D')}
              </TextComponent>
            </div>
          </Grid>

          <Grid item xs={12} sm={9} className='claimsDetailsProviderWrapper'>
            
          <TextComponent className="providerNameHeading">
            {claim.providerName}
          </TextComponent>
          <TextComponent className='claimsDetailsProviderClaimNumber'>
          {'#'+claim.claimNumber}
          </TextComponent>

          </Grid>
          
        </Grid>
        <div className='claimsDetailsMemberNameWrapper'>
          <TextComponent id='member' className='claimsDetailsMemberHeading' />
          <TextComponent>
            {claim.firstName}
          </TextComponent>
        </div>
    </Grid>
    <Grid container className="claimsDetailSecondaryWrapper">
      <Grid container alignItems="center" mr={2}>
        <Grid item xs={12} sm={6} className='claimsDetailsTotalChargesWrapper'>
          
          <TextComponent id='billed' className='claimsDetailsTotalChargesHeading' />
          <TextComponent >
            ${claim.totCharge?.toFixed(2)}
          </TextComponent>
        </Grid>
        <Grid item xs={12} sm={6} className='claimsDetailsTotalOweWrapper'>
          <TextComponent id='youOwe' className='claimsDetailsTotalOweHeading'/>
          <TextComponent className='claimsDetailsTotalOweText'>
           {claimsDetailsLoading && !claimsDetails ? <CircularProgress size='1rem' /> :`$${claimsDetails?.totalYouOwe}`}
          </TextComponent>
        </Grid>
      </Grid>
      <IconButton className='clamisDetailsSubMenu' onClick={(e)=> openSubMenu(e.currentTarget)}>
        <CustomIcon iconName='MoreVert' />
      </IconButton>
    </Grid>
    </ListItem>}
    {subMenu && <SubMenu className='claimsSubMenu' options ={options} onClose={closeSubMenu} anchor={subMenuAnchor} />}

</>
  );

};

ClaimDetails.propTypes = {
  claim: PropTypes.object.isRequired,
  options:PropTypes.array
};

export default ClaimDetails;
