import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { captilizeString } from '../../functionUtils';
import PropTypes from 'prop-types';
import Label from '../../type/Label';
import CustomIcon from '../CustomIcon';

const SmarthHealthMemberCard = (props) => {
 
    return (props.config &&
        <Grid container style={{paddingTop:28}} alignItems="center">
            <Grid style={{ paddingLeft: 40,fontWeight:600 }} item xs={4}>
            <h5 className="nameTextHeading">{`${captilizeString('Sadia')} ${captilizeString('Ameen')}`}</h5>
            </Grid>
             {/* <Grid item xs>
              FIXME: || Hiding temporarily for sep/1st release. Recommendation is to replace subscriberId with MedicaidId 
                <Box>
                  <Typography>
                    <strong>ID Number</strong>
                  </Typography>
                  <Typography>{member?.subscriberId}</Typography>
                </Box> 
              </Grid>*/}
           {props.details && <Grid item xs={4.5}>
                <Box>
                    <Typography  className='labelCard'>Plan Name</Typography>
                    <Typography  className='planNameValue' >{props.details}</Typography>
                </Box>
            </Grid>}
            <Grid item xs={3}>
                <Button
                
                    variant="outlined"
                    color="primary"
                    onClick={e => {
                        e.preventDefault();
                        props.buttonAction(props.member);
                    }}
                >
                 
                    <CustomIcon iconName={props.config.buttonIcon}/>
                 <Label id={props.config.buttonId} />
                </Button>
            </Grid>
        </Grid>
    );
};

SmarthHealthMemberCard.propTypes = {
    member: PropTypes.any,
    config:PropTypes.object,
    buttonAction: PropTypes.func,
    className:PropTypes.string,
    details:PropTypes.string
};
export default SmarthHealthMemberCard;
