import constants from './constants';

export const initialState = {
  plan: {
    name: '',
    status: '',
    description: '',
    hasFamilyInPlan: false,
    deductableAmountMet: 0,
    deductableMaxAmount: 0,
    outOfPocketAmountMet: 0,
    outOfPocketMaxAmount: 0,
    individualMaxDeductable: 0,
    individualMaxOutOfPocket: 0,
    individuals: []
  },
  rxPlan: {},
  rxCost: {},
  medicalCoverage: [],
  coverage: {},
  error: null,
  Loading:true
};

export  const planDetailsReducer= (state = initialState, { type, payload }) => {


  switch (type) {
    case constants.SET_PLAN_DETAILS_BEGIN: {
      return {
        plan: {},
        Loading:true
      };
    }
    case constants.SET_PLAN_DETAILS_ERROR: {
      return {
        plan: initialState.plan,
        Loading:true
      };
    }
    case constants.SET_PLAN_DETAILS_SUCCESS: {
      const { status, plan, individualPlan } = payload.plans[0];
      const hasFamilyInPlan = payload.hasFamilyInPlan;
      return {
        plan: {
          hasFamilyInPlan:hasFamilyInPlan,
          status:status,
          deductableAmountMet: parseFloat(plan.amtMet, 10),
          deductableMaxAmount: parseFloat(plan.dedAmt, 10),
          outOfPocketAmountMet: parseFloat(plan.outOfPocketMaxAmtMet, 10),
          outOfPocketMaxAmount: parseFloat(plan.outOfPocketAmt, 10),
          individualMaxDeductable:
            hasFamilyInPlan && individualPlan&&parseFloat(individualPlan[0].memDedAmt, 10),
          individualMaxOutOfPocket:
            hasFamilyInPlan &&
            individualPlan&&parseFloat(individualPlan[0].memOutOfPocketAmt, 10),
          individuals:
            hasFamilyInPlan &&individualPlan&&
            individualPlan.map(ind => ({
              firstName: ind.firstName,
              lastName: ind.lastName,
              deductableAmountMet: parseFloat(ind.memAmtMet, 10),
              outOfPocketAmountMet: parseFloat(ind.memoutofPocketMaxAmtMet, 10)
            }))
        },
        Loading:false
      };
    }

    
    default:
      return state;
  }
};
