import React, { useEffect, useState } from 'react';
import { usePageTitle } from '../../common/hooks/usePageTitle';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import AscensionCard from '../home/AscensionCard';
import MemberCard from '../../common/CustomComponents/MemberCard';



const CoveredMembersTabPanel = () => {
  const dependentEnrollments = useSelector((state) => state.dependentEnrollments);
  const memberAccountsData = useSelector(state => state?.memberAccounts?.memberAccountsDetails);
  const selfEnrollmentsFromStore = useSelector(state => state?.enrollments?.enrollmentsDetails);
  const tenantResource = useSelector(state => state.tenant.tenanantResource);
  const tenantProducts = useSelector(state => state.tenant.tenantProducts);
  const tenantLayouts = useSelector(state => state.tenant.tenantLayout);

  usePageTitle(tenantLayouts.BenefitsCoveredMembersMainLayout.CoveredMembersConfig.titleId + '|'+tenantLayouts.BenefitsCoveredMembersMainLayout.CoveredMembersConfig.subtitle);
  const [enrollmentsList, setEnrollmentsList] = useState([]);
  const getPlanDetails = (subscriberId) => {
    let enrollmentRecord = null;
    if (enrollmentsList.length) {
      enrollmentRecord = enrollmentsList.filter((data) => data?.memberID === subscriberId)[0];
    }
    return enrollmentRecord;
  };
  useEffect(() => {
    dependentEnrollments.length ?
      setEnrollmentsList([selfEnrollmentsFromStore, ...dependentEnrollments]) :
      setEnrollmentsList([selfEnrollmentsFromStore]);
  }, [dependentEnrollments, selfEnrollmentsFromStore]);

  const getEnrollmentDetails = async (member) => {
    const { subscriberId } = member;
    //add the missing numbers for the suitable handbooks
    const starMedicaidHandbook = tenantProducts.starMedicaidHandbookIds;
    const perinateNewBornHandbook =tenantProducts.perinateNewBornHandbookIds;
    const perinateMemberHandbook = tenantProducts.perinateMemberHandbookIds;
    const enrollmentRecord = getPlanDetails(subscriberId);
    if (starMedicaidHandbook.includes(enrollmentRecord.classPlanID)) {
      window.open(tenantResource.starMedicaidHandbook);
    } else if (perinateNewBornHandbook.includes(enrollmentRecord.classPlanID)) {
      //give the proper handbook pdf url
      window.open(tenantResource.perinateNewBornHandbook);
      //give the proper handbook pdf url
    } else if (perinateMemberHandbook.includes(enrollmentRecord.classPlanID)) {
      window.open(tenantResource.perinateMemberHandbook);
    } else {
      /** FIXME:: need to handle not found case. One idea is to disable download button when planId not found in the records. */
    }
  };

  const allMembers = memberAccountsData && memberAccountsData.dependents && [memberAccountsData.self, ...memberAccountsData.dependents];
  const CoverMemberConfig=tenantLayouts.BenefitsCoveredMembersMainLayout.CoveredMembersConfig;
  return (
    <>
      <AscensionCard >
      {allMembers?.map((member, i) => {
        if (member?.firstName) {
          return (
          <Box
            key={`healthcare-id-card-${i}`}
            sx={[
              { paddingTop: 3},
              i < memberAccountsData?.dependents?.length && {
                borderBottom: '1px solid #D3D3D3', paddingBottom: 3 
              }
            ]}
          >
            <MemberCard config={CoverMemberConfig} member={member} details={getPlanDetails(member?.subscriberId)?.planDescription}  buttonAction={getEnrollmentDetails} />
          </Box>
          );
        }
      }
      )}
      </AscensionCard>
    </>
  );
};

export default CoveredMembersTabPanel;
