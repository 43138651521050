import React from 'react';
import { SvgIcon } from '@mui/material';
import PropTypes from 'prop-types';

SvgIcon.defaultProps = {
  viewBox: '0 0 24 24',
  focusable: 'false',
  'aria-hidden': 'true'
};

const IdCard = ({color, size, style,className, ...props}) => {
  return (
    <SvgIcon style={{...style, color: color, fontSize: size?  size : '24'}}{...props} className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5C2.89543 5 2 5.89543 2 7V18C2 19.1046 2.89543 20 4 20H20C21.1046 20 22 19.1046 22 18V7C22 5.89543 21.1046 5 20 5H4ZM10.5 11.5C10.5 12.3284 9.82843 13 9 13C8.17157 13 7.5 12.3284 7.5 11.5C7.5 10.6716 8.17157 10 9 10C9.82843 10 10.5 10.6716 10.5 11.5ZM18 10H14V11.5H18V10ZM18 13H14V14.5H18V13ZM11.08 14.18C10.44 13.9 9.74 13.75 9 13.75C8.26 13.75 7.56 13.9 6.92 14.18C6.36 14.42 6 14.96 6 15.57V16H12V15.57C12 14.96 11.64 14.42 11.08 14.18Z"
      />
    </SvgIcon>
  );
};

IdCard.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  props: PropTypes.any
};

export default IdCard;
