import React from 'react';
import PropTypes from 'prop-types';
import { Box, useMediaQuery } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useLocation } from 'react-router';
import HtmlText from './HtmlComponent';
import SideNavbar from './SideNavbar';

function NavTabComponent(props) {
  const location = useLocation();

  const [stickySidebar, setStickySidebar] = React.useState(false);
  const isSmallScreen = !useMediaQuery('(min-height:800px)');

  React.useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach(a => {
      a.addEventListener('click', function (e) {
        e.preventDefault();
        const href = this.getAttribute('href');
        const elem = document.querySelector(href);
        const obj = document.querySelector('html');
        obj.style.setProperty('scroll-behavior', 'smooth');
        window.scroll({
          top: elem.offsetTop,
          left: 0,
          behavior: 'smooth'
        });
      });
    });

    window.addEventListener('scroll', () => {
      let scrollY = window.scrollY;
      scrollY >= 600 && scrollY <= 3700
        ? setStickySidebar(true)
        : setStickySidebar(false);

      return () => {
        window.removeEventListener('scroll');
      };
    });
  }, []);

  React.useEffect(() => {
    if (location?.hash) {
      const value = location.hash.substring(1);
      const elem = document.getElementById(value);
      const obj = document.querySelector('html');
      obj.style.setProperty('scroll-behavior', 'smooth');
      window.scroll({
        top: elem.offsetTop,
        left: 0,
        behavior: 'smooth'
      });
    }
  }, [location]);

  return (
    <>
      <SideNavbar
        sideNavItemClassName={
          props.sideNavItemClassName
            ? props.sideNavItemClassName
            : stickySidebar
            ? `sideNavFixedPosition ${isSmallScreen && 'sidebarScroll'}`
            : `sideNavDefaultPosition ${isSmallScreen && 'sidebarScroll'}`
        }
        navItems={props.navItems}
      />
      {props.navItems.filter(item => item.content).length != 0 && (
        <Box className={props.className}>
          <Box className={props.navItemClassName}></Box>
          <Box>
            {props.navItems.map((item, index) => (
              <Box key={index} className={item.className} id={`box-${index}`}>
                <Paper elevation={8}>
                  <Box className={item.contentClassName}>
                    {item.content && <HtmlText content={item.content} />}
                  </Box>
                </Paper>
              </Box>
            ))}
            {props.legalFooter && <HtmlText content={props.legalFooter} />}
          </Box>
        </Box>
      )}
    </>
  );
}

NavTabComponent.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  navItems: PropTypes.array,
  navItemClassName: PropTypes.string,
  sideNavItemClassName: PropTypes.string,
  legalFooter: PropTypes.string
};

export default NavTabComponent;
