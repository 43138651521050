import React from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { NavLink, useLocation } from 'react-router-dom';
import { checkSubMenuChildIsActive, getSanitizedPathName } from '../../common/functionUtils';
import SubMenu from './SubMenu';
import Label from '../type/Label';
import CustomIcon from './CustomIcon';
import { PathNames } from '../../../constants/AppConstants';
import { analyticsTrackEvent, getMappedClickEventPathName } from '../../../utils/analytics/analytics-utils';


function MenuButton(props) {

    const location = useLocation();
    const activeSubmenuItem = getSanitizedPathName(location.pathname);
    const [subMenuAnchor, setSubMenuAnchor] = React.useState(null);
    const [subMenuOptions, setSubMenuOptions] = React.useState(null);

    const openSubMenu = (anchorEl, options) => {
        setSubMenuOptions(options);
        setSubMenuAnchor(anchorEl);
    };

    const closeSubMenu = () => {
        setSubMenuOptions(null);
        setSubMenuAnchor(null);
    };

    const handleMenuItemClick = (path) => {
        
        /** Amplitude analytics implementation - Logging click events **/
        const eventName = `home_header_${getMappedClickEventPathName(path)}_clicked`;
        analyticsTrackEvent(eventName, {path, eventName});
    };

    const path = props.pathName && props.pathName in PathNames ? PathNames[props.pathName] : '';
    return (
        <>

            { props.nestedMenu && props.nestedMenu ? (
                <Button
                    id={props.id}
                    key={props.id}
                    onClick={e => openSubMenu(e.currentTarget, props.nestedMenu)}
                    endIcon={
                        <KeyboardArrowDownIcon style={{ color: '#1E69D2' }} />
                    }
                    className={`${props.className} ${checkSubMenuChildIsActive(activeSubmenuItem) ? 'active' : ''}`}
                >
                    <div ><Label id={props.id} /></div>
                </Button>
            ) : (
                <Button
                    onClick={() => handleMenuItemClick(path)}
                    id={props.id}
                    className={props.className}
                    startIcon={props.Icon && <CustomIcon iconName={props.Icon} />}
                    to={path}
                    target={props.external && '_blank'}
                    component={props.external ? 'a' : NavLink}
                    href={props.external && path}
                >
                    <Label id={props.id} />
                </Button>
            )
            }
            {subMenuOptions && subMenuAnchor && <SubMenu
                anchor={subMenuAnchor}
                onClose={closeSubMenu}
                options={subMenuOptions}
                activeSubmenuItem={activeSubmenuItem}
            />}
        </>
    );

}
MenuButton.propTypes = {
    id: PropTypes.string,
    nestedMenu: PropTypes.any,
    pathName: PropTypes.string,
    title: PropTypes.string,
    Icon: PropTypes.string,
    external: PropTypes.bool,
    className: PropTypes.string
};

export default MenuButton;
