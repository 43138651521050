import {SET_LOCALECONTENT_SUCCESS,SET_LOCALECONTENT_FAILURE, SET_PRODUCTS_FAILURE, SET_PRODUCTS_SUCCESS, SET_RESOURCE_FAILURE,  SET_RESOURCE_SUCCESS, SET_RESOURCE_ES_FAILURE, SET_RESOURCE_ES_SUCCESS, SET_TEANANTID_FAILURE, SET_TEANANTID_SUCCESS, SET_LAYOUT_SUCCESS,SET_LAYOUT_FAILURE,SET_HTMLCONTENT_SUCCESS,SET_HTMLCONTENT_FAILURE} from './constants';

export const initialState = {
    tenantInfo: {},
    tenantInfoError: null,
    tenantInfoLoading: true,
    tenanantResource: {},
    tenanantResourceError: null,
    tenanantResourceLoading: true,
    tenanantResourceES: {},
    tenanantResourceErrorES: null,
    tenanantResourceLoadingES: true,
    tenantProducts: {},
    tenantProductsError: null,
    tenantProductsLoading: true,
    tenantLayout: {},
    tenantLayoutError: null,
    tenantLayoutLoading: true,
    tenantLocaleContent: {},
    tenantLocaleContentError: null,
    tenantLocaleContentLoading: null,
    tenantHtmlContent: {},
    tenantHtmlContentError: null,
    tenantHtmlContentLoading: true
};

export const tenantReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_PRODUCTS_SUCCESS:
            return {
                ...state,
                tenantProducts: payload,
                tenantProductsLoading: false,
                tenantProductsError: null,
            };

        case SET_PRODUCTS_FAILURE:
            return {
                ...state,
                tenantProductsError: payload,
            };
            case SET_LAYOUT_SUCCESS:
            return {
                ...state,
                tenantLayout: payload,
                tenantLayoutLoading: false,
                tenantLayoutError: null,
            };

        case SET_LAYOUT_FAILURE:
            return {
                ...state,
                tenantLayout: {},
                tenantLayoutLoading: false,
                tenantLayoutError: payload.error
            };

        case SET_TEANANTID_SUCCESS:
            return {
                ...state,
                tenantInfo: payload,
                tenantInfoLoading: false,
                tenantInfoError: null,
            };

        case SET_TEANANTID_FAILURE:
            return {
                ...state,
                tenantIdError: payload,
            };
        case SET_RESOURCE_SUCCESS:
            return {
                ...state,
                tenanantResource: payload,
                tenanantResourceLoading: false,
                tenanantResourceError: null,
            };

        case SET_RESOURCE_FAILURE:
            return {
                ...state,
                tenanantResourceError: payload,
            };
        
        case SET_RESOURCE_ES_SUCCESS:
            return {
                ...state,
                tenanantResourceES: payload,
                tenanantResourceLoadingES: false,
                tenanantResourceErrorES: null,
            };
    
        case SET_RESOURCE_ES_FAILURE:
            return {
                ...state,
                tenanantResourceErrorES: payload,
                tenanantResourceLoadingES: false
            };

            case SET_LOCALECONTENT_SUCCESS:
            return {
                ...state,
                tenantLocaleContent: payload,
                tenantLocaleContentLoading: false,
                tenantLocaleContentError: null,
            };

        case SET_LOCALECONTENT_FAILURE:
            return {
                ...state,
                tenantLocaleContentError: payload,
            };

            case SET_HTMLCONTENT_SUCCESS:
                return {
                    ...state,
                    tenantHtmlContent: payload,
                    tenantHtmlContentLoading: false,
                    tenantHtmlContentError: null,
                };
    
            case SET_HTMLCONTENT_FAILURE:
                return {
                    ...state,
                    tenantHtmlContentError: payload,
                };
        default:
            return state;
    }
};