import React from 'react';
import AscensionCard from '../AscensionCard';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import PlanDetails from './PlanDetails';
import Label from '../../../common/type/Label';
//Plan Overview is Multi Tenant
const PlanOverview = (props) => {

    const tenantLayouts = useSelector(state => state.tenant.tenantLayout);
    const info = tenantLayouts && tenantLayouts[props.config];
    const memberAccountsFromStore = useSelector(state => state?.memberAccounts?.memberAccountsDetails);
    const selfCoveragesFromStore = useSelector(state => state?.coverages?.coveragesDetails);
    const selfEnrollmentsFromStore = useSelector(state => state?.enrollments?.enrollmentsDetails);
    const dependentEnrollments = useSelector(state => state?.dependentEnrollments);
    const dependentCoverages = useSelector(state => state?.dependentCoverages);
    return (
        <>

        {(memberAccountsFromStore?.self?.firstName || memberAccountsFromStore?.dependents?.length > 0) &&
            <AscensionCard {...info} className={info.className}>
                <Box className={info.subtitleClassName} id={info.subtitleId}><Label id={info.subtitleId} /></Box>
                {memberAccountsFromStore?.self?.firstName && <PlanDetails data={memberAccountsFromStore.self} coverage={selfCoveragesFromStore} enrollment={selfEnrollmentsFromStore} info={info} id={memberAccountsFromStore?.self?.firstName} />}
                {memberAccountsFromStore?.dependents.map((item, index) => {
                    return <PlanDetails key={index} data={item} coverage={dependentCoverages[index]} enrollment={dependentEnrollments[index]} info={info} id={`Dependent-${index}`} />;
                })}
            </AscensionCard>}
        </>
    );
};

PlanOverview.propTypes = {
    config: PropTypes.string
};

export default PlanOverview;