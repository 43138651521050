import constants from './constants';
import { trackPromise } from 'react-promise-tracker';
import { getCoveragesService, getCoveredMembersService } from '../../services/planDetailsService';

const { SET_COVERAGES_BEGIN, SET_COVERAGES_SUCCESS, SET_COVERAGES_ERROR, SET_COVERAGES_CLEANUP, SET_COVEREDMEMBER_BEGIN,SET_COVEREDMEMBER_CLEANUP,SET_COVEREDMEMBER_ERROR,SET_COVEREDMEMBER_SUCCESS } = constants;

export const setCoveragesBegin = () => ({
    type: SET_COVERAGES_BEGIN
});

export const setCoveragesSuccess = data => ({
    type: SET_COVERAGES_SUCCESS,
    payload: { data }
});

export const setCoveragesError = error => ({
    type: SET_COVERAGES_ERROR,
    payload: { error }
});

export const setCoveragesClean = () => ({
    type: SET_COVERAGES_CLEANUP
});

export const setCoveredMemberBegin = () => ({
    type: SET_COVEREDMEMBER_BEGIN
});

export const setCoveredMemberSuccess = data => ({
    type: SET_COVEREDMEMBER_SUCCESS,
    payload: { data }
});

export const setCoveredMemberError = error => ({
    type: SET_COVEREDMEMBER_ERROR,
    payload: { error }
});

export const setCoveredMemberClean = () => ({
    type: SET_COVEREDMEMBER_CLEANUP
});

export const getCoverages = (tenantId) => {
    return (dispatch) => {
        dispatch(setCoveragesBegin());
        trackPromise(
            getCoveragesService(tenantId)
                .then(res => dispatch(setCoveragesSuccess(res.data)))
                .catch(err => dispatch(setCoveragesError(err)))
        );
    };
};

export const getCoveredMembers = (tenantId) => {
    return (dispatch) => {
        dispatch(setCoveredMemberBegin());
        trackPromise(
            getCoveredMembersService(tenantId)
                .then(res => dispatch(setCoveredMemberSuccess(res.data)))
                .catch(err => dispatch(setCoveredMemberError(err)))
        );
    };
};
