import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@mui/material';
import CustomIcon from '../CustomComponents/CustomIcon';
import Label from './Label';

const CustomAlert = props => {

  return (
    <Alert icon={props.iconName&& <CustomIcon iconName={props.iconName} />} className={props.className} >
      <Label id={props.id} />
    </Alert>
  );
};
CustomAlert.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  props: PropTypes.object,
  iconName: PropTypes.string
};
export default CustomAlert;
