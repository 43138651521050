import constants from './constants';

const { SET_ENROLLMENTS_BEGIN, SET_ENROLLMENTS_SUCCESS, SET_ENROLLMENTS_ERROR, SET_ENROLLMENTS_CLEANUP } = constants;
export const initialState = {
    enrollmentsDetails: null,
    loading: false,
    error: null
};

export const enrollmentsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_ENROLLMENTS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case SET_ENROLLMENTS_SUCCESS:
            return {
                ...state,
                enrollmentsDetails: payload.data,
                loading: false,
            };

        case SET_ENROLLMENTS_ERROR:
            return {
                ...state,
                enrollmentsDetails: {},
                loading: false,
                error: payload.error
            };

        case SET_ENROLLMENTS_CLEANUP:
            return initialState;

        default:
            return state;
    }
};