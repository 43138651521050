import constants from './constants';
import { trackPromise } from 'react-promise-tracker';
import { getMemberAccountsService } from '../../services/memberAccountsService';
import { memberIDCardService } from '../../services/memberAccountsService';

const {
  SET_MEMBER_ACCOUNTS_BEGIN,
  SET_MEMBER_ACCOUNTS_SUCCESS,
  SET_MEMBER_ACCOUNTS_ERROR,
  SET_MEMBER_ACCOUNTS_CLEANUP,
  SET_MEMBER_ID_BEGIN,
  SET_MEMBER_ID_SUCCESS,
  SET_MEMBER_ID_ERROR,
  SET_MEMBER_ID_CLEANUP
} = constants;

export const setMemberAccountsBegin = () => ({
  type: SET_MEMBER_ACCOUNTS_BEGIN
});

export const setMemberAccountsSuccess = data => ({
  type: SET_MEMBER_ACCOUNTS_SUCCESS,
  payload: { data }
});

export const setMemberAccountsError = error => ({
  type: SET_MEMBER_ACCOUNTS_ERROR,
  payload: { error }
});

export const setMemberAccountsClean = () => ({
  type: SET_MEMBER_ACCOUNTS_CLEANUP
});

export const setMemberIDBegin = () => ({
  type: SET_MEMBER_ID_BEGIN
});

export const setMemberIDSuccess = data => ({
  type: SET_MEMBER_ID_SUCCESS,
  payload: { data }
});

export const setMemberIDError = error => ({
  type: SET_MEMBER_ID_ERROR,
  payload: { error }
});

export const setMemberIDClean = () => ({
  type: SET_MEMBER_ID_CLEANUP
});
export const getMemberAccounts = (tenantId) => {
    return (dispatch) => {
        dispatch(setMemberAccountsBegin());
        trackPromise(
            getMemberAccountsService(tenantId)
                .then(res => dispatch(setMemberAccountsSuccess(res.data)))
                .catch(err => dispatch(setMemberAccountsError(err)))
        );
    };
};

export const getMemberIDCard = (memberAccountHeader, tenantId) => {
  return (dispatch) => {
    dispatch(setMemberIDBegin());
    trackPromise(
      memberIDCardService(memberAccountHeader, tenantId)
            .then(res => dispatch(setMemberIDSuccess(res.data)))
            .catch(err => dispatch(setMemberIDError(err)))
        );
    };
};