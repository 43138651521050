import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { Button } from '@mui/material';
import IdCardIcon from '../../../assets/IdCard';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Label from '../type/Label';
const HomeHeroImageComponent = props => {
  const { image,layoutImage } = props;
  const tenantResource = useSelector(state => state.tenant.tenanantResource);
  const navigate = useNavigate();

  return (
    <Box>
      <div className='wrapper' style={{ backgroundImage: `url(${tenantResource[image]})` }}>
        <Box className='contentWrapper' style={{ backgroundImage: `url(${tenantResource[layoutImage]})` }}>
          <Box className='bannerTextWrapper'>
            <Typography className={props.className}><Label id={props.id} /></Typography>
            {props.buttonLabel &&
              <Button
                variant="contained"
                className={props.buttonClass}
                startIcon={
                  <IdCardIcon
                    color="#B40F87"
                    size="26"
                    className={props.iconClass}
                  />
                }
                onClick={() => navigate(`/${props.pathName}`)}
              >
                {props.buttonLabel}
              </Button>}
          </Box>
        </Box>
      </div>
    </Box>
  );
};

HomeHeroImageComponent.propTypes = {
  props: PropTypes.object,
  image: PropTypes.string,
  layoutImage: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  label: PropTypes.string,
  sublabel: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonClass: PropTypes.string,
  iconClass: PropTypes.string,
  className: PropTypes.string,
  pathName: PropTypes.string,
  id:PropTypes.string,
};

export default HomeHeroImageComponent;
