import React from 'react';
import {  Grid } from '@mui/material';
import PropTypes from 'prop-types';
import GridItem from './GridItem';
import { useSelector } from 'react-redux';

function RowContainer(props) {
    const tenantResource = useSelector(state => state.tenant.tenanantResource);
    const backgroundImage=props.backgroundImageId &&tenantResource[props.backgroundImageId];
    return (
        <Grid container className={props.className}  style={backgroundImage&&{backgroundImage:`url(${backgroundImage})`}} key={props.id} id={props.id}>
            <GridItem items={props.items}/>
        </Grid>
    );
}
RowContainer.propTypes = {
    items: PropTypes.array,
    className: PropTypes.string,
    id: PropTypes.string,
    backgroundImageId:PropTypes.string
};

export default RowContainer;
