import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { checkLabelHasDynamicText, getSanitizedText } from '../functionUtils';
import { capitalizeString } from '../../../utils/format-utils';
import { useMsal } from '@azure/msal-react';

const Text = ( props ) => {
  const { instance } = useMsal();
  const account = instance.getActiveAccount();

  const getTextLabel = label => {
    if (checkLabelHasDynamicText(label)) {
      return label.replace(
        `<${getSanitizedText(label)}>`,
        capitalizeString(
          account.idTokenClaims && account.idTokenClaims.firstName
        )
      );
    } else {
      return label;
    }
  };

  return <Typography className={props.className}>{getTextLabel(props.label)}</Typography>;
};
Text.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  props: PropTypes.object
};
export default Text;
