import React from 'react';
import { useSelector } from 'react-redux';
import RowContainer from '../../common/CustomComponents/RowContainer';

const Medical = () => {
  const tenantLayouts = useSelector(state => state.tenant.tenantLayout);
    return (
      tenantLayouts['medical'] && 
        <div className={tenantLayouts['medical'].className} id={tenantLayouts['medical'].id}>
        {tenantLayouts['medical'].sections.map((item,index) =>{
          return <RowContainer key={index} {...item} />;
        })}
        </div>
    );
};

export default Medical;