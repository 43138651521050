import { trackPromise } from 'react-promise-tracker';
import { getClaimDetailsService, getClaimsService } from '../../services/claimsDetailsService';
import constants from './constants';

// action creators
export const setClaims = payload => ({
  type: constants.SET_CLAIMS,
  payload
});

export const setClaimsError = payload => ({
  type: constants.SET_CLAIMS_ERROR,
  payload
});

export const setClaimDetailsBegin = payload => ({
  type: constants.SET_CLAIM_DETAILS_BEGIN,
  payload
});

export const setClaimDetailsSuccess = payload => ({
  type: constants.SET_CLAIM_DETAILS_SUCCESS,
  payload
});

export const setClaimDetailsError = payload => ({
  type: constants.SET_CLAIM_DETAILS_ERROR,
  payload
});

export const getClaimDetails = (claimId,tenantId) => {
  return (dispatch) => {
    dispatch(setClaimDetailsBegin());
    trackPromise(
        getClaimDetailsService(claimId,tenantId)
            .then(res => dispatch(setClaimDetailsSuccess(res.data)))
            .catch(err => dispatch(setClaimDetailsError(err)))
    );
};
};
  

export const getClaims = (tenantId,requestParams, pageNumber, pageSize)=>{
  return (dispatch)=>
   trackPromise(
    getClaimsService(tenantId,requestParams, pageNumber, pageSize)
      .then(res => {
        dispatch(
          setClaims({
            ...res.data,
            requestParams
          })
        );
      })
      .catch(err => dispatch(setClaimsError(err.message)))
  );
};
