import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent } from '@mui/material';
import Label from '../../common/type/Label';

const AscensionCard = props => {
  const { children, titleId, marginBottom, id, className, titleClassName, cardContentClassName } = props;
  return (
    <Card
      variant="outlined"
      className={className}
      id={id}
      sx={{
        marginBottom: marginBottom ? marginBottom : 0
      }}
    >
      {titleId &&(
      <CardHeader
        title={<Label id={titleId}/>}
        classes={{
          title: titleClassName
        }}
        id={titleId}
      />)}

      <CardContent className={cardContentClassName} sx={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}>
        {children}
      </CardContent>
    </Card>
  );
};

AscensionCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array,PropTypes.object]),
  titleId: PropTypes.string,
  marginBottom: PropTypes.string,
  id: PropTypes.string,
  props: PropTypes.object,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  cardContentClassName: PropTypes.string
};

export default AscensionCard;
