import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './rootReducer';

const initialState = { ...JSON.parse(window.sessionStorage.getItem('redux') || '{}') };

export const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(thunk))
);


const storeDataToSessionStorage = () => {
  window.sessionStorage.setItem('redux', JSON.stringify(store.getState()));
};

store.subscribe(storeDataToSessionStorage);
