import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import warningSvg from '../../../assets/warning.svg';

const useStyles = makeStyles(() => ({
    root: {
        padding: '16px',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        boxShadow:
            'rgb(0 0 0 / 7%) 0px 4px 5px, rgb(0 0 0 / 6%) 0px 1px 10px, rgb(0 0 0 / 10%) 0px 2px 4px !important',
        borderRadius: 4,
        margin: '10% auto',
        justifyContent: 'center',
        maxWidth: '650px'
    },
    warning: {
        color: '#FF7F00',
        textAlign: 'center',
    },
    content: {
        margin: '53px 20px 53px 20px'
    },
    warningIcon: {
        width: '76.25px !important',
        height: '68.03px !important',
    },
    button: {
        display: 'flex',
        justifyContent: 'center'
    },
    title: {
        fontSize: '24px',
        lineHeight: '24px',
        fontFamily: 'HCo Whitney SSm',
        paddingTop: '40px',
        paddingBottom: '16px'
    },
    subtitle: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: 'HCo Whitney SSm',
        paddingBottom: '40px'
    },
    paragraph:{
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing:'0.5px',
        fontFamily: 'HCo Whitney SSm',
        paddingBottom: '42px'
    },
    phone: {
        fontWeight: 'bold',
        color: '#000000',
        textDecoration: 'none'
      },
}));

const InternalError = () => {
    const classes = useStyles();
    const handleClick = () => {
        window.location.reload();
      };
    return (
        <>
            <Card className={classes.root}>
                <CardContent className={classes.content}>
                    <div className={classes.warning}>
                        <img src={warningSvg} alt="Warning" id ='warningIcon' className={classes.warningIcon} />
                    </div>
                    <Typography variant='h6' id ='message504' gutterBottom className={classes.title}>We&apos;re sorry,</Typography>
                    <Typography className={classes.subtitle} id ='description504'>The page that you requested can&apos;t be accessed due to an issue on our end. Please try refreshing the page.</Typography>
                    <Typography color="textSecondary"  id ='support504' className={classes.paragraph}>If you continue to have this issue, call support at {''}
                    <a href="tel:18559216284" id ='contact504' className={classes.phone}>1-855-921-6284</a></Typography>
                    <Box className={classes.button}>
                        <Button variant="contained" color="primary" id ='button504' onClick={() => handleClick()}>REFRESH PAGE</Button>
                    </Box>
                </CardContent>
            </Card>
        </>
    );
};

export default InternalError;
