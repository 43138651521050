import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import warningSvg from '../../../assets/warning.svg';

const useStyles = makeStyles(() => ({
    root: {
        padding: '16px',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        boxShadow:
            'rgb(0 0 0 / 7%) 0px 4px 5px, rgb(0 0 0 / 6%) 0px 1px 10px, rgb(0 0 0 / 10%) 0px 2px 4px !important',
        borderRadius: 4,
        margin: '8% auto',
        justifyContent: 'center',
        maxWidth: '650px'
    },
    warning: {
        color: '#FF7F00',
        textAlign: 'center',
    },
    content: {
        margin: '50px 20px 35px 20px'
    },
    warningIcon: {
        width: '76.25px !important',
        height: '68.03px !important',
    },
    button: {
        display: 'flex',
        justifyContent: 'center'
    },
    title: {
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '24px',
        fontFamily: 'HCo Whitney SSm',
        paddingTop: '40px',
        paddingBottom: '16px'
    },
    subtitle: {
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: 'HCo Whitney SSm',
        paddingBottom: '54px'
    }
}));

const ErrorLayout = () => {
    const classes = useStyles();
    const handleClick = () => {
        window.location.reload();
      };
    return (
        <>
            <Card className={classes.root}>
                <CardContent className={classes.content}>
                    <div className={classes.warning}>
                        <img src={warningSvg} alt="Warning" id ='warningIcon' className={classes.warningIcon} />
                    </div>
                    <Typography variant='h6' id ='message404' className={classes.title}>We&apos;re sorry,</Typography>
                    <Typography id ='description404' className={classes.subtitle}>The page that you requested can&apos;t be found or doesn&apos;t exist.</Typography>
                    <Box className={classes.button}>
                        <Button variant="contained" id ='button404' color="primary" onClick={() => handleClick()}>GO TO MEMBER DASHBOARD</Button>
                    </Box>
                </CardContent>
            </Card>
        </>
    );
};

export default ErrorLayout;
