import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Banner from '../components/Banner/Banner';
import Footer from '../components/Footer/Footer';
import SecureRoute from '../components/common/SecureRoute/SecureRoute';
import '@ascension/ui-library/src/fonts/WhitneySSm.css';
import '@ascension/ui-library/src/fonts/ChronicleSSm.css';
import AxiosInterceptors from '../components/common/AxiosInterceptors';
import {  useDispatch, useSelector } from 'react-redux';
import { setLanguageCookies } from '../utils/content-utils';
import CustomRoutes from './CustomRoutes';
import { settingUpLaunguageForLoginPage } from '../auth/config';
import { fetchHtmlContent, fetchLayouts, fetchLocaleContent, fetchProducts, fetchResource, fetchResourceES, fetchTenantId } from '../redux/tenantinfo/action';


const App = () => {

  const [searchParams] = useSearchParams();
  const tenantResource = useSelector(state => state.tenant.tenanantResource);

  const dispatch = useDispatch();
  const setLanguageInCookie = () => {
    if (document.cookie.split(';').some((item) => item.trim().startsWith('language='))) {
      setLanguageCookies(document.cookie.split('; ').find((row) => row.startsWith('language='))?.split('=')[1]);
      settingUpLaunguageForLoginPage(document.cookie.split('; ').find((row) => row.startsWith('language='))?.split('=')[1]);
    }
  };

  const getPath = () => {
    const hostname = window.location.hostname;
    if (hostname.includes('localhost')) {
      if (searchParams.get('tenantId') === null || searchParams.get('tenantId') === undefined) {
        localStorage.setItem('tenantHandle', 'dchp');
      } else {
        localStorage.setItem('tenantHandle', searchParams.get('tenantId'));
      }
    }
    else if (hostname.includes('beehive') || hostname.includes('dchp')) {
      localStorage.setItem('tenantHandle', 'dchp');
    }
    else if (hostname.includes('smarthealth')) {
      localStorage.setItem('tenantHandle', 'smarthealth');
    }
    else {
      localStorage.setItem('tenantHandle', searchParams.get('tenantId'));
    }
    return localStorage.getItem('tenantHandle');
  };
  React.useEffect(() => {
    //calling services & setting data to data state variable
    dispatch(fetchTenantId(getPath()));
    dispatch(fetchProducts(getPath()));
    dispatch(fetchResource(getPath()));
    dispatch(fetchLayouts(getPath()));
    dispatch(fetchLocaleContent(getPath()));
    dispatch(fetchHtmlContent(getPath()));
    dispatch(fetchResourceES(getPath()));
  }, [dispatch]);

  React.useEffect(() => {
    //need to check dynamic css import
    if (tenantResource.styles) {
      var styles = document.getElementById(localStorage.getItem('tenantHandle'));
      var head = document.head;
      if (styles) {
        head.removeChild(styles);
      }
      var link = document.createElement('link');
      link.type = 'text/css';
      link.id = localStorage.getItem('tenantHandle');
      link.rel = 'stylesheet';
      link.href = tenantResource.styles;
      head.appendChild(link);
    }
  }, [tenantResource]);

  useEffect(() => {
    setLanguageInCookie();
  }, []);




  return (
    <>
      <AxiosInterceptors />
      <SecureRoute>
        <Banner />
        <CustomRoutes />
        <Footer />
      </SecureRoute>
    </>

  );
};


export default App;