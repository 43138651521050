import React from 'react';
import PropTypes from 'prop-types';
import Label from '../type/Label';
import { useMsal } from '@azure/msal-react';

function WelcomeText(props) {
  const { instance } = useMsal();
  const account = instance.getActiveAccount();

  const getText = () => {
    const hours = new Date().getHours();
    if (hours < 12) {
      return <Label id="goodMorningText" />;
    } else if (hours >= 12 && hours <= 17) {
      return <Label id="goodAfterNoonText" />;
    } else {
      return <Label id="goodEveningText" />;
    }
  };

  const firstName = account?.idTokenClaims?.firstName;
  const capitalizeFirstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);

  return (
    <p className={props.className} id={props.id}>
      {getText()} {account && account.idTokenClaims?.firstName && capitalizeFirstName}
    </p>
  );
}
WelcomeText.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string
};

export default WelcomeText;
