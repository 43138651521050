import { PathNames } from './AppConstants';

export const benefitsSubMenuItems = [
  PathNames.benefitsOverview,
  PathNames.benefitsWhatsCovered,
  PathNames.benefitsExtraBenefits,
  PathNames.benefitsCoveredMembers
];
export const profileSubMenuItems = [
  PathNames.settings
];

export const planNames = {
  DELL_CHILDRENS_TANF_ADULT: 'Dell Childrens TANF Adult',
  DELL_CHILDRENS_CHILD: 'Dell Childrens Child',
  DELL_CHILDRENS_AAPCA_CHILD: 'Dell Childrens AAPCA Child',
  DELL_CHILDRENS_TEMP_NEWBORN: 'Dell Childrens Temp Newborn',
  DELL_CHILDRENS_AAPCA_TEMP_NEWBORN: 'Dell Childrens AAPCA Temp Newborn',
  DELL_CHILDRENS_PREGNANT_WOMEN_21_PW: 'Dell Childrens Pregnant Women < 21 PW',
  DELL_CHILDRENS_AAPCA_PREGNANT_WOMEN_21_PW:
    'Dell Childrens AAPCA Pregnant Women < 21 PW',
  DELL_CHILDRENS_PREGNANT_WOMEN_21_AND_OLDER_PW:
    'Dell Childrens Pregnant Women 21 And Older PW'
};
