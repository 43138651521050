import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Paper, Typography } from '@mui/material';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import Image from '../CustomComponents/Image';
import { getDateObj } from '../../../utils/date-utils';
import { camelCaseString } from '../../../utils/format-utils';
import TextComponent from '../CustomComponents/TextComponent';
import CustomIcon from '../CustomComponents/CustomIcon';
import Label from '../type/Label';

const PlanOverview = props => {
  const coveragesDetails = useSelector(
    state => state?.coverages?.coveragesDetails
  );
  const coveredMembers = useSelector(state => state?.coverages?.coveredMembers);
  const planDetails =
    coveragesDetails &&
    coveragesDetails.filter(details => {
      return details.type === 'M';
    })[0];

  const tenantLayouts = useSelector(state => state.tenant.tenantLayout);
  const config = tenantLayouts[props.config];

  const getDisplayDate = date => {
    const dateObj = getDateObj(date);

    if (!dateObj.shortMonth) return '';

    return `${dateObj.shortMonth} ${dateObj.day}, ${dateObj.year}`;
  };
  const getDisplayYear = date => {
    const dateObj = getDateObj(date);

    if (!dateObj.year) return '9999';

    return `${dateObj.year}`;
  };

  const foramtCoveredMembers = members => {
    members = _.orderBy(members, ['subscriberSuffix']);

    const getListFormat = (arrayLength, index) => {
      if (index === 0) return '';

      if (arrayLength - 1 === index) return ', and';

      return ',';
    };

    return members.map(
      (member, index) =>
        `${getListFormat(members.length, index)} ${camelCaseString(
          member.firstName
        )} ${camelCaseString(member.lastName)}`
    );
  };

  return (
    planDetails && (
      <Paper className={config.className}>
        <Box p={2}>
          <TextComponent
            className={config.titleClassName}
            id={config.titleId}
          />
        </Box>
        <Box>
          {/* <img src={heroSmPeople} width="100%" /> */}
          <Image
            imageId={config.image}
            alt={config.image}
            className={config.image}
          />
        </Box>
        <Box p={2}>
          <div className="homeWelcomeOverviewList">
            <CustomIcon
              iconName="LocalHospital"
              className="homeWelcomeOverviewIcon"
            />
            <Typography id="familyEnrolledInPlan">
              <Label id="familyEnrolledInPlanId" />{' '}
              {planDetails.coverageCategory} <Label id="for" />{' '}
              {getDisplayYear(planDetails.effectiveDate)}
            </Typography>
          </div>
          <div className={config.listClassName}>
            <CustomIcon
              iconName="CalendarToday"
              className="homeWelcomeOverviewIcon"
            />
            <Typography id="coverageBeginsLabel" >
              <b>
                <Label id="coverageBeginsLabel" />{' '}
                {getDisplayDate(planDetails.effectiveDate)}
              </b>
            </Typography>
          </div>
          <div className={config.listClassName}>
            <CustomIcon iconName="People" className="homeWelcomeOverviewIcon" />
            <Typography id="coveredMembersLabel">
              <b>
                <Label id="coveredMembersLabel" />
              </b>
              <br />
              {foramtCoveredMembers(coveredMembers)}
            </Typography>
          </div>
        </Box>
      </Paper>
    )
  );
};

PlanOverview.propTypes = {
  config: PropTypes.string,
  coverageDate: PropTypes.string,
  coveredMembers: PropTypes.array
};

export default PlanOverview;
