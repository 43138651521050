import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';
import { useMsal } from '@azure/msal-react';
import { useSelector } from 'react-redux';
import Label from '../../common/type/Label';

const useStyles = makeStyles(() => ({
    wrapper: {
        padding: 24,
        flexGrow: 1,
        boxShadow: ' 0px 4px 5px rgba(0, 0, 0, 0.07), 0px 1px 10px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: 4,
        marginBottom: 24
    },
    titleWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 28,
        '& h4': {
            margin: '0 0 0 24px',
            fontWeight: 350,
            fontSize: 24,
            fontStyle: 'normal',
        }
    },
    contentWrapper: {
        display:'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginLeft:67
    },
    content: {
        color: 'rgba(0, 0, 0, 0.6)'
    },
}));


const AccountSection = () => {
    const { instance } = useMsal();
    const selfEnrollmentsFromStore = useSelector(state => state?.enrollments?.enrollmentsDetails);
    const address = selfEnrollmentsFromStore && selfEnrollmentsFromStore.formattedAddress + ', '+ selfEnrollmentsFromStore.zipCode;

    const account = instance.getActiveAccount();
    const classes = useStyles();
    return (
        <>
            <Box className={classes.wrapper} id='settingsAccountSection'>
                <Box className={classes.titleWrapper}>
                    <Box display="flex" justifyContent="flex-start" alignItems="center">
                        <Avatar
                            sx={{ bgcolor: '#E8F5FE', color: '#1E69D2' }}
                            alt={account ? account.idTokenClaims?.name : 'U'}
                            src="/broken-image-url.jpg"
                        />
                        <Typography variant="h4" style={{textTransform: 'capitalize'}}>{account && account.idTokenClaims?.name}</Typography>
                    </Box>
                </Box>
                {selfEnrollmentsFromStore && <Box className={classes.contentWrapper}>
                    <Box flexGrow="1">
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}><Label id='dateOfBirth' /></Typography>
                        <Typography variant="body2" className={classes.content}>{selfEnrollmentsFromStore.birthDate}</Typography>
                    </Box>
                    <Box flexGrow="1">
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}><Label id='address'/></Typography>
                        <Typography variant="body2"  className={classes.content} style={{maxWidth: 140}}>
                            {address}
                        </Typography>
                    </Box>
                </Box>}
            </Box>
        </>
    );
};

AccountSection.propTypes = {
    navigate: PropTypes.func
};


export default AccountSection;
