import constants from './constants';

const { SET_COVERAGES_BEGIN, SET_COVERAGES_SUCCESS, SET_COVERAGES_ERROR, SET_COVERAGES_CLEANUP, SET_COVEREDMEMBER_SUCCESS,SET_COVEREDMEMBER_ERROR } = constants;
export const initialState = {
    coveragesDetails: null,
    coveredMembers:[],
    loading: false,
    error: null,
    coveredMembersError:null
};

export const coveragesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_COVERAGES_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case SET_COVERAGES_SUCCESS:
            return {
                ...state,
                coveragesDetails: payload.data,
                loading: false,
            };

        case SET_COVERAGES_ERROR:
            return {
                ...state,
                coveragesDetails: {},
                loading: false,
                error: payload.error
            };

        case SET_COVERAGES_CLEANUP:
            return initialState;

        case SET_COVEREDMEMBER_SUCCESS:
            return {
            ...state,
            coveredMembers: payload.data,
            }; 
        case SET_COVEREDMEMBER_ERROR:
            return {
                ...state,
                coveredMembersError: payload.error
            }; 
        default:
            return state;
    }
};