import AscensionCard from '../../home/AscensionCard';
import React from 'react';
// import { usePageTitle } from '../../common/hooks/usePageTitle';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import SmarthHealthMemberCard from '../../../common/CustomComponents/smartHealth/SmarthHealthMemberCard';

const SmartHealthCoveredMembersPanel=()=>{
  const tenantLayouts = useSelector(state => state.tenant.tenantLayout);
//   const dependentEnrollments = useSelector((state) => state.dependentEnrollments);
//   // const memberAccountsData = useSelector(state => state?.memberAccounts?.memberAccountsDetails);
//   // const selfEnrollmentsFromStore = useSelector(state => state?.enrollments?.enrollmentsDetails);
//   const tenantResource = useSelector(state => state.tenant.tenanantResource);
//   const tenantProducts = useSelector(state => state.tenant.tenantProducts);

  const CoverMemberConfig=tenantLayouts.BenefitsCoveredMembersMainLayout.CoveredMembersConfig;
 const allMembers=[
    {
        'firstName': 'sadia',
        'lastName': 'ameen',
        'subscriberId': '770141497',
        'subscriberSuffix': '0',
        'groupId': 'DCHPTXMD',
        'hashedKey': 'fTNhP5f2I/djwqYomlNg1EThbPG4pjHN5a3GVXGo6QY='
    },
    {
        'firstName': 'sadia',
        'lastName': 'ameen',
        'subscriberId': '770141497',
        'subscriberSuffix': '0',
        'groupId': 'DCHPTXMD',
        'hashedKey': 'fTNhP5f2I/djwqYomlNg1EThbPG4pjHN5a3GVXGo6QY='
    }
  ];
  // usePageTitle(tenantLayouts.BenefitsCoveredMembersMainLayout.CoveredMembersConfig.titleId + '|'+tenantLayouts.BenefitsCoveredMembersMainLayout.CoveredMembersConfig.subtitle);
  // const [enrollmentsList, setEnrollmentsList] = useState([]);
 
   
  // const getPlanDetails = (subscriberId) => {
  //   let enrollmentRecord = null;
  //   if (enrollmentsList.length) {
  //     enrollmentRecord = enrollmentsList.filter((data) => data?.memberID === subscriberId)[0];
  //   }
  //   return enrollmentRecord;
  // };
  // useEffect(() => {
  //   dependentEnrollments.length ?
  //     setEnrollmentsList([selfEnrollmentsFromStore, ...dependentEnrollments]) :
  //     setEnrollmentsList([selfEnrollmentsFromStore]);
  // }, [dependentEnrollments, selfEnrollmentsFromStore]);

  // const getEnrollmentDetails = async (member) => {
  //   // const { subscriberId } = member;
  //   //add the missing numbers for the suitable handbooks
  //   const starMedicaidHandbook = tenantProducts.starMedicaidHandbookIds;
  //   const perinateNewBornHandbook =tenantProducts.perinateNewBornHandbookIds;
  //   const perinateMemberHandbook = tenantProducts.perinateMemberHandbookIds;
  //   // const enrollmentRecord = getPlanDetails(subscriberId);
  //   if (starMedicaidHandbook.includes(enrollmentRecord.classPlanID)) {
  //     window.open(tenantResource.starMedicaidHandbook);
  //   } else if (perinateNewBornHandbook.includes(enrollmentRecord.classPlanID)) {
  //     //give the proper handbook pdf url
  //     window.open(tenantResource.perinateNewBornHandbook);
  //     //give the proper handbook pdf url
  //   } else if (perinateMemberHandbook.includes(enrollmentRecord.classPlanID)) {
  //     window.open(tenantResource.perinateMemberHandbook);
  //   } else {
  //     /** FIXME:: need to handle not found case. One idea is to disable download button when planId not found in the records. */
  //   }
  // };
return(<>
<AscensionCard >
  {allMembers?.map((member,i)=>{ if (member?.firstName) {
          return (
          <Box
            key={`healthcare-id-card-${i}`}
            sx={[
              { paddingTop: 3},
              i < allMembers?.length -1 && {
                borderBottom: '1px solid #D3D3D3', paddingBottom: 3 
              }
            ]}
          >
            <SmarthHealthMemberCard config={CoverMemberConfig} member={member} details='HDHP 3000'  />
          </Box>
          );
        }
      }
  )
    }

</AscensionCard>
</>);
};
export default SmartHealthCoveredMembersPanel;
