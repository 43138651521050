
import React from 'react';
import { Box, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const FindCare = () => {
    const theme = useTheme();
    const checkDevice = useMediaQuery(theme.breakpoints.up('md'));

    const [isDesktop, setIsDesktop] = React.useState(false);

    React.useEffect(() => {
        setIsDesktop(checkDevice);
    }, [checkDevice]);

    const desktopWrapperStyle = {
        marginRight: '10px', maxWidth: '320px'
    };

    const mobileWrapperStyle = {
        marginRight: '0px', maxWidth: '100%'
    };

    return (
        <Box sx={isDesktop ? desktopWrapperStyle : mobileWrapperStyle}>
            <Paper sx={{ minHeight: '500px', boxShadow: '0px 4px 5px rgb(0 0 0 / 7%), 0px 1px 10px rgb(0 0 0 / 6%), 0px 2px 4px rgb(0 0 0 / 10%)', borderRadius: '8px'}}></Paper>
        </Box>
    );
};



export default FindCare;