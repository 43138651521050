import { Card, CardContent, Typography } from '@mui/material';
import * as React from 'react';
import UnidentifiedBanner from './UnidentifiedBanner';
import { makeStyles } from '@mui/styles';
import Image from '../../common/CustomComponents/Image';
import TextComponent from '../../common/CustomComponents/TextComponent';
import Label from '../../common/type/Label';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  root: {
    padding: '16px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    boxShadow:
      'rgb(0 0 0 / 7%) 0px 4px 5px, rgb(0 0 0 / 6%) 0px 1px 10px, rgb(0 0 0 / 10%) 0px 2px 4px !important',
    borderRadius: 4,
    margin: '8% auto',
    justifyContent: 'center',
    maxWidth: '650px'
  },
  warning: {
    color: '#FF7F00',
    textAlign: 'center'
  },
  contentHeader: {
    margin: '50px 24px 90px 24px'
  },
  warningIcon: {
    width: '76.25px !important',
    height: '68.03px !important'
  },
  button: {
    display: 'flex',
    justifyContent: 'center'
  },
  title: {
    fontWeight: '500 !important',
    fontSize: '24px !important',
    lineHeight: '24px !important',
    fontFamily: 'HCo Whitney SSm !important',
    paddingTop: '40px',
    paddingBottom: '16px',
    margin: '0px'
  },
  subtitle: {
    fontWeight: '700 !important',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'HCo Whitney SSm',
    paddingBottom: '8px',
    margin: '0px',
    letterSpacing: '0.75px'
  },
  content: {
    fontSize: '16px !important',
    lineHeight: '24px !important',
    fontFamily: 'HCo Whitney SSm',
    color: 'rgba(0, 0, 0, 0.6)',
    letterSpacing: '0.5px',
    paddingBottom: '16px',
    margin: '0px'
  }
}));

const Unidentified = () => {
  const classes = useStyles();
  const tenantInfo = useSelector(state => state.tenant.tenantInfo);

  return (
    <>
      <UnidentifiedBanner />
      <Card className={classes.root}>
        <CardContent className={classes.contentHeader}>
          <div className={classes.warning}>
            <Image
              imageId="warningIcon"
              altText="Warning"
              id="warningIcon"
              className={classes.warningIcon}
            />
          </div>
          <TextComponent id="unidentifiedTitle" className={classes.title} />
          <TextComponent
            id="unidentifiedSubTitleMaxReach"
            className={classes.subtitle}
          />
          <TextComponent
            id="unidentifiedInformationNotMatched"
            className={classes.content}
          />
          <TextComponent
            id="unidentifiedSubTitleToAccess"
            className={classes.subtitle}
          />

          <Typography id="unidentifiedCallSupport" className={classes.content}>
            <Label id="unidentifiedCallSupport" />{' '}
            <a
              href="memberSuppport"
              style={{
                textDecoration: 'none',
                color: 'rgba(30, 105, 210, 1)',
                fontWeight: 700
              }}
              id='unidentifiedSupportNumber'
            >
              {tenantInfo && tenantInfo['unidentifiedSupport']}
            </a>
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};

export default Unidentified;
