import React from 'react';
import PropTypes from 'prop-types';
import RowContainer from '../../common/CustomComponents/RowContainer';
import { usePageTitle } from '../../common/hooks/usePageTitle';

// MTMP - Settings Page
const Settings = ({config}) => {
  const homeLayout = config;
  usePageTitle(homeLayout && homeLayout.pageTitle);

  return (
    config && (
      <div
        className={config.className}
        id={config.id}
      >
        {config.sections.map((item, index) => {
          return <RowContainer key={index} {...item} />;
        })}
      </div>
    )
  );
};

Settings.propTypes = {
  navigate: PropTypes.func,
  config:PropTypes.object
};

export default Settings;
