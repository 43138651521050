const AddDependentFromReducer = (state, action) => {
    switch (action.type) {
        case 'HANDLE_INPUT_TEXT': {
            return {
                ...state,
                [action.field]: action.payload,
                [`${action.field}Error`]: !action.payload
            };
        }
        case 'ON_SUBMIT': {
            return {
                ...state,
                firstNameError: !state.firstName,
                lastNameError: !state.lastName,
        dateOfBirthError: !state.dateOfBirth || state.dateOfBirth === 'Invalid date',
      medIdError:  !state.medId
        };
        }
        case 'CLEANUP': {
            return {
                ...action
            };
        }
        default: {
            throw new Error(`Invalid action tyle: ${action.type}`);
        }
    }
};

export default AddDependentFromReducer;