import React from 'react';
import { Box} from '@mui/material';
import PropTypes from 'prop-types';



const PlanDetailsProgressBar = ({ progressValue }) => {
    return (
        <Box>
           <Box className="pSProgressBarWrapper">
               <Box className="pSProgressBarShowValue" sx={{width: `${progressValue}%`}}></Box>
           </Box>
        </Box>
    );
};

PlanDetailsProgressBar.propTypes = {
    progressValue: PropTypes.string,
};

export default PlanDetailsProgressBar;