
import React, { useEffect } from 'react';
import { NavigateNext } from '@mui/icons-material';
import { Box, IconButton, Paper, Typography, useTheme } from '@mui/material';
import PlanStatusChart from './PlanStatusChart/PlanStatusChart';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPlanDetails } from '../../../../redux/planDetails/actions';
import Label from '../../type/Label';



const PlanStatus = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const planData = useSelector(state => state.planDetails.plan);

  useEffect(() => {
    dispatch(fetchPlanDetails(localStorage.getItem('tenantHandle')));
  }, [dispatch]);
  return (
    planData && <Paper className='planStatusRoot'>
      <Box className='planStatusheading'>
        <Typography className='planStatusheadingText' id='planStatusheadingText' variant="h5" >
          <Label id='homePlanStatusTitle' />
        </Typography>
        <IconButton id='NavigateNext' size="small">
          <NavigateNext color="secondary" />
        </IconButton>
      </Box>
      <Box className='planStatuscontent'>
        <Box className='chartContainer'>
          {planData.deductableMaxAmount > 0 && (
            <PlanStatusChart
              className='chartItem'
              title="Deductible"
              metLabel="Deductible"
              color={theme.palette.primary.main}
              amountMet={planData.deductableAmountMet}
              maxAmount={planData.deductableMaxAmount}
              hasFamilyInPlan={planData.hasFamilyInPlan}
              inlineTitle={true}
            />
          )}
          {planData.outOfPocketMaxAmount > 0 && (
            <PlanStatusChart
              className='chartItem'
              title="Out-of-Pocket Max"
              metLabel="OOP Max"
              color={theme.palette.primary.dark}
              amountMet={planData.outOfPocketAmountMet}
              maxAmount={planData.outOfPocketMaxAmount}
              hasFamilyInPlan={planData.hasFamilyInPlan}
              inlineTitle={true}
            />
          )}
        </Box>
        <Typography className='planStatusText' id='planStatusText' >{planData.status}</Typography>
      </Box>
    </Paper>
  );
};



export default PlanStatus;