import React from 'react';
import PropTypes from 'prop-types';
import { PieChart as RePieChart, Pie, Cell } from 'recharts';
import {  Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '8px'
  },
  labelContainer: {
    position: 'absolute',
    textAlign: 'center'
  },
  mainLabel: {
    fontSize: '18px'
  }
}));

const PieChart = ({
  size,
  color,
  maxValue,
  value,
  label,
  subLabel,
  labelTestId
}) => {
  const classes = useStyles();

  const getPercentageUsed = (val, max) => {
    const result = Math.ceil((val / max) * 100);
    return result <= 100 ? result : 100;
  };

  const getData = () => {
    const percentage = getPercentageUsed(value, maxValue);

    return [
      { value: 100 - percentage, color: '#ccc' },
      { value: percentage, color }
    ];
  };

  const calcRadius = chartSize => {
    const outer = chartSize / 2;
    let inner = chartSize / 2.4;

    if (outer - inner <= 4) {
      inner = outer - 4;
    }

    return { outer, inner };
  };

  const radius = calcRadius(size);

  return (
    <div className={classes.root}>
      <RePieChart width={size} height={size}>
        <Pie
          data={getData()}
          innerRadius={radius.inner}
          outerRadius={radius.outer}
          fill="#8884d8"
          startAngle={90}
          endAngle={-270}
          paddingAngle={0}
          dataKey="value"
        >
          {getData().map((item, index) => (
            <Cell key={`cell-${index}`} fill={item.color} />
          ))}
        </Pie>
      </RePieChart>
      <div className={classes.labelContainer} data-testid={labelTestId}>
        <Typography className={classes.mainLabel} style={{ color }}>
          {label}
        </Typography>
        <Typography>{subLabel}</Typography>
      </div>
    </div>
  );
};

PieChart.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string.isRequired,
  maxValue: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  label: PropTypes.string,
  subLabel: PropTypes.string,
  labelTestId: PropTypes.string
};

PieChart.defaultProps = {
  size: 200,
  subLabel: ''
};

export default PieChart;
