
import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import PlanStatusMenu from './PlanStatusMenu';
import PlanStatusCard from './PlanStatusCard';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useSelector } from 'react-redux';
import { clsx } from 'clsx';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


function SliderNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <NavigateNextIcon />
    </div>
  );
}

function SliderPrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <NavigateBeforeIcon />
    </div>
  );
}

const PlanStatusWithCards = () => {
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up('md'));

  const { ascensionPlanCardLogo, nationalNetworkIcon } = useSelector(state => state.tenant.tenanantResource);

const mockPlanDetails = [
  {
    tier: 'Tier 1',
    tierCurrencyIndicator: '$',
    networkName: 'Ascension network',
    networkType: 'Preffered network',
    familyDeductibleSpent: '3,000',
    familyDeductibleOutOf: '6,000',
    oPMaxSpent: '3,000',
    oPMaxOutOf: '6,000',
    icon: ascensionPlanCardLogo
  },
  {
    tier: 'Tier 2',
    tierCurrencyIndicator: '$$$',
    networkName: 'National network I',
    networkType: 'Blue Cross Blue Shield Network',
    familyDeductibleSpent: '3,000',
    familyDeductibleOutOf: '7,000',
    oPMaxSpent: '3,000',
    oPMaxOutOf: '1,400',
    icon: nationalNetworkIcon
  },
  {
    tier: 'Tier 3',
    tierCurrencyIndicator: '$$$',
    networkName: 'National network II',
    networkType: 'Blue Cross Blue Shield Network',
    familyDeductibleSpent: '2,000',
    familyDeductibleOutOf: '6,000',
    oPMaxSpent: '5,000',
    oPMaxOutOf: '900',
    icon: nationalNetworkIcon
  }
];

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          centerMode: false
        }
      }
    ]
  };

  const renderSlides = () => mockPlanDetails?.map((mockPlan, index) => <PlanStatusCard key={index} planDetails={mockPlan} />);

  return (
    <Box>
      <Box className="planStatusWithCardsWrapper">
        <PlanStatusMenu />
        <Box className={clsx('planStatusDetailsWrapper', isMobile && 'planStatusDetailsWrapperMobile')}>
          <Slider {...settings}>
            {renderSlides()}
          </Slider>
        </Box>
      </Box>
    </Box>
  );
};

SliderNextArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
};

SliderPrevArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
};



export default PlanStatusWithCards;