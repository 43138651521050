import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Label from '../type/Label';
import { useNavigate } from 'react-router';
import { PathNames } from '../../../constants/AppConstants';
import { analyticsTrackEvent, getMappedClickEventPathName } from '../../../utils/analytics/analytics-utils';

function HyperLink(props) {
  const tenantResource = useSelector(state => state.tenant.tenanantResource);
  const navigate = useNavigate();
  const handleChange = (pathname) => {

    if (pathname.includes('tel')) {
      /** Amplitude analytics implementation - Logging click events **/
      analyticsTrackEvent('home_header_contactsupport_clicked');
    }
    else if (!pathname.includes('tel')) {
      /** Amplitude analytics implementation - Logging click events **/
      analyticsTrackEvent(`${getMappedClickEventPathName(pathname)}_clicked`, {
        path: pathname
      });

      navigate(`${'/' + pathname}`);
    }


  };
  var path = PathNames && PathNames[props.pathName];
  if (props.external) {
    path = tenantResource[props.pathName];
  }
  return (
    <a id={props.id} onClick={() => handleChange(path)} className={props.className} href={`${path}`}><Label id={props.id} /></a>
  );
}
HyperLink.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  pathName: PropTypes.string,
  title: PropTypes.string,
  external: PropTypes.bool
};
export default HyperLink;
