import axios from 'axios';
import { loginRequest, msalInstance } from '../auth/config';
import { getEnvVars } from '../utils/env-utils';

const { apiUrl, memberKey,memberKeyTenantConfig } = getEnvVars(window.location.hostname);

const mockTenantMemberApi = axios.create(
  {
    baseURL: process.env.PUBLIC_URL,
  }
);

const tenantMemberApi = axios.create({
  baseURL: `${apiUrl}/member-tenant`,
  headers: {
    apikey: memberKeyTenantConfig
  }
});

const memberApi = axios.create({
  baseURL: `${apiUrl}/member`,
  headers: {
    apikey: memberKey
  }
});
const setupInterceptors = () => {


  memberApi.interceptors.request.use(
    async config => {
      const activeAccount = msalInstance.getActiveAccount();
      const request = { ...loginRequest, account: { ...activeAccount } };
      const { accessToken } = await msalInstance.acquireTokenSilent(request);
      config.headers['Authorization'] = `Bearer ${accessToken}`;
      return config;
    },
    error => {
      Promise.reject(error);
    }
  );
  
  tenantMemberApi.interceptors.request.use(
    async config => {
      const activeAccount = msalInstance.getActiveAccount();
      const request = { ...loginRequest, account: { ...activeAccount } };
      const { accessToken } = await msalInstance.acquireTokenSilent(request);
      config.headers['Authorization'] = `Bearer ${accessToken}`;
      config.headers['Content-Type'] = 'application/json';
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );
};

export { memberApi,tenantMemberApi,mockTenantMemberApi, setupInterceptors };
