import { combineReducers } from 'redux';
import { claimsReducer } from './claims/reducer';
import { coveragesReducer } from './coverages/reducer';
import { dependentEnrollmentsDetailsReducer, dependentCoveragesDetailsReducer } from './dependentDetails/reducer';
import { enrollmentsReducer } from './enrollments/reducer';
import { memberAccountsReducer } from './memberAccounts/reducer';
import { memberVerificationReducer } from './memberVerification/reducer';
import { planDetailsReducer } from './planDetails/reducer';
import {tenantReducer} from './tenantinfo/reducer';

const appReducer = combineReducers({
    memberAccounts: memberAccountsReducer,
    memberVerification: memberVerificationReducer,
    tenant: tenantReducer,
    enrollments: enrollmentsReducer,
    coverages: coveragesReducer,
    dependentEnrollments: dependentEnrollmentsDetailsReducer,
    dependentCoverages: dependentCoveragesDetailsReducer,
    planDetails:planDetailsReducer,
    claims:claimsReducer
});

export default (state, action) => appReducer({ ...state }, action);
