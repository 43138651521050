import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getLanguageCookies } from '../../../utils/content-utils';

function HtmlText(props) {
    const tenantHtml = useSelector(state => state.tenant.tenantHtmlContent);
    var language = getLanguageCookies();
    const htmlData = tenantHtml['htmlContents'];
    if (htmlData[props.content][language] === undefined || htmlData[props.content][language] === '') {
        language = 'en';
    }
    return (
        <div dangerouslySetInnerHTML={{ __html: htmlData[props.content][language] }} />
    );
}
HtmlText.propTypes = {
    id: PropTypes.string,
    content: PropTypes.string,
};
export default HtmlText;
