import { memberApi } from '../config/axios-config';




export const getMemberAccountsService = (tenantId) => {
    return memberApi.get('v3/my/member-accounts', {
        headers:{'tenant-id': tenantId}
    });
};

export const memberVerificationService = (requestBody, tenantId) => {
    return memberApi.post('/v3/my/verifications', requestBody, {headers: {'tenant-id': tenantId}});
};

export const memberIDCardService = (memberAccountHeader, tenantId) => {
    return memberApi.get('v3/my/cards/medical?fileFormat=png&base64-encoded-response=true', {
        headers: {
            'tenant-id': tenantId,
            'member-account': memberAccountHeader
        }
    });
};

