
/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { loginRequest } from '../../../auth/config';
import ProfileSetup from '../ProfileSetup';
import { getMemberAccounts, setMemberAccountsClean } from '../../../redux/memberAccounts/action';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDependentCoveragesDetails,
  setDependentEnrollmentsDetails,
  setDependentCoveragesClean,
  setDependentEnrollmentsClean
} from '../../../redux/dependentDetails/action';
import {
  getEnrollments,
  setEnrollmentsClean
} from '../../../redux/enrollments/action';
import {
  getCoverages,
  getCoveredMembers,
  setCoveragesClean
} from '../../../redux/coverages/action';
import genericServiceReducer from '../genericServiceReducer';
import { getCoveragesService, getEnrollmentsService } from '../../../services/planDetailsService';
import { getSanitizedPathName, hasSignUp } from '../functionUtils';
import { getUserAgreementStatus } from '../../../utils/storage-utils';
import UserAgreement from '../../userAgreement/UserAgreement';
import HtmlComponent from '../CustomComponents/HtmlComponent';
import { useSearchParams } from 'react-router-dom';
import InternalError from '../CustomComponents/Error504';
import ErrorLayout from '../CustomComponents/Error404';
import Unidentified from '../../profileSetup/unidentified/Unidentified';
import Unverified from '../../profileSetup/Unverified';
import MemberSupport from '../../pages/membersupport/MemberSupport';
import UnidentifiedBanner from '../../profileSetup/unidentified/UnidentifiedBanner';

const SecureRoute = ({ children }) => {
  const { inProgress, instance } = useMsal();
  const account = instance.getActiveAccount();
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  const serviceDispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const tenantModules = useSelector(state => state.tenant.tenantProducts);
  const tenantLayout = useSelector(state => state.tenant.tenantLayout);
  const tenantLayoutError = useSelector(state => state.tenant.tenantLayoutError);
  const tenantLocaleContentError = useSelector(state => state.tenant.tenantLocaleContentError);
  const memberAccountsFromStore = useSelector(state => state?.memberAccounts?.memberAccountsDetails);
  const isMemberAccountsUpdated = useSelector(state => state?.memberAccounts?.isMemberAccountsUpdated);
  const acceptedUserAgreement = getUserAgreementStatus();
  const [{ status: depStatus }, depDispatch] = React.useReducer(
    genericServiceReducer,
    {
      status: 'idle',
      data: null,
      error: null
    }
  );

  const pendingResponse =
    inProgress === InteractionStatus.Startup ||
    inProgress === InteractionStatus.HandleRedirect;

  React.useEffect(() => {
    
    if(!isAuthenticated && !pendingResponse){
      const request = { ...loginRequest, account };

    instance
      .acquireTokenSilent(request)
      .then(() => {
        console.info('Auth Success');
      })
      .catch(() => {
        instance.acquireTokenRedirect(request).then(() => {
          console.info('Auth Success');
        });
      });

    // return null;
    }
  }, [!pendingResponse]);

  const getPath = () => {
    const hostname = window.location.hostname;
    if (hostname.includes('localhost')) {
      if (searchParams.get('tenantId') === null || searchParams.get('tenantId') === undefined) {
        localStorage.setItem('tenantHandle', 'dchp');
      } else {
        localStorage.setItem('tenantHandle', searchParams.get('tenantId'));
      }
    }
    else if (hostname.includes('beehive') || hostname.includes('dchp')) {
      localStorage.setItem('tenantHandle', 'dchp');
    }
    else if (hostname.includes('smarthealth')) {
      localStorage.setItem('tenantHandle', 'smarthealth');
    }
    else {
      localStorage.setItem('tenantHandle', searchParams.get('tenantId'));
    }
    return localStorage.getItem('tenantHandle');
  };


  React.useState(()=> {
    if (account) {
      ReactGA.set({ userId: account.idTokenClaims.profileId });
    }
  }, [])

  React.useEffect(() => {
    
    if (isAuthenticated) {
      serviceDispatch(getMemberAccounts(getPath()));
    }
    /**Clean up store data on component unmounts */
    return () => {
      serviceDispatch(setMemberAccountsClean());
      serviceDispatch(setDependentEnrollmentsClean());
      serviceDispatch(setDependentCoveragesClean());
      serviceDispatch(setEnrollmentsClean());
      serviceDispatch(setCoveragesClean());
    };
  }, [isAuthenticated]);

  React.useEffect(() => {
    if (
      memberAccountsFromStore?.self?.firstName ||
      memberAccountsFromStore?.dependents?.length > 0
    ) {
      depDispatch({ type: 'started' });
      if (isMemberAccountsUpdated) {
        serviceDispatch(setDependentEnrollmentsClean());
        serviceDispatch(setDependentCoveragesClean());
        serviceDispatch(setEnrollmentsClean());
        serviceDispatch(setCoveragesClean());
        const { self: selfFromStore, dependents: dependentsFromStore } =
          memberAccountsFromStore;
        if (selfFromStore?.firstName) {
          const { groupId, subscriberId, subscriberSuffix } = selfFromStore;
          const memberAccountHeader = `${groupId}-${subscriberId}-${subscriberSuffix}`;
          dispatch(getEnrollments(getPath(), memberAccountHeader));
          dispatch(getCoverages(getPath(), memberAccountHeader));
          dispatch(getCoveredMembers(getPath(), memberAccountHeader));
        }
        if (dependentsFromStore?.length > 0) {
          dependentsFromStore.forEach(member => {
            const { groupId, subscriberId, subscriberSuffix } = member;
            const memberAccountHeader = `${groupId}-${subscriberId}-${subscriberSuffix}`;

            getEnrollmentsService(getPath(), memberAccountHeader)
              .then(res => {
                dispatch(setDependentEnrollmentsDetails(res.data));

                getCoveragesService(getPath(), memberAccountHeader)
                  .then(res => {
                    dispatch(setDependentCoveragesDetails(res.data));
                  })
                  .catch(err => {
                    depDispatch({ type: 'error', error: err });
                  });
              })
              .catch(err => {
                depDispatch({ type: 'error', error: err });
              })
              .finally(() => {
                depDispatch({ type: 'success' });
              });
          });
        } else {
          depDispatch({ type: 'success' });
        }
      } else {
        depDispatch({ type: 'success' });
      }
    }
  }, [memberAccountsFromStore]);

  if (isAuthenticated) {
    const path = getSanitizedPathName(window.location.pathname);
    const hasUserAgreement = tenantModules && tenantModules.modules && Boolean((tenantModules.modules).indexOf('userAgreementPage') > -1);
    const userAgreementPaths = tenantLayout && tenantLayout.userAgreementPage;
    if (depStatus === 'pending') {
      return <ProfileSetup loading={true} />;
    } else {
      if (tenantLayoutError &&  tenantLocaleContentError) {
        return <InternalError />
      }
      if(getSanitizedPathName(window.location.pathname) === 'unverified'){
        return <Unverified />
      }
      if(hasUserAgreement && !acceptedUserAgreement && userAgreementPaths && (path ===userAgreementPaths?.termsPath || path===userAgreementPaths?.privacyPath )){
        return <div className='userAgreementLinks'><HtmlComponent content={path} /></div>;
      }
      if(hasUserAgreement && !acceptedUserAgreement && (localStorage.getItem('showProfileSetup') === 'true' || memberAccountsFromStore?.self?.firstName ||
      memberAccountsFromStore?.dependents?.length > 0 )){
        return <UserAgreement config='userAgreementPage' />;
      }
      if(getSanitizedPathName(window.location.pathname) === 'unidentified'){
        return <Unidentified />
      }
      
      if(getSanitizedPathName(window.location.pathname) === 'memberSuppport'  && localStorage.getItem('showProfileSetup') === 'false'){
        return <><UnidentifiedBanner /><MemberSupport /></>
      }
     
      if (acceptedUserAgreement && localStorage.getItem('showProfileSetup') === 'true') {
        return children;
      }

      
      if ( acceptedUserAgreement&&
        memberAccountsFromStore?.self?.firstName ||
        memberAccountsFromStore?.dependents?.length > 0
      ) {
        return children;
      }
      if ((tenantModules && hasSignUp(tenantModules.modules)) && (!memberAccountsFromStore?.self?.firstName || !memberAccountsFromStore?.dependents?.length)) {
        return <ProfileSetup />;
      }
      
      
      return children;
    }
  }

  else if (pendingResponse) {
    return null;
  }
   
};

SecureRoute.propTypes = {
  element: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  children: PropTypes.any
};

export default SecureRoute;