import AscensionCard from '../../home/AscensionCard';
import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import HistoricalCoveredMembersCard from '../../../common/CustomComponents/smartHealth/HistoricalCoveredMembersCard';

const HistoricallyCoveredMembersPanel=()=>{
  const tenantLayouts = useSelector(state => state.tenant.tenantLayout);
  const CoverMemberConfig=tenantLayouts.BenefitsCoveredMembersMainLayout.CoveredMembersConfig;
  const allMembers=[
    {
        'firstName': 'sadia',
        'lastName': 'ameen',
        'subscriberId': '770141497',
        'subscriberSuffix': '0',
        'groupId': 'DCHPTXMD',
        'hashedKey': 'fTNhP5f2I/djwqYomlNg1EThbPG4pjHN5a3GVXGo6QY='
    },
    {
        'firstName': 'sadia',
        'lastName': 'ameen',
        'subscriberId': '770141497',
        'subscriberSuffix': '0',
        'groupId': 'DCHPTXMD',
        'hashedKey': 'fTNhP5f2I/djwqYomlNg1EThbPG4pjHN5a3GVXGo6QY='
    }
  ];
return(<>
<AscensionCard >
  {allMembers?.map((member,i)=>{ if (member?.firstName) {
          return (
          <Box
            key={`healthcare-id-card-${i}`}
            sx={[
              { paddingTop: 3},
              i < allMembers?.length -1 && {
                borderBottom: '1px solid #D3D3D3', paddingBottom: 3 
              }
            ]}
          >
            <HistoricalCoveredMembersCard config={CoverMemberConfig} member={member} details='HDHP OB'  />
          </Box>
          );
        }
      }
  )
    }

</AscensionCard>

</>);
};
export default HistoricallyCoveredMembersPanel;
