import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';import FormHelperText from '@mui/material/FormHelperText';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddDependentFromReducer from './AddDependentFromReducer';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import {
  memberVerification,
  setMemberVerificationClean
} from '../../redux/memberVerification/action';
import Alert from '@mui/material/Alert';
import ErrorIcon from '@mui/icons-material/Error';
import { useDispatch, useSelector } from 'react-redux';
import Label from '../common/type/Label';
import { useNavigate } from 'react-router';
import { clearUserAttempts, getUserAttempts, setUserAttempts } from '../../utils/content-utils';
import { analyticsTrackEvent } from '../../utils/analytics/analytics-utils';

const CustomInputLabel = styled(InputLabel)(() => ({
  position: 'relative',
  fontWeight: '550',
  transform: 'none',
  WebkitTransform: 'none',
  marginBottom: '8px'
}));

const AddDependentModal = ({
  dependentsList,
  setDependentsList,
  open,
  setOpen,
  setDisplaySuccess,
  handleSubmit
}) => {
  const serviceDispatch = useDispatch();
  const navigate = useNavigate();

  const [displayError, setDisplayError] = React.useState(false);
  const [dob, setDob] = React.useState(null);
  const mvDetailsFromStore = useSelector(
    state => state?.memberVerification?.memberVerificationDetails
  );
  const mvLoadingFromStore = useSelector(
    state => state?.memberVerification?.loading
  );
  const mvErrorFromStore = useSelector(
    state => state?.memberVerification?.error
  );
  const isLoading = mvLoadingFromStore;

  const handleClose = () => {
    setDob(null);
    setDisplayError(false);
    dispatch({
      type: 'CLEANUP',
      action: initialFormState
    });
    serviceDispatch(setMemberVerificationClean());
    if (mvDetailsFromStore?.status === 200) {
      setDisplaySuccess({ status: true, displayName: formState.firstName });
      if(getUserAttempts(formState.medicaidId+'-count')){
        //clear the cookie if found
        clearUserAttempts(formState.medicaidId+'-count');
      }
      if(localStorage.getItem('showProfileSetup') === 'false'){
        localStorage.setItem('showProfileSetup', 'true');
      }
    }
    handleSubmit();
    setOpen(false);
  };

  const initialFormState = {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    medicaidId: '',
    firstNameError: false,
    lastNameError: false,
    dateOfBirthError: false,
    medicaidIdError: false
  };

  const [formState, dispatch] = React.useReducer(
    AddDependentFromReducer,
    initialFormState
  );

  const handleInputChange = e => {
    let value = e.target.value;
    if (value !== '') {
      if (e.target.name === 'dateOfBirth') {
        value = moment(e.target.value).format('YYYY-MM-DD');
      }
    }
    dispatch({
      type: 'HANDLE_INPUT_TEXT',
      field: e.target.name,
      payload: value
    });
  };

  const handleAddDependent = () => {
    if (
      !formState.firstName ||
      !formState.lastName ||
      !formState.dateOfBirth ||
      !formState.medicaidId
    ) {
      dispatch({ type: 'ON_SUBMIT' });
      return;
    }
    let requestBody = { ...formState, isDependent: true };
    setDisplayError(false);
    serviceDispatch(memberVerification(requestBody, localStorage.getItem('tenantHandle')));
    analyticsTrackEvent('profile_add_dependent_clicked');
  };

  const isFormValid = () => {
    return (
      !!formState.firstName &&
      !!formState.lastName &&
      !!formState.dateOfBirth &&
      !!formState.medicaidId
    );
  };

  React.useEffect(() => {
    if (mvDetailsFromStore?.status === 200) {
      setDependentsList([...dependentsList, formState]);
      handleClose();
    }
  }, [mvDetailsFromStore]);

  React.useEffect(() => {
    setDisplayError(mvErrorFromStore);

    if(mvErrorFromStore && (mvErrorFromStore?.response?.status === 404 || mvErrorFromStore?.response?.status >= 500)){
      if(!getUserAttempts(formState && formState.medicaidId+'-count')){
      //set the count to 1 for that mediciaid id
      setUserAttempts(formState.medicaidId+'-count', 1);
    }else if(getUserAttempts(formState && formState.medicaidId+'-count')<2){
      //set and increase the count by 1 
      setUserAttempts(formState.medicaidId+'-count', Number(getUserAttempts(formState.medicaidId+'-count'))+1);
    }else if (getUserAttempts(formState && formState.medicaidId+'-count')<3 || getUserAttempts(formState.medicaidId+'-count')>=3){
      //increase count by 1 and redirect to uunidentified view
      setUserAttempts(formState.medicaidId+'-count', Number(getUserAttempts(formState.medicaidId+'-count'))+1);
      navigate('/unidentified');
    }
    } 
  }, [mvErrorFromStore]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='md'>
      <DialogTitle id='dependantInformation'><Label id='dependantInformation'/></DialogTitle>
      <form onSubmit={e => e.preventDefault()}>
        <DialogContent sx={{ padding: '32px' }}>
          <Box mb={4}>
            {displayError && mvErrorFromStore?.response?.status === 404 && (
              <Alert
                icon={<ErrorIcon sx={{ color: '#E00019' }} />}
                sx={{
                  backgroundColor: '#ffffff',
                  border: '1px solid #E00019',
                  borderLeft: '4px solid #E00019'
                }}
                severity='error'
                onClose={() => setDisplayError(false)}
                id='userNotFoundError'
              >
               <Label id='404Error'/>
              </Alert>
            )}

            {displayError && mvErrorFromStore?.response?.status >= 500 && (
              <Alert
                icon={<ErrorIcon sx={{ color: '#E00019' }} />}
                sx={{
                  backgroundColor: '#ffffff',
                  border: '1px solid #E00019',
                  borderLeft: '4px solid #E00019'
                }}
                severity='error'
                onClose={() => setDisplayError(false)}
                id='internalServerError'
              >
                <Label id='internalServerError'/>
              </Alert>
            )}
          </Box>
          <Box mt={3}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl variant='standard' fullWidth>
                  <CustomInputLabel shrink htmlFor='dependent-first-name' id='firstName'>
                  <Label id='firstName' />{' *'}
                  </CustomInputLabel>
                  <OutlinedInput
                    notched={false}
                    placeholder='First name'
                    label='First name'
                    fullWidth
                    id='dependent-first-name'
                    name='firstName'
                    value={formState.firstName}
                    aria-describedby='First name'
                    sx={{ height: 48 }}
                    onBlur={handleInputChange}
                    onChange={handleInputChange}
                    error={formState.firstNameError}
                  />
                </FormControl>
                {formState.firstNameError && (
                  <FormHelperText
                    className='Mui-error'
                    id='date-of-birth-helper-text'
                  >
                    <Label id='formFieldRequired'/>
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={6}>
                <FormControl variant='standard' fullWidth>
                  <CustomInputLabel shrink htmlFor='dependent-last-name' id='lastName'>
                  <Label id='lastName'/>{' *'}
                  </CustomInputLabel>
                  <OutlinedInput
                    notched={false}
                    placeholder='Last name'
                    label='Last name'
                    fullWidth
                    id='dependent-last-name'
                    name='lastName'
                    value={formState.lastName}
                    aria-describedby='Last name'
                    sx={{ height: 48 }}
                    onBlur={handleInputChange}
                    onChange={handleInputChange}
                    error={formState.lastNameError}
                  />
                </FormControl>
                {formState.lastNameError && (
                  <FormHelperText
                    className='Mui-error'
                    id='date-of-birth-helper-text'
                  >
                    <Label id='formFieldRequired'/>
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Box>
          <Box mt={3}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl variant='standard' fullWidth>
                  <CustomInputLabel shrink htmlFor='dependent-date-of-birth' id='dateOfBirth'>
                  <Label id='dateOfBirth' />{' *'}
                  </CustomInputLabel>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      disableFuture
                      onChange={newValue => {
                        setDob(newValue);
                      }}
                      id='dependent-date-of-birth'
                      name='dateOfBirth'
                      value={dob}
                      disableOpenPicker
                      onBlur={handleInputChange}
                      error={formState.dateOfBirthError}
                      renderInput={params => (
                        <OutlinedInput
                          {...params}
                          id='date-of-birth'
                          name='dateOfBirth'
                          error={formState.dateOfBirthError}
                          onBlur={handleInputChange}
                          sx={{ height: 48 }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {formState.dateOfBirthError ? (
                    <FormHelperText
                      className='Mui-error'
                      id='date-of-birth-helper-text'
                    >
                      Example: 01/01/2010.
                    </FormHelperText>
                  ) : (
                    <FormHelperText id='date-of-birth-helper-text'>
                      Example: 01/01/2010
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant='standard' fullWidth>
                  <CustomInputLabel shrink htmlFor='dependent-med-id' id='medicaidId'>
                  <Label id='medicaidId' />{' *'}
                  </CustomInputLabel>
                  <OutlinedInput
                    fullWidth
                    notched={false}
                    placeholder='Medicaid ID'
                    label='Medicaid ID'
                    id='dependent-med-id'
                    name='medicaidId'
                    value={formState.medicaidId}
                    //endAdornment={<InputAdornment position='end'><InfoIcon fontSize='small' /></InputAdornment>}
                    aria-describedby='Medicaid ID'
                    sx={{ height: 48 }}
                    onBlur={handleInputChange}
                    onChange={handleInputChange}
                    error={formState.medicaidIdError}
                  />
                  {formState.medicaidIdError ? (
                    <FormHelperText
                      className='Mui-error'
                      id='date-of-birth-helper-text'
                    >
                      Example: 547825698
                    </FormHelperText>
                  ) : (
                    <FormHelperText id='medicaid id-helper-text'>
                      Example: 547825698
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            padding: '0 32px 32px',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Button sx={{ color: '#E00019' }} onClick={handleClose} id='cancel'>
            <Label id='cancel'/>
          </Button>
          <Button
            disabled={!!isLoading || !isFormValid()}
            type='submit'
            variant='contained'
            onClick={handleAddDependent}
            sx={{ height: 48 }}
            id='addDependant'
          >
            {isLoading && (
              <CircularProgress
                size='16px'
                sx={{ color: '#ffffff', marginRight: '10px' }}
              />
            )}{' '}
            <Label id='addDependant'/>
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

AddDependentModal.propTypes = {
  dependentsList: PropTypes.array,
  setDependentsList: PropTypes.func,
  open: PropTypes.any,
  setOpen: PropTypes.func,
  setDisplaySuccess: PropTypes.func,
  handleSubmit:PropTypes.func
};

export default AddDependentModal;