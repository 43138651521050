
import React from 'react';
import { Route, Routes } from 'react-router';
import { useSelector } from 'react-redux';
import CustomComponent from '../components/common/CustomComponent';
import Pages from '../constants/pages';
import { PathNames } from '../constants/AppConstants';

const CustomRoutes = () => {
 const tenantLayouts = useSelector(state => state.tenant.tenantLayout);
  return (
    <Routes>
      {Pages[localStorage.getItem('tenantHandle')]&&Pages[localStorage.getItem('tenantHandle')].map(page => {
        const Component = page?.component ;
        const { path, title, config } = page;
        const pathname = PathNames[path];
        return (
          <Route key={path} exact path={pathname} element={<CustomComponent componentName={Component} title={title} config={tenantLayouts && tenantLayouts[config]}/>} />
        );
      })}
      
    </Routes>
  );
};

export default CustomRoutes;