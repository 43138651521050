import { benefitsSubMenuItems ,profileSubMenuItems} from '../../constants/constants';
import { planNames } from '../../constants/constants';
import moment from 'moment';

export const checkSubMenuChildIsActive = value => {
  return benefitsSubMenuItems.includes(value);
};
export const checkProfileChildIsActive = value => {
  return profileSubMenuItems.includes(value);
};
export const getSanitizedPathName = value => {
  return value.replace(/\//g, '');
};

export const getSanitizedText = value => {
  return value.substring(value.search(/n*</) + 1, value.search(/n*>/));
};

export const checkLabelHasDynamicText = value => {
  return (/n*<*>n*/).test(value);
};

export const captilizeString = string => {
  if (!string || (typeof string !== 'string')) return;
  return string
    .split(' ')
    .map(word => {
      let updatedString = word.toLowerCase();
      return updatedString.charAt(0).toUpperCase() + updatedString.slice(1);
    })
    .join(' ');
};

export const formatDate = date => {
  if (typeof date === 'string') {
    return moment(date).format('MM/DD/YYYY');
  } else {
    return '-';
  }
};

export const mapPlanName = name => {
  if (!name || (typeof name !== 'string')) return;
  let newName = name.toLowerCase();

  switch (newName) {
    case planNames.DELL_CHILDRENS_TANF_ADULT.toLowerCase():
      return planNames.DELL_CHILDRENS_TANF_ADULT;

    case planNames.DELL_CHILDRENS_CHILD.toLowerCase():
      return planNames.DELL_CHILDRENS_CHILD;

    case planNames.DELL_CHILDRENS_AAPCA_CHILD.toLowerCase():
      return planNames.DELL_CHILDRENS_AAPCA_CHILD;

    case planNames.DELL_CHILDRENS_TEMP_NEWBORN.toLowerCase():
      return planNames.DELL_CHILDRENS_TEMP_NEWBORN;

    case planNames.DELL_CHILDRENS_AAPCA_TEMP_NEWBORN.toLowerCase():
      return planNames.DELL_CHILDRENS_AAPCA_TEMP_NEWBORN;

    case planNames.DELL_CHILDRENS_PREGNANT_WOMEN_21_PW.toLowerCase():
      return planNames.DELL_CHILDRENS_PREGNANT_WOMEN_21_PW;

    case planNames.DELL_CHILDRENS_AAPCA_PREGNANT_WOMEN_21_PW.toLowerCase():
      return planNames.DELL_CHILDRENS_AAPCA_PREGNANT_WOMEN_21_PW;

    case planNames.DELL_CHILDRENS_PREGNANT_WOMEN_21_AND_OLDER_PW.toLowerCase():
      return planNames.DELL_CHILDRENS_PREGNANT_WOMEN_21_AND_OLDER_PW;

    default:
      return name;
  }
};

export const checkCustomIcon = value => {
  return (/n*customn*/).test(value);
};

export const getSanitizedIconName = value => {
  return value.substring(7, value.length);
};
      
export const hasSignUp = (value) => {
  return value.indexOf('signUpPage')> -1;
};
