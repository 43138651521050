import constants from './constants';

const { SET_ENROLLMENTS_DETAILS, SET_ENROLLMENTS_DETAILS_CLEANUP, SET_COVERAGES_DETAILS, SET_COVERAGES_DETAILS_CLEANUP } = constants;

export const setDependentEnrollmentsDetails = data => ({
    type: SET_ENROLLMENTS_DETAILS,
    payload: data
});

export const setDependentEnrollmentsClean = () => ({
    type: SET_ENROLLMENTS_DETAILS_CLEANUP
});

export const setDependentCoveragesDetails = data => ({
    type: SET_COVERAGES_DETAILS,
    payload: data
});

export const setDependentCoveragesClean = () => ({
    type: SET_COVERAGES_DETAILS_CLEANUP
});