import { memberApi } from '../config/axios-config';

export const getEnrollmentsService = (tenantId,memberAccountHeader) => {
  return memberApi.get('/v3/my/enrollments', {
    headers: { 'member-account': memberAccountHeader,'tenant-id':tenantId }
  });
};

export const getCoveragesService = (tenantId,memberAccountHeader) => {
  return memberApi.post('/v3/my/coverages', {
    fromDate: '2020-01-01',
    toDate: '2022-11-01',
    eligibilityIndicator: true
  }, {
    headers: { 'member-account': memberAccountHeader,'tenant-id':tenantId  }
  });
};

export const getCoveredMembersService = (tenantId,memberAccountHeader) => {
  return memberApi.get('/v2/my/coveredMembers', {
    headers: { 'member-account': memberAccountHeader,'tenant-id':tenantId  }
  });
};

export const planDetailsService = (tenantId,requestBody) => {
  return memberApi.post('/v3/my/plandetails', requestBody,{headers:{'tenant-id':tenantId}});
};

export const planStatusService = (requestBody, tenantId) => {
  return memberApi.post('/v3/my/planstatus', requestBody, {headers:{'tenant-id': tenantId}});
};