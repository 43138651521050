
import React from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
// import { useSelector } from 'react-redux';
import PlanDetailsSpentIndicator from './PlanDetailsSpentIndicator';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';



const PlanStatusCard = (props) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    // const { ascensionPlanCardLogo } = useSelector(state => state.tenant.tenanantResource);
    const { tier, networkName, networkType, tierCurrencyIndicator, icon } = props.planDetails;
    return (
        <Box>
            <Paper className={isDesktop ? 'planStatusCardWrapper' : 'planStatusCardWrapperMobile'}>
                <Box className="planStatusCardChipWrapper">
                    <Chip className="planStatusCardChip" label={`${tier} (${tierCurrencyIndicator})`} />
                </Box>
                <Box className="pSnetworkWrapper">
                    <Box>
                        <Typography className="pSnetworkTitle" variant="h5">{networkName}</Typography>
                        <Typography className="pSnetworkType" variant="body2">{networkType}</Typography>
                    </Box>
                    <img src={icon} />
                </Box>

                <Box className="PlanDetailsSpentWrapper">
                    <PlanDetailsSpentIndicator spentIndicatorDetails={props.planDetails} />
                </Box>
                <Box className="pSButtonSection">
                    <Button sx={{fontSize: '13px', letterSpacing: '0.46px'}} variant="text">SEE DETAILS</Button>

                </Box>
            </Paper>
        </Box>
    );
};

PlanStatusCard.propTypes = {
    planDetails: PropTypes.obj
};

export default PlanStatusCard;