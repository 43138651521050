import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App/App';
import initAuth from './auth/initAuth';
import { store } from './redux/store';
import { ascensionTheme } from '@ascension/ui-library';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { MsalProvider } from '@azure/msal-react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { getEnvVars } from './utils/env-utils';
import { initAnalytics } from './utils/analytics/analytics-utils';

const msalInstance = initAuth();
const root = ReactDOM.createRoot(document.getElementById('root'));
const createdAscensionTheme = createTheme(ascensionTheme);

/** ANALYTICS INTEGRATION :: Amplitude analytics implementation - initializating right after login **/

const { amplitudeApiKey } = getEnvVars();
initAnalytics(amplitudeApiKey, msalInstance);

root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance} >
            <Provider store={store}>
                <ThemeProvider theme={createdAscensionTheme}>
                    <CssBaseline />
                    <BrowserRouter>
                        <App/>
                    </BrowserRouter>
                </ThemeProvider>
            </Provider>
        </MsalProvider>
    </React.StrictMode>
);