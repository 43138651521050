import React from 'react';
import { useSelector } from 'react-redux';
import RowContainer from '../common/CustomComponents/RowContainer';

const Pharmacy = () => {
  const tenantLayouts = useSelector(state => state.tenant.tenantLayout);
    return (
      tenantLayouts['pharmacy'] && 
        <div className={tenantLayouts['pharmacy'].className} id={tenantLayouts['pharmacy'].id}>
        {tenantLayouts['pharmacy'].sections.map((item,index) =>{
          return <RowContainer key={index} {...item} />;
        })}
        </div>
    );
};

export default Pharmacy;