import { planDetailsService } from '../../services/planDetailsService';
import { trackPromise } from 'react-promise-tracker';
import constants from './constants';
import moment from 'moment';


// action creators
export const setPlanDetailsBegin = () => ({
  type: constants.SET_PLAN_DETAILS_BEGIN
});

export const setPlanDetailsSuccess = data => ({
  type: constants.SET_PLAN_DETAILS_SUCCESS,
  payload:  data 
});

export const setPlanDetailsError = error => ({
  type: constants.SET_PLAN_DETAILS_ERROR,
  payload:  error 
});

export const setPlanDetailsClean = () => ({
  type: constants.SET_PLAN_DETAILS_CLEANUP
});


export const fetchPlanDetails = (tenantId) => {
  return (dispatch) => {
      trackPromise(
          planDetailsService(tenantId,{
            asOfDate: moment(new Date()).format('YYYY-MM-DDThh:mm:ss'),
            accountType: 'this-is-not-actually-required',
            planCategory: 'M'
          }).then(res => dispatch(setPlanDetailsSuccess(res.data)))
              .catch(err => dispatch(setPlanDetailsError(err)))
      );
  };
};
