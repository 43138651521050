import constants from './constants';
import { trackPromise } from 'react-promise-tracker';
import { getEnrollmentsService } from '../../services/planDetailsService';

const { SET_ENROLLMENTS_BEGIN, SET_ENROLLMENTS_SUCCESS, SET_ENROLLMENTS_ERROR, SET_ENROLLMENTS_CLEANUP } = constants;

export const setEnrollmentsBegin = () => ({
    type: SET_ENROLLMENTS_BEGIN
});

export const setEnrollmentsSuccess = data => ({
    type: SET_ENROLLMENTS_SUCCESS,
    payload: { data }
});

export const setEnrollmentsError = error => ({
    type: SET_ENROLLMENTS_ERROR,
    payload: { error }
});

export const setEnrollmentsClean = () => ({
    type: SET_ENROLLMENTS_CLEANUP
});

export const getEnrollments = (tenantId,memberAccountHeader) => {
    return (dispatch) => {
        dispatch(setEnrollmentsBegin());
        trackPromise(
            getEnrollmentsService(tenantId,memberAccountHeader)
                .then(res => dispatch(setEnrollmentsSuccess(res.data)))
                .catch(err => dispatch(setEnrollmentsError(err)))
        );
    };
};
