export const keys = {
  USER_AGREEMENT: 'member-user-agreement'
};

export const getUserAgreementStatus = () =>{
  return Boolean(window.localStorage.getItem(keys.USER_AGREEMENT));
};

export const setUserAgreementStatus = value =>{
  return window.localStorage.setItem(keys.USER_AGREEMENT, value);
};
