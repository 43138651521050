import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { captilizeString } from '../functionUtils';
import PropTypes from 'prop-types';
import Label from '../type/Label';
import CustomIcon from './CustomIcon';
import { analyticsTrackEvent } from '../../../utils/analytics/analytics-utils';

const MemberCard = (props) => {
    return (props.config &&
        <Grid container className={props.className} alignItems="center">
            <Grid style={{ paddingLeft: 40 }} item xs={4.5}>
                <strong>{`${captilizeString(props.member?.firstName)} ${captilizeString(props.member?.lastName)}`}</strong>
            </Grid>
             {/* <Grid item xs>
              FIXME: || Hiding temporarily for sep/1st release. Recommendation is to replace subscriberId with MedicaidId 
                <Box>
                  <Typography>
                    <strong>ID Number</strong>
                  </Typography>
                  <Typography>{member?.subscriberId}</Typography>
                </Box> 
              </Grid>*/}
           {props.details && <Grid item xs={4.5}>
                <Box>
                    <Typography>{props.details}</Typography>
                </Box>
            </Grid>}
            <Grid item xs={3}>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={e => {
                        e.preventDefault();
                        props.buttonAction(props.member);
                        analyticsTrackEvent('benefits_coveredmembers_handbook_clicked', {eventCategory: 'Plan Benefits Pathing'});
                    }}
                >
                 
                    <CustomIcon iconName={props.config.buttonIcon}/>
                 <Label id={props.config.buttonId} />
                </Button>
            </Grid>
        </Grid>
    );
};

MemberCard.propTypes = {
    member: PropTypes.any,
    config:PropTypes.object,
    buttonAction: PropTypes.func,
    className:PropTypes.string,
    details:PropTypes.string
};
export default MemberCard;
