import React from 'react';
import { useSelector } from 'react-redux';
import RowContainer from '../common/CustomComponents/RowContainer';

const Footer = () => {
  const tenantLayout = useSelector(state => state.tenant.tenantLayout);
    return (
      tenantLayout['footer'] && tenantLayout['footer'].sections&&
        <div className={tenantLayout['footer'].className} id={tenantLayout['footer'].id}>
        { tenantLayout['footer'].sections.map((item, index) =>
          <RowContainer key={index} {...item} />
        ) }
        </div>
    
    );
};

export default Footer;
