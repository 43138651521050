import { Button, Tooltip, Typography } from '@mui/material';
import React from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { checkProfileChildIsActive, getSanitizedPathName } from '../../common/functionUtils';
import SubMenu from './SubMenu';

function IconWithButton(props) {
    const [subMenuAnchor, setSubMenuAnchor] = React.useState(null);
    const [subMenuOptions, setSubMenuOptions] = React.useState(null);
    const { instance } = useMsal();
    const location = useLocation();
    const activeSubmenuItem = getSanitizedPathName(location.pathname);
    const account = instance.getActiveAccount();
    const openSubMenu = (anchorEl, options) => {
        setSubMenuOptions(options);
        setSubMenuAnchor(anchorEl);
    };

    const closeSubMenu = () => {
        setSubMenuOptions(null);
        setSubMenuAnchor(null);
    };
    return (
        <>
            { props.nestedMenu && props.nestedMenu ? (
                <Tooltip title={props.tooltip}>
                    <Button
                        startIcon={props.Icon && <AccountCircleIcon />}
                        id={props.id}
                        key={props.id}
                        onClick={e => openSubMenu(e.currentTarget, props.nestedMenu)}
                        className={`${props.className} ${checkProfileChildIsActive(activeSubmenuItem) ? 'active':''}`}
                    >
                        <Typography id={props.id} className={props.className}>{account && account.idTokenClaims?.firstName}</Typography>
                    </Button>
                </Tooltip>
            ) : (
                <Tooltip title={props.tooltip}>
                    <Button
                        startIcon={props.Icon && <AccountCircleIcon />}
                        id={props.id}
                        key={props.id}
                        // onClick={handleCloseNavMenu}
                        className={props.className}
                        component={props.external ? 'a' : NavLink}
                    >
                        <Typography id={props.id} >{account && account.idTokenClaims?.firstName}</Typography>
                    </Button>
                </Tooltip>
            )
            }
            {subMenuOptions && subMenuAnchor && <SubMenu
                anchor={subMenuAnchor}
                onClose={closeSubMenu}
                // navigate={navigate}
                options={subMenuOptions}
                activeSubmenuItem={activeSubmenuItem}
                text={account && account.idTokenClaims?.firstName}
            />}
        </>
    );
}
IconWithButton.propTypes = {
    tooltip: PropTypes.string,
    Icon: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.string,
    id: PropTypes.string,
    pathName: PropTypes.string,
    external: PropTypes.bool,
    nestedMenu: PropTypes.any,
};

export default IconWithButton;
