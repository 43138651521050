import React from 'react';
import PropTypes from 'prop-types';
import AmountMetDisplay from '../AmountMetDisplay/AmountMetDisplay';
import PieChart from '../PieChart/PieChart';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { monitaryFormat } from '../../../../../utils/format-utils';
import Label from '../../../type/Label';

const PlanStatusChart = ({
  amountMet,
  maxAmount,
  hasFamilyInPlan,
  color,
  title,
  metLabel,
  inlineTitle,
  ...rest
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const size = isMobile ? 150 : 190;

  return (
    <div {...rest}>
      {amountMet === maxAmount ? (
        <>
          <div id="FamilyPlanText">
            {hasFamilyInPlan && (
              <Typography>
                <b><Label id='Family'/></b>
              </Typography>
            )}
            <Typography>
              <b>{title}</b>
            </Typography>
          </div>
          <AmountMetDisplay label={metLabel} color={color} size={size} />
        </>
      ) : (
        <>
          <Typography
            style={{
              display: !inlineTitle && 'flex',
              flexDirection: !inlineTitle && 'column'
            }}
            color="textSecondary"
            id="FamilyPlanText"
          >
            {hasFamilyInPlan && <b><Label id='Family'/></b>} <b>{title}</b>
          </Typography>
          <PieChart
            size={size}
            color={color}
            maxValue={maxAmount}
            value={amountMet}
            label={monitaryFormat(amountMet)}
            subLabel={`of ${monitaryFormat(maxAmount, {
              includeDecimal: false
            })}`}
            labelTestId={`${title}-chart-label`}
          />
        </>
      )}
    </div>
  );
};

PlanStatusChart.propTypes = {
  amountMet: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasFamilyInPlan: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  metLabel: PropTypes.string.isRequired,
  inlineTitle: PropTypes.bool
};

PlanStatusChart.defaultProps = {
  amountMet: 0,
  maxAmount: 0,
  inlineTitle: false
};

export default PlanStatusChart;
