import { tenantMemberApi, mockTenantMemberApi } from '../config/axios-config';
const mock = process.env.REACT_APP_MODE !== 'mock';

export const getTenanatInfoService = (tenantId) => {
    return mock ? mockTenantMemberApi.get('/' + tenantId + '/info.json') : tenantMemberApi.get('v3/member/tenant/' + tenantId + '/info');
};

export const getTenanatModuleService = (tenantId) => {
    return mock ? mockTenantMemberApi.get('/' + tenantId + '/modules.json') : tenantMemberApi.get('v3/member/tenant/' + tenantId + '/modules');
};

export const getTenanatResourceService = (tenantId) => {
    return mock ? mockTenantMemberApi.get('/' + tenantId + '/resources.json') : tenantMemberApi.get('v3/member/tenant/' + tenantId + '/resources');
};

export const getTenantResourceESService = (tenantId) => {
    return mock ? mockTenantMemberApi.get('/' + tenantId + '/resourcesES.json') : tenantMemberApi.get('v3/member/tenant/' + tenantId + '/resourcesES');
};

export const getTenanatLayoutService = (tenantId) => {
    return mock ? mockTenantMemberApi.get('/' + tenantId + '/layouts.json') : tenantMemberApi.get('v3/member/tenant/' + tenantId + '/layouts');
};

export const getTenanatLocaleContentService = (tenantId) => {
    return mock ? mockTenantMemberApi.get('/' + tenantId + '/localeContent.json') : tenantMemberApi.get('v3/member/tenant/' + tenantId + '/localeContent');
};

export const getTenanatHtmlContentService = (tenantId) => {
    return mock ? mockTenantMemberApi.get('/' + tenantId + '/htmlContent.json') : tenantMemberApi.get('v3/member/tenant/' + tenantId + '/htmlContent');
};