import constants from './constants';

export const initialState = {
  claims: [],
  isClaimDetailsOpen: false,
  claimDetails: {},
  dependents: [],
  pagination: {},
  member: {},
  requestParams: {},
  error: null,
  claimDetailsLoading:false,
  claimDetailsError:null
};

export const claimsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.SET_CLAIM_DETAILS_BEGIN:{
      return {
        ...state,
        claimDetailsLoading: true,
        claimDetailsError: null
    };
    }
    case constants.SET_CLAIM_DETAILS_SUCCESS:{
      return {
        ...state,
        claimDetails:{...payload},
        claimDetailsLoading: false,
        claimDetailsError: null
    };
    }
    case constants.SET_CLAIM_DETAILS_ERROR:{
      return {
        ...state,
        claimDetails:{},
        claimDetailsLoading: false,
        claimDetailsError: payload.error
    };
    }
    case constants.SET_CLAIMS: {
      const haveParamsChanged =
        JSON.stringify(state.requestParams) !==
        JSON.stringify(payload.requestParams);

      const appendedClaims = [...state.claims, ...payload.claims].reduce(
        (prev, curr) => {
          if (prev.find(i => i.claimNumber === curr.claimNumber)) {
            return prev;
          } else {
            return [...prev, curr];
          }
        },
        []
      );

      return {
        ...state,
        claims: haveParamsChanged ? [...payload.claims] : appendedClaims,
        dependents: [...payload.dependents],
        pagination: { ...payload.pagination },
        member: { ...payload.member },
        requestParams: { ...payload.requestParams }
      };
    }

    case constants.SET_CLAIMS_ERROR:
      return {
        ...state,
        error: payload
      };
    default:
      return state;
  }
};
