import React from 'react';
import CoveredMembersTabPanel from '../../pages/benefits/CoveredMembersTabPanel';
import PropTypes from 'prop-types';
import IdCardMemberCard from './IdCardMemberCard';
import AccountSection from '../../pages/settings/AccountSection';
import PrivacySection from '../../pages/settings/PrivacySection';
import LanguageSection from '../../pages/settings/LanguageSection';
import AscensionCard from '../../pages/home/AscensionCard';
import ListCards from '../../pages/home/ListCards';
import MajorMedicalPlanOverview from '../majorMedical/PlanOverview';
import RecentClaims from '../majorMedical/claims/RecentClaims';
import PlanStatus from '../majorMedical/planStatus/PlanStatus';
import SmartHealthCoveredMembersCardPanel from '../../pages/smarthealth/medical/SmartHealthCoveredMebersPanel';
import HistoricallyCoveredMembersPanel from '../../pages/smarthealth/medical/HistoricallyCoveredMembersPanel';
import PlanStatusWithCards from './smartHealth/planStatusSection/PlanStatusWithCards';
import FindCare from './smartHealth/FindCare';

const ComponentsList={
    CoveredMembers:CoveredMembersTabPanel,
    SmartHealthCoveredMembers: SmartHealthCoveredMembersCardPanel,
    HistoricallyCoveredMembers:HistoricallyCoveredMembersPanel,
    IdCards:IdCardMemberCard,
    AccountSection:AccountSection,
    PrivacySection:PrivacySection,
    LanguageSection:LanguageSection,
    AscensionCard:AscensionCard,
    QuickLinksCard:ListCards,
    RecentClaims:RecentClaims,
    PlanStatus:PlanStatus,
    MajorMedicalPlanOverview:MajorMedicalPlanOverview,
    PlanStatusWithCards: PlanStatusWithCards,
    FindCare: FindCare
};

function CustomComponent(props) {
    const Component=  props.componentName && ComponentsList[props.componentName];
    return <Component  {...props}/>;
}
CustomComponent.propTypes = {
    componentName: PropTypes.string,
    config: PropTypes.string,
};


export default CustomComponent;