import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import ToggleLanguage from '../../common/CustomComponents/toggleLanguage';
import TextWithContact from '../../common/CustomComponents/TextWithContact';
import IconWithButton from '../../common/CustomComponents/IconWithButton';
import Image from '../../common/CustomComponents/Image';

const useStyles = makeStyles(() => ({
  mainMenuLabel: {
    fontWeight: 600,
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: 12
  },
  customAppBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    boxShadow: 'none'
  },
  headerWrapper: {
    paddingLeft: '48px',
    paddingRight: '48px',
    display: 'flex',
    justifyContent :'space-between'
  },
  topBarLinkWrapper: {
    padding: '11px 60px',
    backgroundColor: '#ffffff',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  topBarLinkLanguage: {
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.6)',
    letterSpacing: 1,
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  topBarLink: {
    fontSize: 12,
    letterSpacing: 1,
    marginRight: 40,
    color: 'rgba(0, 0, 0, 0.6)',
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  topBarContactLink: {
    fontSize: 12,
    letterSpacing: 1,
    marginRight: 40,
    color: 'rgba(0, 0, 0, 0.6)',
    cursor: 'auto',
    textDecoration: 'underline'
  }
}));

const subMenuOptions = [
    {
        'id': 'profileSupport',
        'pathName':'memberSupport',
        'Icon': 'LiveHelp',
        'label': 'Support',
        'className': 'subMenuItem',
        'requiredVerification': true
    },
    {
        'id': 'logout',
        'pathName':'testLogout',
        'Icon': 'Logout',
        'label': 'Log Out',
        'className': 'subMenuItem',
        'requiredVerification': true
    }
];

const UnidentifiedBanner = () => {
  const classes = useStyles();

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        className={classes.topBarLinkWrapper}
      >
        <ToggleLanguage className='topBarLink' id='language' label='Cambiar a Español' defaultLanguageType='en' targetlabel='Change to English' targetedLangugeType='es' />
        <TextWithContact id='contact' className='topBarLink' pathName='memberSupport' />
      </Box>
      <AppBar
        position="static"
        sx={{ backgroundColor: '#fff', padding: '12px' }}
        className={classes.customAppBar}
        elevation={0}
      >
        <Toolbar disableGutters className={classes.headerWrapper}>
          <Image imageId='homepageLogo' altText='Logo' id='homePageLogo' pathName='home' />
          <Box sx={{ flexGrow: 0 }}>
            <IconWithButton id='profile' className='unidentifiedProfileButton' tooltip='Open Settings' Icon='AccountCircle' nestedMenu={subMenuOptions} style={{paddingRight: '40px'}} />
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}; 

export default UnidentifiedBanner;
