import * as React from 'react';

import PropTypes from 'prop-types';
// import * as MuiIcons from '@mui/icons-material';
import IDCards from '../pages/home/IDCards';
import PlanOverview from '../pages/home/modules/PlanOverview';
import ListCards from '../pages/home/ListCards';
import Text from './type/Text';
// import CustomButton from './type/Button';
import AscensionCard from '../pages/home/AscensionCard';
import HomeHeroImageComponent from './HeroImage/HomeHeroImage';
import CustomAlert from './type/Alert';
import MenuButton from './CustomComponents/MenuButton';
import Home from '../pages/home/Home';
import IdCards from '../pages/idcards/IdCards';
import MemberSupport from '../pages/membersupport/MemberSupport';
import Benefits from '../pages/benefits/Benefits';
import Medical from '../pages/benefits/Medical';
import Pharmacy from '../pages/Pharmacy';
import Support from '../pages/Support';
import Settings from '../pages/settings/Settings';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsOfUse from '../pages/TermsOfUse';
import ManageProfile from '../profileSetup/ManageProfile';
import TextComponent from '../../components/common/CustomComponents/TextComponent';
import UserAgreement from '../userAgreement/UserAgreement';

const ComponentLists = {
  IDCards: IDCards,
  PlanOverview: PlanOverview,
  QuickLinksCard: ListCards,
  Text: Text,
  Button: MenuButton,
  HomeHeroImageComponent:HomeHeroImageComponent,
  AscensionCard:AscensionCard,
  IDCardsCard: ListCards,
  Alert:CustomAlert,
  Home:Home,
  IdCards:IdCards,
  MemberSupport:MemberSupport,
  Benefits:Benefits,
  Medical: Medical,
  Pharmacy: Pharmacy,
  Support: Support,
  Settings:Settings,
  PrivacyPolicy:PrivacyPolicy,
  TermsOfUse:TermsOfUse,
  ProfileSetupPage:ManageProfile,
  text: TextComponent,
  UserAgreement:UserAgreement
};

const CustomComponent = (props) => {
  const {componentName} = props;
  const Component = ComponentLists[componentName];

  return <Component {...props} />;
};

CustomComponent.propTypes = {
  componentName: PropTypes.string
};

export default CustomComponent;
