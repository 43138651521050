import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { setUserAgreementStatus } from '../../utils/storage-utils';
import Label from '../common/type/Label';
import BannerLogoOnly from '../Banner/BannerLogoOnly';
import TextComponent from '../common/CustomComponents/TextComponent';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import HtmlComponent from '../common/CustomComponents/HtmlComponent';

// MTMP - user Agreement Page
const UserAgreement = (props) => {
  const navigate = useNavigate();
  const [termsAccepted, setTermsAccepted] = useState(false);

  const tenantLayouts = useSelector(state => state.tenant.tenantLayout);
  const config = tenantLayouts && tenantLayouts[props.config]; 

  const handleAcceptClicked = () => {
    if (termsAccepted) {
      setUserAgreementStatus(termsAccepted);
      navigate('/');
    }
  };

  return (
    <>
      <BannerLogoOnly />
      <TextComponent className='userAgreementTitle' id={config.id} />

      <Container className='userAgreementContainerWrapper'>
        <Grid container className='userAgreementWrapper'>
          <Grid item xs={0} sm={1}></Grid>
          <Grid item xs={12} sm={10}>
            <Paper className='userAgreementContentWrapper'>
              <main className='userAgreementDocumentView'>
                <HtmlComponent content={config.content} />
              </main>
              <FormGroup className='userAgreementCheckBox'>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={termsAccepted}
                      onClick={() => setTermsAccepted(!termsAccepted)}
                      id='userAgreementCheckbox'
                    />
                  }
                  label={
                    <div id='userAgreementClickLabel'>
                      <Label id="userAgreementClickLabel" />
                      <a href={config.termsPath}  rel="noreferrer" target={config.external ? '_blank' :''} className='userAgreementTermsofUse' id='userAgreementTermsofUse'><Label id='footerTerms' /></a>

                      <Label id="and" />
                      <a href={config.privacyPath} rel="noreferrer" target={config.external ? '_blank' :''} className='userAgreementTermsofUse' id='userAgreementPrivacyPolicy'><Label id='footerPrivacy' /></a>
                    </div>
                  }
                />
              </FormGroup>
              <div className='userAgreementAcceptButon'>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleAcceptClicked}
                  disabled={!termsAccepted}
                  style={{padding:'16px'}}
                  id='userAgreementAcceptButon'
                >
                  <Label id={config.buttonLabel} />
                </Button>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={0} sm={1}></Grid>
        </Grid>
      </Container>
    </>
  );
};


UserAgreement.propTypes = {
    config: PropTypes.string,
    
};

export default UserAgreement;
