import React from 'react';
import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import AscensionCard from './AscensionCard';
import IdCardIcon from '../../../assets/IdCard';
import { NavigateNext } from '@mui/icons-material';


const IDCards = () => {
  const links = [
    {
      label: 'View and print ID cards',
      link: '/idCards',
      Icon: IdCardIcon,
      internal: true,
      color: '#B40F87'
    }
  ];

  return (
    <AscensionCard title="ID Cards">
      <List component="nav" aria-labelledby="quick links">
        {links.map((item) => (
          <ListItem button key={item.label}
            component='a'
            target={!item.internal && '_blank'}
            href={item.link}
          >
            <ListItemIcon>
              <item.Icon color={item.color ? item.color : 'secondary'} />
            </ListItemIcon>
            <ListItemText
              primary={item.label}
            />
            <NavigateNext color="secondary" />
          </ListItem>
        ))}
      </List>
    </AscensionCard>
  );
};

export default IDCards;
