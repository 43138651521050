import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Button,
  Divider,
  CircularProgress
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InfoIcon from '@mui/icons-material/Info';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useNavigate } from 'react-router';
import profileSetupFormReducer from './profileSetupFormReducer';
import AddIcon from '@mui/icons-material/Add';
import FormHelperText from '@mui/material/FormHelperText';
import AddDependentModal from './AddDependentModal';
import AddedMemberListItem from './AddedMemberListItem';
import genericServiceReducer from '../common/genericServiceReducer';
import { memberVerificationService } from '../../services/memberAccountsService';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import Alert from '@mui/material/Alert';
import ErrorIcon from '@mui/icons-material/Error';
import { useDispatch, useSelector } from 'react-redux';
import { getMemberAccounts } from '../../redux/memberAccounts/action';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Label from '../common/type/Label';
import { clearUserAttempts, getUserAttempts, setUserAttempts } from '../../utils/content-utils';
import { analyticsTrackEvent } from '../../utils/analytics/analytics-utils';

const useStyles = makeStyles(() => ({
  pageWrapper: {
    backgroundColor: '#FAFAFA',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    paddingBottom: 163
  },
  contentWrapper: {
    maxWidth: 820,
    margin: '0 auto',
    marginTop: 47
  },
  pageTitle: {
    fontFamily: 'Chronicle Text G1',
    fontWeight: 325,
    fontSize: 45,
    margin: 0,
    marginBottom: 46
  },
  customAddButton: {
    width: '100%',
    cursor: 'pointer',
    textAlign: 'center',
    padding: 10,
    border: '1px dashed #1E69D2',
    borderRadius: 8,
    alignItems:'center',
    color: '#1E69D2',
    display: 'flex',
    justifyContent: 'center',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: '#f0f6ff'
    }
  }
}));

const CustomPaper = styled(Paper)(() => ({
  backgroundColor: '#fff',
  borderRadius: 8,
  padding: '32px 24px',
  boxShadow:
    '0px 4px 5px rgba(0, 0, 0, 0.07), 0px 1px 10px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.1)'
}));

const CustomInputLabel = styled(InputLabel)(() => ({
  position: 'relative',
  fontWeight: '550',
  transform: 'none',
  WebkitTransform: 'none',
  marginBottom: '8px'
}));

const ManageProfile = ({
  titleId = 'manageProfile',
  showNavActions = false
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const serviceDispatch = useDispatch();
  const memberAccountsData = useSelector(
    state => state?.memberAccounts?.memberAccountsDetails
  );
  const memberAccountsServiceLoading = useSelector(
    state => state?.memberAccounts?.loading
  );
  const memberAccountsServiceError = useSelector(
    state => state?.memberAccounts?.error
  );

  const [open, setOpen] = React.useState(false);
  const [selfdAdded, setSelfAdded] = React.useState(false);
  const [dependantAdded, setDependantAdded] = React.useState(false);
  const [dependentsList, setDependentsList] = React.useState([]);
  const [hasDependent, setHasDependent] = React.useState(false);
  const [dob, setDob] = React.useState(null);
  const [isMemberVerified, setIsMemberVerified] = React.useState(false);
  const [displayError, setDisplayError] = React.useState(false);
  const [displaySuccess, setDisplaySuccess] = React.useState({
    status: false,
    displayName: ''
  });
  const [mvState, mvDispatch] = React.useReducer(genericServiceReducer, {
    status: 'idle',
    data: null,
    error: null
  });

  const isLoading = mvState?.status === 'pending';
  const verificationError = mvState?.error;

  const initialFormState = {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    medicaidId: '',
    isDependent: false,
    firstNameError: false,
    lastNameError: false,
    dateOfBirthError: false,
    medicaidIdError: false,
    isCovered: false
  };

  const [formState, formDispatch] = React.useReducer(
    profileSetupFormReducer,
    initialFormState
  );

  const handleInputChange = e => {
    let value = e.target.value;
    if (e.target.name === 'isDependent' || e.target.name === 'isCovered') {
      value = e.target.value === 'false' ? false : true;
    }
    if (e.target.name === 'dateOfBirth') {
      value = moment(e.target.value).format('YYYY-MM-DD');
    }
    formDispatch({
      type: 'HANDLE_INPUT_TEXT',
      field: e.target.name,
      payload: value
    });
  };

  const handleSkipButton = () => {

    if(localStorage.getItem('tenantHandle') == 'dchp'){
      localStorage.setItem('showProfileSetup', 'true');
      navigate('/');
    }
    if(localStorage.getItem('tenantHandle') == 'smartHealth'){
      if(localStorage.getItem('showProfileSetup')== 'true'){
        navigate('/');
  
      } else {
        localStorage.setItem('showProfileSetup', 'false');
        navigate('unverified');
      }
    }
    
  };

  const handleDeleteDependent = index => {
    let updatedArr = [...dependentsList];
    if (index !== -1) {
      updatedArr.splice(index, 1);
      setDependentsList(updatedArr);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const isFormValid = () => {
    return (
      !!formState.firstName &&
      !!formState.lastName &&
      !!formState.dateOfBirth &&
      !!formState.medicaidId
    );
  };

  const handleAddDependent = () => {
    setDependantAdded(true);
    // serviceDispatch(getMemberAccounts(localStorage.getItem('tenantHandle')));
  };

  const handleSubmit = () => {
    analyticsTrackEvent('profile_submit_dependent_clicked');
    if (
      !formState.firstName ||
      !formState.lastName ||
      !formState.dateOfBirth ||
      !formState.medicaidId
    ) {
      formDispatch({ type: 'ON_SUBMIT' });
      return;
    }
    let requestBody = { ...formState };
    setDisplayError(false);
    mvDispatch({ type: 'started' });
    memberVerificationService(requestBody, localStorage.getItem('tenantHandle'))
      .then(res => {
        if(getUserAttempts(formState.medicaidId+'-count')){
          //clear the cookie if found
          clearUserAttempts(formState.medicaidId+'-count');
        }
        mvDispatch({ type: 'success', data: res.data.dependents });
        setIsMemberVerified(true);
        if(localStorage.getItem('showProfileSetup') === 'false'){
          localStorage.setItem('showProfileSetup', 'true');
        }
        setDisplayError(false);
        setDisplaySuccess({ status: true, displayName: formState.firstName });
        setSelfAdded(true);
      })
      .catch(err => {
        mvDispatch({ type: 'error', error: err });
        setDisplayError(true);
        if(err.response.status === 404 || err.response.status >= 500){
          if(!getUserAttempts(formState.medicaidId+'-count')){
            //set the count to 1 for that mediciaid id
            setUserAttempts(formState.medicaidId+'-count', 1);
          }else if(getUserAttempts(formState.medicaidId+'-count')<2){
            //set and increase the count by 1 
            setUserAttempts(formState.medicaidId+'-count', Number(getUserAttempts(formState.medicaidId+'-count'))+1);
          }else if (getUserAttempts(formState.medicaidId+'-count')<3 || getUserAttempts(formState.medicaidId+'-count')>=3){
            //increase count by 1 and redirect to uunidentified view
            setUserAttempts(formState.medicaidId+'-count', Number(getUserAttempts(formState.medicaidId+'-count'))+1);
            navigate('/unidentified');
          }
        }
      });
  };

  React.useEffect(() => {
    if (memberAccountsData?.self?.firstName) {
      setIsMemberVerified(true);
    }
    if (memberAccountsData?.dependents?.length > 0) {
      setDependentsList(memberAccountsData.dependents);
      setHasDependent(true);
    }

    /**Calling member-accounts api on 'profile setup compoonent unmount' to get latest data */
    if(!showNavActions  &&(selfdAdded || dependantAdded)  ) {
      setTimeout(()=> {
        serviceDispatch(getMemberAccounts(localStorage.getItem('tenantHandle')));
      },3000);
      setSelfAdded(false);
      setDependantAdded(false);
    }
  }, [memberAccountsData,selfdAdded,dependantAdded]);

  const handleDependentCheck = () => {
    setHasDependent(prev => !prev);
  };

  if (memberAccountsServiceLoading) {
    return (
      <Box className={classes.pageWrapper}>
        <Box className={classes.contentWrapper}>
          <Box id='profileSetupTitle'>
            <h1 className={classes.pageTitle}>
              <Label id={titleId} />
            </h1>
          </Box>

          <CustomPaper>
            <Typography
              variant="h4"
              sx={{ fontSize: 24, marginBottom: '32px' }}
            >
              <Label id="profileSetupYourInformation" />
            </Typography>

            <Box
              style={{
                width: '100%',
                height: '30vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgress />
            </Box>
          </CustomPaper>
        </Box>
      </Box>
    );
  }

  if (
    memberAccountsServiceError &&
    memberAccountsServiceError?.response?.status !== 404
  ) {
    return (
      <Box className={classes.pageWrapper}>
        <Box className={classes.contentWrapper}>
          <h1 className={classes.pageTitle}>
            <Label id={titleId} />
          </h1>
          <CustomPaper>
            <Typography
              variant="h4"
              sx={{ fontSize: 24, marginBottom: '32px' }}
            >
              <Label id="profileSetupYourInformation" />
            </Typography>
            <Box mb={4}>
              <Alert
                icon={<ErrorIcon sx={{ color: '#E00019' }} />}
                sx={{
                  backgroundColor: '#ffffff',
                  border: '1px solid #E00019',
                  borderLeft: '4px solid #E00019'
                }}
                severity="error"
              >
                <Label id="internalServerError" />
              </Alert>
            </Box>
            <Button sx={{ height: '48px' }} onClick={handleSkipButton}>
              <Label id="skipProfileSetup" />
            </Button>
          </CustomPaper>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box className={classes.pageWrapper}>
        <Box className={classes.contentWrapper}>
          <h1 className={classes.pageTitle} id='profileSetupTitle'>
            <Label id={titleId} />
          </h1>
          <CustomPaper>
            <Typography
              variant="h4"
              sx={{ fontSize: 24, marginBottom: '32px' }}
              id='yourInformation'
            >
              <Label id="profileSetupYourInformation" />
            </Typography>

            <Box mb={4} id='verificationError'>
              {displayError && verificationError?.response?.status === 404 && (
                <Alert
                  icon={<ErrorIcon sx={{ color: '#E00019' }} />}
                  sx={{
                    backgroundColor: '#ffffff',
                    border: '1px solid #E00019',
                    borderLeft: '4px solid #E00019'
                  }}
                  severity="error"
                  onClose={() => setDisplayError(false)}
                >
                  <Label id="404Error" />
                </Alert>
              )}
            </Box>

            <Box mb={4} id='internalServerError'>
              {displayError && verificationError?.response?.status >= 500 && (
                <Alert
                  icon={<ErrorIcon sx={{ color: '#E00019' }} />}
                  sx={{
                    backgroundColor: '#ffffff',
                    border: '1px solid #E00019',
                    borderLeft: '4px solid #E00019'
                  }}
                  severity="error"
                  onClose={() => setDisplayError(false)}
                >
                  <Label id="internalServerError" />
                </Alert>
              )}
            </Box>

            <Box mt={4}>
              <Box
                sx={{ display: { xs: !isMemberVerified ? 'block' : 'none' } }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl variant="standard" fullWidth>
                      <CustomInputLabel shrink htmlFor="isCovered" id='doesSelfIsCovered'>
                        <Label id='prfolieSetupSelfLabel' />
                      </CustomInputLabel>
                      <RadioGroup
                        row
                        aria-labelledby={<Label id='prfolieSetupSelfLabel' />}
                        name="isCovered"
                        value={formState.isCovered}
                        onChange={handleInputChange}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Yes"
                          id='Yes'
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="No"
                          id='No'
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  display: {
                    xs:
                      !isMemberVerified && formState.isCovered
                        ? 'block'
                        : 'none'
                  }
                }}
              >
                <Box mt={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl variant="standard" fullWidth>
                        <CustomInputLabel shrink htmlFor="first-name" id='firstName'>
                          <Label id='firstName' />{' *'}
                        </CustomInputLabel>
                        <OutlinedInput
                          notched={false}
                          placeholder="First name"
                          label="First name"
                          fullWidth
                          id="first-name"
                          name="firstName"
                          value={formState.firstName}
                          aria-describedby="First name"
                          sx={{ height: 48 }}
                          onBlur={handleInputChange}
                          onChange={handleInputChange}
                          error={formState.firstNameError}
                        />
                      </FormControl>
                      {formState.firstNameError && (
                        <FormHelperText
                          className="Mui-error"
                          id="date-of-birth-helper-text"
                        >
                          <Label id='formFieldRequired' />
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl variant="standard" fullWidth>
                        <CustomInputLabel shrink htmlFor="last-name" id='lastName'>
                          <Label id='lastName' />{' *'}
                        </CustomInputLabel>
                        <OutlinedInput
                          notched={false}
                          placeholder="Last name"
                          label="Last name"
                          fullWidth
                          id="last-name"
                          name="lastName"
                          value={formState.lastName}
                          aria-describedby="Last name"
                          sx={{ height: 48 }}
                          onBlur={handleInputChange}
                          onChange={handleInputChange}
                          error={formState.lastNameError}
                        />
                      </FormControl>
                      {formState.lastNameError && (
                        <FormHelperText
                          className="Mui-error"
                          id="date-of-birth-helper-text"
                        >
                          <Label id='formFieldRequired' />
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Box>
                <Box mt={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl variant="standard" fullWidth>
                        <CustomInputLabel shrink htmlFor="date-of-birth" id='dateOfBirth'>
                          <Label id='dateOfBirth' />{' *'}
                        </CustomInputLabel>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            disableFuture
                            onChange={newValue => {
                              setDob(newValue);
                            }}
                            id="date-of-birth"
                            name="dateOfBirth"
                            value={dob}
                            disableOpenPicker
                            renderInput={params => (
                              <OutlinedInput
                                {...params}
                                id="date-of-birth"
                                name="dateOfBirth"
                                error={formState.dateOfBirthError}
                                onBlur={handleInputChange}
                                sx={{ height: 48 }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                        {formState.dateOfBirthError ? (
                          <FormHelperText
                            className="Mui-error"
                            id="date-of-birth-helper-text"
                          >
                            Example: 01/01/2010.
                          </FormHelperText>
                        ) : (
                          <FormHelperText id="date-of-birth-helper-text">
                            Example: 01/01/2010
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                      <FormControl variant="standard" fullWidth>
                        <CustomInputLabel shrink htmlFor="med-id" id='medicaidId'>
                          <Label id='medicaidId' />{' *'}
                        </CustomInputLabel>
                        <OutlinedInput
                          fullWidth
                          notched={false}
                          placeholder="Medicaid ID"
                          label="Medicaid ID"
                          id="med-id"
                          name="medicaidId"
                          value={formState.medicaidId}
                          endAdornment={
                            <InputAdornment position="end">
                              <InfoIcon fontSize="small" />
                            </InputAdornment>
                          }
                          aria-describedby="Medicaid ID"
                          sx={{ height: 48 }}
                          onBlur={handleInputChange}
                          onChange={handleInputChange}
                          error={formState.medicaidIdError}
                        />
                        {formState.medicaidIdError ? (
                          <FormHelperText
                            className="Mui-error"
                            id="date-of-birth-helper-text"
                          >
                            Example: 547825698
                          </FormHelperText>
                        ) : (
                          <FormHelperText id="medicaid id-helper-text">
                            Example: 547825698
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box
                mt={4}
                display="flex"
                justifyContent={formState.isCovered ? 'flex-end' : 'flex-end'}
                alignItems="center"
                sx={{ display: { xs: !isMemberVerified ? 'flex' : 'none' } }}
              >
                <Button
                  disabled={!!isLoading || !isFormValid()}
                  sx={{
                    display: { xs: formState.isCovered ? 'block' : 'none' },
                    height: '48px',
                    marginBottom: 4
                  }}
                  variant="contained"
                  onClick={handleSubmit}
                  id='submitButton'
                >
                  {isLoading && (
                    <CircularProgress
                      size="16px"
                      sx={{ color: '#ffffff', marginRight: '10px' }}
                    />
                  )}{' '}
                  <Label id='submit' />
                </Button>
              </Box>

              <Box
                mb={5}
                sx={{ display: { xs: isMemberVerified ? 'block' : 'none' } }}
              >
                <Box mb={4}>
                  <AddedMemberListItem
                    data={formState}
                    handleDelete={handleDeleteDependent}
                    memberAccountsData={memberAccountsData}
                  />
                </Box>
              </Box>

              <Box>
                <Divider />
                <Box
                  mt={4}
                  sx={{
                    display: { xs: !dependentsList.length ? 'block' : 'none' }
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl variant="standard" fullWidth>
                        <CustomInputLabel shrink htmlFor="hasDependent" id='hasDependantCovered'>
                          <Label id='profileSetupDependentLabel' />
                        </CustomInputLabel>
                        <RadioGroup
                          row
                          aria-labelledby={<Label id='profileSetupDependentLabel' />}
                          name="hasDependent"
                          value={hasDependent}
                          onChange={handleDependentCheck}
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Yes"
                            id='yes'
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="No"
                            id='No'
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>

                {hasDependent === true && dependentsList?.length > 0 && (
                  <Box mt={4}>
                    <Typography
                      variant="h4"
                      sx={{ fontSize: 24, marginBottom: '25px' }}
                      id='dependantInformationTitle'
                    >
                      <Label id='dependantInformation' />
                    </Typography>
                    {dependentsList.map((item, index) => (
                      <Box mb={4} key={index}>
                        <AddedMemberListItem
                          data={item}
                          index={index}
                          handleDelete={handleDeleteDependent}
                        />
                      </Box>
                    ))}
                  </Box>
                )}

                {hasDependent === true && (
                  <Box mt={4}>
                    <a
                      className={classes.customAddButton}
                      onClick={handleClickOpen}
                      id='addADependant'
                    >
                      <Label id='addADependant' /> &nbsp;
                      <AddIcon />
                    </a>
                  </Box>
                )}

                {showNavActions && (
                  <Box
                    mt={4}
                    display="flex"
                    justifyContent={
                      !dependentsList?.length && hasDependent
                        ? 'space-between'
                        : 'flex-end'
                    }
                    alignItems="center"
                  >
                    {!dependentsList?.length && hasDependent && (
                      <Button
                        sx={{ height: '48px' }}
                        onClick={handleSkipButton}
                        id='skipAddDependant'
                      >
                        <Label id='skipAddDependant' />
                      </Button>
                    )}

                    {isMemberVerified || hasDependent ? (
                      <Button
                        disabled={
                          !hasDependent ? false : !dependentsList?.length
                        }
                        variant="contained"
                        sx={{ height: '48px' }}
                        onClick={handleSkipButton}
                        id='continue'
                      >
                        <Label id='continue' />
                      </Button>
                    ) : (
                      <Button
                        sx={{ height: '48px' }}
                        onClick={handleSkipButton}
                        id='skipProfileSetup'
                      >
                        <Label id='skipProfileSetup' />
                      </Button>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          </CustomPaper>
          {displaySuccess?.status && (
            <Box mt={6} id='addedSuccssLabel'>
              <Alert
                icon={<CheckCircleIcon sx={{ color: '#126E36' }} />}
                sx={{
                  backgroundColor: '#ffffff',
                  border: '1px solid #126E36',
                  borderLeft: '4px solid #126E36'
                }}
                severity="success"
                onClose={() => setDisplaySuccess(false)}
              >
                {displaySuccess?.displayName} <Label id='addedSuccssLabel' />
              </Alert>
            </Box>
          )}
        </Box>
        <AddDependentModal
          dependentsList={dependentsList}
          setDependentsList={setDependentsList}
          open={open}
          setOpen={setOpen}
          setDisplaySuccess={setDisplaySuccess}
          handleSubmit={handleAddDependent}
        />
      </Box>
    </>
  );
};

ManageProfile.propTypes = {
  titleId: PropTypes.string,
  showNavActions: PropTypes.bool
};

export default ManageProfile;