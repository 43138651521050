import PropTypes from 'prop-types';
import {
  getLanguageCookies,  getSanitizedString,
  findKeyHasId
} from '../../../utils/content-utils';
import { useSelector } from 'react-redux';
// import { languageCodes } from '../../../models/languageType';

const Label = ({ id }) => {
  const tenantLocaleContent = useSelector(state => state.tenant.tenantLocaleContent);
  const language = getLanguageCookies();
  const data = tenantLocaleContent['localeString'];
  // const languageCode = language && languageCodes[language.toUpperCase()];
  const phrases = data && Object.keys(data);
  const phrase = findKeyHasId(phrases, id)[0];
  const getLabel = id => {
    if (phrase != undefined && phrase.length > 0) {
      return language.includes('en')
        ? getSanitizedString(phrase)
        : data[phrase] && data[phrase][language];
    } else {
      console.error('Cannt find the label- ' + id);
    }
  };

  return getLabel(id);
};
Label.propTypes = {
  id: PropTypes.string
};
export default Label;
