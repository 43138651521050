import { SET_LOCALECONTENT_FAILURE,SET_LOCALECONTENT_LOADING,SET_LOCALECONTENT_SUCCESS,SET_PRODUCTS_FAILURE, SET_PRODUCTS_LOADING, SET_PRODUCTS_SUCCESS, SET_RESOURCE_SUCCESS, SET_RESOURCE_FAILURE, SET_RESOURCE_LOADING, SET_RESOURCE_ES_FAILURE, SET_RESOURCE_ES_LOADING, SET_RESOURCE_ES_SUCCESS, SET_TEANANTID_FAILURE, SET_TEANANTID_LOADING, SET_TEANANTID_SUCCESS, SET_LAYOUT_FAILURE,SET_LAYOUT_LOADING,SET_LAYOUT_SUCCESS,SET_HTMLCONTENT_SUCCESS,SET_HTMLCONTENT_FAILURE,SET_HTMLCONTENT_LOADING } from './constants';
import { trackPromise } from 'react-promise-tracker';
import { getTenanatLocaleContentService, getTenanatInfoService, getTenanatLayoutService, getTenanatModuleService, getTenanatResourceService, getTenantResourceESService, getTenanatHtmlContentService } from '../../services/tenantInfoService';

export const setTenantId = payload => ({
  type: SET_TEANANTID_SUCCESS,
  payload
});

export const setTenantIdError = payload => ({
  type: SET_TEANANTID_FAILURE,
  payload
});

export const setTenantIdLoading = payload => ({
  type: SET_TEANANTID_LOADING,
  payload
});

export const setResources = payload => ({
  type: SET_RESOURCE_SUCCESS,
  payload
});

export const setResourcesError = payload => ({
  type: SET_RESOURCE_FAILURE,
  payload
});

export const setResourcesLoading = payload => ({
  type: SET_RESOURCE_LOADING,
  payload
});

export const setResourcesES = payload => ({
  type: SET_RESOURCE_ES_SUCCESS,
  payload
});

export const setResourcesErrorES = payload => ({
  type: SET_RESOURCE_ES_FAILURE,
  payload
});

export const setResourcesLoadingES = payload => ({
  type: SET_RESOURCE_ES_LOADING,
  payload
});

export const setProducts = payload => ({
  type: SET_PRODUCTS_SUCCESS,
  payload
});

export const setProductsError = payload => ({
  type: SET_PRODUCTS_FAILURE,
  payload
});

export const setProductsLoading = payload => ({
  type: SET_PRODUCTS_LOADING,
  payload
});

export const setTenantLayout = payload => ({
  type: SET_LAYOUT_SUCCESS,
  payload
});

export const setTenantLayoutError = error => ({
  type: SET_LAYOUT_FAILURE,
  payload: { error }
});

export const setTenantLayoutLoading = payload => ({
  type: SET_LAYOUT_LOADING,
  payload
  });

export const setTenantLocaleContent = payload => ({
  type: SET_LOCALECONTENT_SUCCESS,
  payload
});

export const setTenantLocaleContentError = payload => ({
  type: SET_LOCALECONTENT_FAILURE,
  payload
});

export const setTenantLocaleContentLoading = payload => ({
  type: SET_LOCALECONTENT_LOADING,
  payload
});

export const setTenantHtmlContent = payload => ({
  type: SET_HTMLCONTENT_SUCCESS,
  payload
});

export const setTenantHtmlContentError = payload => ({
  type: SET_HTMLCONTENT_FAILURE,
  payload
});

export const setTenantHtmlContentLoading = payload => ({
  type: SET_HTMLCONTENT_LOADING,
  payload
});

export const fetchTenantId = (tenantId) => dispatch => {
  dispatch(setTenantIdLoading(true));
  trackPromise(getTenanatInfoService(tenantId)
      .then(res => dispatch(setTenantId(res.data)))
      .catch(err => dispatch(setTenantIdError(err.message))));
};

export const fetchResource = (tenantId) => dispatch => {
  dispatch(setResourcesLoading(true));
  trackPromise(getTenanatResourceService(tenantId)
      .then(res => dispatch(setResources(res.data)))
      .catch(err => dispatch(setResourcesError(err.message))));
};

export const fetchResourceES = (tenantID) => dispatch => {
  dispatch(setResourcesLoadingES(true));
  trackPromise(getTenantResourceESService(tenantID)
  .then(res =>dispatch(setResourcesES(res.data)))
  .catch(err => dispatch(setResourcesErrorES(err.message))));
};

export const fetchProducts = (tenantId) => dispatch => {
  dispatch(setProductsLoading(true));
  trackPromise(
      getTenanatModuleService(tenantId)
      .then(res => dispatch(setProducts(res.data)))
      .catch(err => dispatch(setProductsError(err.message))));
};

export const fetchLayouts = (tenantId) => dispatch => {
  dispatch(setTenantLayoutLoading(true));
  trackPromise(
      getTenanatLayoutService(tenantId)
      .then(res => dispatch(setTenantLayout(res.data.layouts)))
      .catch(err => dispatch(setTenantLayoutError(err))));
};

export const fetchLocaleContent = (tenantId) => dispatch => {
  dispatch(setTenantLocaleContentLoading(true));
  trackPromise(
      getTenanatLocaleContentService(tenantId)
      .then(res => dispatch(setTenantLocaleContent(res.data)))
      .catch(err => dispatch(setTenantLocaleContentError(err))));
};

export const fetchHtmlContent = (tenantId) => dispatch => {
   dispatch(setTenantHtmlContentLoading(true));
  trackPromise(
    getTenanatHtmlContentService(tenantId)
      .then(res => dispatch(setTenantHtmlContent(res.data)))
      .catch(err => dispatch(setTenantHtmlContentError(err))));
};
