export const setLanguageCookies = (language) => {
    if (!navigator.language.includes(language)) {
        document.cookie = `language=${language}; SameSite=None; Secure`;
    } else {
        if (document.cookie.split(';').some((item) => item.trim().startsWith('language='))) {
            document.cookie = 'language=; Max-Age=0';
        }
    }
};

export const getLanguageCookies = () => {
    const languageCookie = document.cookie.split(';').some((item) => item.trim().startsWith('language='));
    if (languageCookie) {
        return document.cookie.split('; ').find((row) => row.startsWith('language='))?.split('=')[1];
    } else {
        return navigator.language.substring(0,2);
    }
};
export const getSanitizedString = (text) => {
    return text.substring(0, text.search(/n*#/));
};
export const findKeyHasId = (phrases, id) => {
    return phrases.filter(
        phrase =>
            phrase.substring(phrase.search(/n*##/) + 2, phrase.length) === id
    );
};

export const getUserAttempts = (user) => {
    return document.cookie =  document.cookie.split('; ').find((row) => row.startsWith(`${user}=`))?.split('=')[1];
};
export const setUserAttempts = (user,count) => {
    return document.cookie = `${user}=${count}; SameSite=None; Secure`;
};

export const clearUserAttempts = (user) => {
    return document.cookie = `${user}=; Max-Age=0`;
};