import React from 'react';
import { useSelector } from 'react-redux';


export const usePageTitle = (title) => {
    
    const tenantInfo = useSelector(state => state.tenant.tenantInfo);
    React.useEffect(() => {
        title && (document.title = `${title} | ` + tenantInfo['title']);
    }, [title]);
};
