import * as React from 'react';
import PropTypes from 'prop-types';
import * as MuiIcons from '@mui/icons-material';
import { useSelector } from 'react-redux';
import {checkCustomIcon,getSanitizedIconName} from '../functionUtils';

const CustomIcon = ({ iconName, className }) => {
  const tenantResource = useSelector(state => state.tenant.tenanantResource);
  const [Icon, setIcon] = React.useState('');

  React.useEffect(() => {
    checkCustomIcon(iconName) ? setIcon(getSanitizedIconName(iconName)) : setIcon(MuiIcons[iconName]);
  }, [Icon]);

  return (
    <>
      {checkCustomIcon(iconName)
        ? Icon && (
            <img
              src={tenantResource && tenantResource[Icon]}
              alt={Icon}
            />
          )
        : Icon && <Icon className={className} />}
    </>
  );
};

CustomIcon.propTypes = {
  iconName: PropTypes.string,
  className: PropTypes.string
  

};

export default CustomIcon;
