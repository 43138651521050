import React from 'react';
import PropTypes from 'prop-types';
import { Box, ListItem, Paper } from '@mui/material';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CustomIcon from './CustomIcon';
import Label from '../type/Label';
import { useLocation } from 'react-router';
import { analyticsTrackEvent, getMappedClickEventPathName } from '../../../utils/analytics/analytics-utils';

const SideNavbar = props => {
  const location = useLocation();
  const getId = str => {
    return str.split('-')[1];
  };
  const [selectedIndex, setSelectedIndex] = React.useState(
    location.hash ? Number(getId(location.hash)) : 0
  );
  const handleListItemClick = (event, index, item) => {
    setSelectedIndex(index);
    analyticsTrackEvent(`${getMappedClickEventPathName(item?.content)}_clicked`, {eventCategory: 'Plan Benefits Pathing'});
  };

  return (
    <>
      <Box className={props.sideNavItemClassName}>
        <Paper elevation={8}>
          <List>
            {props.navItems.map((item, index) => (
              <ListItem
                component="a"
                button
                key={index}
                selected={selectedIndex === index}
                onClick={event => handleListItemClick(event, index, item)}
                className={item.buttonClassName}
                href={`#box-${index}`}
                id={item.id}
              >
                {item.Icon && (
                  <ListItemIcon>
                    <CustomIcon
                      iconName={item.Icon}
                      className={item.iconClassName}
                    />
                  </ListItemIcon>
                )}
                <ListItemText primary={<Label id={item.id} />} />
                {item.endIcon && (
                  <CustomIcon
                    iconName={item.endIcon}
                    className={item.iconClassName}
                  />
                )}
              </ListItem>
            ))}
          </List>
        </Paper>
      </Box>
    </>
  );
};

SideNavbar.propTypes = {
  navigate: PropTypes.func,
  navItems: PropTypes.array,
  Icon: PropTypes.string,
  endIcon: PropTypes.string,
  className: PropTypes.string,
  sideNavItemClassName: PropTypes.string
};

export default SideNavbar;
