import React from 'react';
import PropTypes from 'prop-types';
import { Box} from '@mui/material';
import { makeStyles } from '@mui/styles';
import IconComponent from '../../common/CustomComponents/IconComponent';
import TextComponent from '../../common/CustomComponents/TextComponent';
import CustomIcon from '../../common/CustomComponents/CustomIcon';

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: 24,
    flexGrow: 1,
    boxShadow:
      ' 0px 4px 5px rgba(0, 0, 0, 0.07), 0px 1px 10px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: 4,
    marginBottom: 24
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 28,
    '& h4': {
      margin: '0 0 0 24px',
      fontWeight: 350,
      fontSize: 24,
      fontStyle: 'normal'
    }
  }
}));

const PrivacySection = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.wrapper}>
        <Box className={classes.titleWrapper}>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <CustomIcon iconName='Shield' className='settingsPrivacySectionIcon' />
            <TextComponent id='settingsPrivacyLabel' className='settingsPrivacySectionLabel' />
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          
          <TextComponent id='settingsPrivacyPolicyLabel' className='settingsPrivacyPolicyLabel' />
         
          <IconComponent icon='OpenInNew' pathName='privacyPolicy' className='settingsPrivacyPolicyIcon' />
        </Box>
      </Box>
    </>
  );
};

PrivacySection.propTypes = {
  navigate: PropTypes.func
};

export default PrivacySection;
