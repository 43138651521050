
import React from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import PlanDetailsProgressBar from './PlanDetailsProgressBar';

const PlanDetailsSpentIndicator = ({ spentIndicatorDetails }) => {
    const { familyDeductibleSpent, familyDeductibleOutOf, oPMaxSpent, oPMaxOutOf } = spentIndicatorDetails;
    return (
        <Box>
            <Box>
                <Typography className="pDIndicatorTitle" variant="h6">Family deductible</Typography>
                <Box className="pDIndicatorBox">
                    <PlanDetailsProgressBar progressValue={50} />
                </Box>
                <Box className="pDIndicatorDetailsWrapper">
                    <Typography className="pDIndicatorDetails" variant="body2">${familyDeductibleSpent} spent</Typography>
                    <Typography className="pDIndicatorDetails" variant="body2">Out of ${familyDeductibleOutOf}</Typography>
                </Box>
            </Box>

            <Box sx={{ marginTop: '16px' }}>
                <Typography className="pDIndicatorTitle" variant="h6">Family out-of-pocket max</Typography>
                <Box className="pDIndicatorBox">
                    <PlanDetailsProgressBar progressValue={75} />
                </Box>
                <Box className="pDIndicatorDetailsWrapper">
                    <Typography className="pDIndicatorDetails" variant="body2">${oPMaxSpent} spent</Typography>
                    <Typography className="pDIndicatorDetails" variant="body2">Out of ${oPMaxOutOf}</Typography>
                </Box>
            </Box>
        </Box>
    );
};


PlanDetailsSpentIndicator.propTypes = {
    spentIndicatorDetails: PropTypes.obj,
};


export default PlanDetailsSpentIndicator;