import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { PathNames } from '../../../constants/AppConstants';

function Image(props) {
    const tenantResource = useSelector(state => state.tenant.tenanantResource);

    const path = PathNames && props.pathName && PathNames[props.pathName];
    return (
        <a href={path} id={props.id}>
            <img src={tenantResource[props.imageId]} className={props.className} alt={props.altText} />
        </a>
    );
}
Image.propTypes = {
    id: PropTypes.string,
    pathName: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.string,
    altText: PropTypes.string,
    imageId: PropTypes.string
};
export default Image;
