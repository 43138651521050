const Pages = {
    dchp: [
        {
            'title': 'HOME',
            'path': 'home',
            'component': 'Home',
            'config': 'homePage'
        },
        {
            'title': 'HOME',
            'path': 'home',
            'component': 'Home',
            'config': 'homePage'
        },
        {
            'title': 'BENEFITS',
            'path': 'benefitsOverview',
            'config': 'benefits',
            'component': 'Benefits'
        },
        {
            'title': 'BENEFITS',
            'path': 'benefitsWhatsCovered',
            'config': 'benefits',
            'component': 'Benefits'
        },
        {
            'title': 'BENEFITS',
            'path': 'benefitsExtraBenefits',
            'config': 'benefits',
            'component': 'Benefits'
        },
        {
            'title': 'BENEFITS',
            'path': 'benefitsExtraBenefits#box-0',
            'config': 'benefits',
            'component': 'Benefits'
        },
        {
            'title': 'BENEFITS',
            'path': 'benefitsExtraBenefits#box-1',
            'config': 'benefits',
            'component': 'Benefits'
        },
        {
            'title': 'BENEFITS',
            'path': 'benefitsExtraBenefits#box-3',
            'config': 'benefits',
            'component': 'Benefits'
        },
        {
            'title': 'BENEFITS',
            'path': 'benefitsExtraBenefits#box-4',
            'config': 'benefits',
            'component': 'Benefits'
        },
        {
            'title': 'BENEFITS',
            'path': 'benefitsExtraBenefits#box-7',
            'config': 'benefits',
            'component': 'Benefits'
        },
        {
            'title': 'BENEFITS',
            'path': 'benefitsExtraBenefits#box-10',
            'config': 'benefits',
            'component': 'Benefits'
        },
        {
            'title': 'BENEFITS',
            'path': 'benefitsCoveredMembers',
            'config': 'benefits',
            'component': 'Benefits'
        },

        {
            'title': 'ID CARDS',
            'path': 'idCards',
            'Icon': 'IdCardIcon',
            'component': 'IdCards'
        },
        {
            'title': 'Member Support',
            'path': 'memberSupport',
            'component': 'MemberSupport'
        },
        {
            'title': 'Settings',
            'path': 'settings',
            'component': 'Settings',
            'config': 'settingsPage'
        },
        {
            'title': 'Privacy Policy',
            'path': 'privacyPolicy',
            'component': 'PrivacyPolicy'
        },
        {
            'title': 'Terms of Use',
            'path': 'termsOfUse',
            'component': 'TermsOfUse'
        },
        {
            'title': 'Manage Profile',
            'path': 'manageProfile',
            'component': 'ProfileSetupPage'
        }
    ],
    smarthealth: [
        {
            'title': 'HOME',
            'path': 'home',
            'component': 'Settings',
            'config': 'homePage'
        },
        {
            'title': 'HOME',
            'path': 'home',
            'component': 'Home',
            'config': 'homePage'
        },
        {
            'title': 'MEDICAL',
            'path': 'medical',
            'config': 'medical',
            'component': 'Medical'
        },  {
            'title': 'PHARMACY',
            'path': 'pharmacy',
            'config': 'pharmacy',
            'component': 'Pharmacy'
        },
        {
            'title': 'SUPPORT',
            'path': 'support',
            'config': 'support',
            'component': 'Support'
        },
        {
            'title': 'BENEFITS',
            'path': 'benefitsWhatsCovered',
            'config': 'benefits',
            'component': 'Benefits'
        },
        {
            'title': 'BENEFITS',
            'path': 'benefitsExtraBenefits',
            'config': 'benefits',
            'component': 'Benefits'
        },
        {
            'title': 'BENEFITS',
            'path': 'benefitsExtraBenefits#box-0',
            'config': 'benefits',
            'component': 'Benefits'
        },
        {
            'title': 'BENEFITS',
            'path': 'benefitsExtraBenefits#box-1',
            'config': 'benefits',
            'component': 'Benefits'
        },
        {
            'title': 'BENEFITS',
            'path': 'benefitsExtraBenefits#box-3',
            'config': 'benefits',
            'component': 'Benefits'
        },
        {
            'title': 'BENEFITS',
            'path': 'benefitsExtraBenefits#box-4',
            'config': 'benefits',
            'component': 'Benefits'
        },
        {
            'title': 'BENEFITS',
            'path': 'benefitsExtraBenefits#box-7',
            'config': 'benefits',
            'component': 'Benefits'
        },
        {
            'title': 'BENEFITS',
            'path': 'benefitsExtraBenefits#box-10',
            'config': 'benefits',
            'component': 'Benefits'
        },
        {
            'title': 'BENEFITS',
            'path': 'benefitsCoveredMembers',
            'config': 'benefits',
            'component': 'Benefits'
        },
        
        {
            'title': 'ID CARDS',
            'path': 'idCards',
            'Icon': 'IdCardIcon',
            'component': 'IdCards'
        },
        {
            'title': 'Member Support',
            'path': 'memberSupport',
            'component': 'MemberSupport'
        },
        {
            'title': 'Settings',
            'path': 'settings',
            'component': 'Settings',
            'config': 'settingsPage'
        },
        {
            'title': 'Privacy Policy',
            'path': 'privacyPolicy',
            'component': 'PrivacyPolicy',
            'config': 'PrivacyPolicy'
        },
        {
            'title': 'Terms of Use',
            'path': 'termsOfUse',
            'component': 'TermsOfUse'
        },
        {
            'title': 'Manage Profile',
            'path': 'manageProfile',
            'component': 'ProfileSetupPage'
        }
    ]

};

export default Pages;
