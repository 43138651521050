import constants from './constants';

const { SET_MEMBER_VERIFICATION_BEGIN, SET_MEMBER_VERIFICATION_SUCCESS, SET_MEMBER_VERIFICATION_ERROR, SET_MEMBER_VERIFICATION_CLEANUP } = constants;
export const initialState = {
    memberVerificationDetails: null,
    loading: false,
    error: null
};

export const memberVerificationReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_MEMBER_VERIFICATION_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case SET_MEMBER_VERIFICATION_SUCCESS:
            return {
                ...state,
                memberVerificationDetails: payload.data,
                loading: false,
            };

        case SET_MEMBER_VERIFICATION_ERROR:
            return {
                ...state,
                memberVerificationDetails: {},
                loading: false,
                error: payload.error
            };

        case SET_MEMBER_VERIFICATION_CLEANUP:
            return initialState;

        default:
            return state;
    }
};