export default {
    SET_MEMBER_ACCOUNTS_BEGIN: 'SET_MEMBER_ACCOUNTS_BEGIN',
    SET_MEMBER_ACCOUNTS_SUCCESS: 'SET_MEMBER_ACCOUNTS_SUCCESS',
    SET_MEMBER_ACCOUNTS_ERROR: 'SET_MEMBER_ACCOUNTS_ERROR',
    SET_MEMBER_ACCOUNTS_CLEANUP: 'SET_MEMBER_ACCOUNTS_CLEANUP',
    SET_MEMBER_ID_BEGIN: 'SET_MEMBER_ID_BEGIN',
    SET_MEMBER_ID_SUCCESS: 'SET_MEMBER_ID_SUCCESS',
    SET_MEMBER_ID_ERROR: 'SET_MEMBER_ID_ERROR',
    SET_MEMBER_ID_CLEANUP: 'SET_MEMBER_ID_CLEANUP',
  };
  