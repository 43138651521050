import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { usePageTitle } from '../../common/hooks/usePageTitle';
import { useSelector, useDispatch } from 'react-redux';
import CustomComponent from '../../common/CustomComponent';
import PropTypes from 'prop-types';
import { getMemberAccounts } from '../../../redux/memberAccounts/action';

//Home Page is Multi Tenant
const Home = ({ config }) => {
  // const tenantLayouts = useSelector(state => state.tenant.tenantLayout);
  const homeLayout = config;
  const tenantModules = useSelector(state => state.tenant.tenantProducts);
  const maErrorFromStore = useSelector(state => state?.memberAccounts?.error);
  const [memberAccountError, setMemberAccountError] = useState('');
  const serviceDispatch = useDispatch();

  React.useEffect(() => {
    serviceDispatch(getMemberAccounts(localStorage.getItem('tenantHandle')));
  }, []);

  React.useEffect(() => {
    if (maErrorFromStore?.response?.status === 404) {
      setMemberAccountError('maNotFound');
    }
    if (maErrorFromStore?.response?.status !== 404) {
      setMemberAccountError('maServiceError');
    }
  }, [maErrorFromStore]);

  usePageTitle(homeLayout && homeLayout.pageTitle);

  const renderItem = item => {
    return (
      <>
        {item.props.map((component, index) => (
          <CustomComponent
            config={component.id}
            {...component}
            key={index}
          />
        ))}
      </>
    );
  };
  const gridItem = (item, index) => {
    return (
      <Grid
        item
        md={item.tabletGridWidth}
        xs={item.mobileGridWidth}
        lg={item.desktopGridWidth}
        key={index}
        id={item.id}
        className={item.className}
      >
        {renderItem(item)}
      </Grid>
    );
  };

  const gridContainer = sections => {
    return (
      sections &&
      sections.map((section, index) => (
        <Grid container key={index} className={section && section.className}>
          {section.items &&
            section.items.map((item, index) =>
              item.showOnlyWhenProfileNotSetup
                ? memberAccountError === item.showWhenErrorCode &&
                  gridItem(item, index)
                : gridItem(item, index)
            )}
        </Grid>
      ))
    );
  };
  return (
    <>
      {tenantModules.modules && homeLayout && (
        <div className={homeLayout.className} id={homeLayout.id}>
          {tenantModules.modules.includes('profileSetupSections') &&
          maErrorFromStore
            ? gridContainer(homeLayout && homeLayout.profileSetupSections)
            : gridContainer(homeLayout && homeLayout.sections)}
        </div>
      )}
    </>
  );
};

Home.propTypes = {
  config: PropTypes.object
};

export default Home;
