import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@mui/material';
import BannerLogoOnly from '../Banner/BannerLogoOnly';
import ManageProfile from '../profileSetup/ManageProfile';
import { useSelector } from 'react-redux';


const ProfileSetup = ({loading}) => {
  const memberAccountsFromStore = useSelector(state => state?.memberAccounts?.memberAccountsDetails);
  const maLoadingFromStore = useSelector(state => state?.memberAccounts?.loading);

  if (loading || maLoadingFromStore) {
    return (<>
      <Box>
        <BannerLogoOnly />
        <Box style={{ width: '100%', height: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></Box>
      </Box>
    </>);
  }

  else {
    return (<>
      <Box>
        <BannerLogoOnly />
        {memberAccountsFromStore && <ManageProfile showNavActions={true} titleId={'profileSetupPageTitle'} data={memberAccountsFromStore} />}
      </Box>
    </>);
  }
};

ProfileSetup.propTypes = {
  loading: PropTypes.bool
};


export default ProfileSetup;