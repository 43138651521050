import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';


const AddedMemberListItem = ({ data, index = 0, handleDelete, memberAccountsData }) => {
    const capitalize = (value) => {
        if (typeof value !== 'string') return;
        let updatedValue = value.toLowerCase();
        return updatedValue.toLowerCase().charAt(0).toUpperCase() + updatedValue.slice(1);
    };

    return (
        <Box key={index} display='flex' justifyContent='space-between' alignItems='center'>
            <Box display='flex'>
                <DoneIcon sx={{ color: '#126E36', margin: '0 14px' }} />
                <Typography sx={{ color: '#000000', fontWeight: 500 }}>{capitalize(memberAccountsData?.self?.firstName || data.firstName)}</Typography>
            </Box>
            <Button
                sx={{ display: 'none' }}
                startIcon={<DeleteOutlineIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />}
                onClick={() => handleDelete(index)}
            >
                Delete
            </Button>
        </Box>
    );
};

AddedMemberListItem.propTypes = {
    data: PropTypes.object,
    memberAccountsData: PropTypes.object,
    index: PropTypes.number,
    handleDelete: PropTypes.func
};

export default AddedMemberListItem;