import * as React from 'react';
import { useSelector } from 'react-redux';
import { useAnalyticsPageViewsEvent } from '../../utils/analytics/hooks/useAnalyticsPageViewsEvent';
import RowContainer from '../common/CustomComponents/RowContainer';



const Banner = () => {
  const tenantResource = useSelector(state => state.tenant.tenanantResource);
  const tenantLayouts = useSelector(state => state.tenant.tenantLayout);

  React.useEffect(() => {
    //need to check dynamic css import
    if (tenantResource.styles) {
      var styles = document.getElementById(localStorage.getItem('tenantHandle'));
      var head = document.head;
      if (styles) {
        head.removeChild(styles);
      }
      var link = document.createElement('link');
      link.type = 'text/css';
      link.id = localStorage.getItem('tenantHandle');
      link.rel = 'stylesheet';
      link.href = tenantResource.styles;
      head.appendChild(link);
    }
  }, [tenantResource]);

  /** ANALYTICS INTEGRATION :: Amplitude analytics implementation - Logging page views with custom-hook having 'useLocation' under the hood **/
  useAnalyticsPageViewsEvent();

  return (
    tenantLayouts.headerBanner && tenantLayouts.headerBanner.sections && tenantLayouts.headerBanner.sections.map((item, index) =>
      <RowContainer key={index} {...item} />
    )
  );
};
export default Banner;