import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { getLanguageCookies, setLanguageCookies } from '../../../utils/content-utils';
import Label from '../../common/type/Label';
import { useSelector } from 'react-redux';
import TextComponent from '../../common/CustomComponents/TextComponent';
import CustomIcon from '../../common/CustomComponents/CustomIcon';

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: 24,
    flexGrow: 1,
    boxShadow:
      ' 0px 4px 5px rgba(0, 0, 0, 0.07), 0px 1px 10px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: 4
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h4': {
      margin: '0 0 0 24px',
      fontWeight: 350,
      fontSize: 24,
      fontStyle: 'normal'
    }
  }
}));

const LanguageSection = (props) => {
  const classes = useStyles();
  const tenantLayouts = useSelector(state => state.tenant.tenantLayout);
  const config = tenantLayouts[props.config];

  const [selectedValue, setSelectedValue] = React.useState();

  React.useEffect(() => {
    const language = getLanguageCookies();
    setSelectedValue(language);
  }, []);

  const handleChange = event => {
    setLanguageCookies(event.target.value);
    setSelectedValue(event.target.value);
    window.location.reload();
  };

  return (
    <>
      <Box className={classes.wrapper}>
        <Box className={classes.titleWrapper}>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <CustomIcon iconName='Language' className='settingsLanguageSectionIcon' />
            <TextComponent id='settingsLanguagePreferenceLabel' className='settingsLanguageSectionLabel' />
          </Box>
          <Box>{ selectedValue && 
            <Select
              value={selectedValue}
              defaultValue={selectedValue}
              onChange={handleChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              style={{ width: 150, height: 48 }}
            >
              {config.selectItems &&
                config.selectItems.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.value}
                    className={item.className}
                    id={index}
                  >
                    {<Label id={item.id} />}
                  </MenuItem>
                ))}
            </Select>}
          </Box>
        </Box>
      </Box>
    </>
  );
};

LanguageSection.propTypes = {
  config: PropTypes.string
};

export default LanguageSection;
