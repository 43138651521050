
  export const SET_TEANANTID_LOADING   = 'SET_TEANANTID_LOADING';
  export const SET_TEANANTID_SUCCESS   = 'SET_TEANANTID_SUCCESS';
  export const SET_TEANANTID_FAILURE   = 'SET_TEANANTID_FAILURE';
  export const SET_RESOURCE_LOADING   = 'SET_RESOURCE_LOADING';
  export const SET_RESOURCE_SUCCESS   = 'SET_RESOURCE_SUCCESS';
  export const SET_RESOURCE_FAILURE   = 'SET_RESOURCE_FAILURE';
  export const SET_RESOURCE_ES_LOADING   = 'SET_RESOURCE_ES_LOADING';
  export const SET_RESOURCE_ES_SUCCESS   = 'SET_RESOURCE_ES_SUCCESS';
  export const SET_RESOURCE_ES_FAILURE   = 'SET_RESOURCE_ES_FAILURE';
  export const SET_PRODUCTS_LOADING  = 'SET_PRODUCTS_LOADING';
  export const SET_PRODUCTS_SUCCESS   = 'SET_PRODUCTS_SUCCESS';
  export const SET_PRODUCTS_FAILURE   = 'SET_PRODUCTS_FAILURE';
  export const SET_LAYOUT_LOADING  = 'SET_LAYOUT_LOADING';
  export const SET_LAYOUT_SUCCESS   = 'SET_LAYOUT_SUCCESS';
  export const SET_LAYOUT_FAILURE   = 'SET_LAYOUT_FAILURE';
  
  export const SET_LOCALECONTENT_LOADING  = 'SET_LOCALECONTENT_LOADING';
  export const SET_LOCALECONTENT_SUCCESS   = 'SET_LOCALECONTENT_SUCCESS';
  export const SET_LOCALECONTENT_FAILURE   = 'SET_LOCALECONTENT_FAILURE';
  export const SET_HTMLCONTENT_LOADING  = 'SET_HTMLCONTENT_LOADING';
  export const SET_HTMLCONTENT_SUCCESS   = 'SET_HTMLCONTENT_SUCCESS';
  export const SET_HTMLCONTENT_FAILURE   = 'SET_HTMLCONTENT_FAILURE';
