
const genericServiceReducer = (state, action) => {
    switch (action.type) {
        case 'error': {
            return {
                ...state,
                status: 'rejected',
                error: action.error
            };
        }
        case 'success': {
            return {
                ...state,
                status: 'resolved',
                data: action.data
            };
        }
        case 'started': {
            return {
                ...state,
                status: 'pending'
            };
        }
        default: {
            throw new Error(`Invalid action tyle: ${action.type}`);
        }
    }
};

export default genericServiceReducer;