import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router';
import { useMsal } from '@azure/msal-react';
import { getSanitizedPathName } from '../functionUtils';
import Label from '../type/Label';
import CustomIcon from './CustomIcon';
import { PathNames } from '../../../constants/AppConstants';
import { analyticsTrackEvent, getMappedClickEventPathName } from '../../../utils/analytics/analytics-utils';


const SubMenu = ({ anchor, onClose, options, activeSubmenuItem, text, className }) => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const handleMenuClick = path => {

      /** Amplitude analytics implementation - Logging click events **/
      const eventName = `home_header_${getMappedClickEventPathName(path)}_clicked`;
      analyticsTrackEvent(eventName, { path, eventName });

    if (path.includes('testLogout')) {
      localStorage.removeItem('showProfileSetup');
      navigate('/');
      analyticsTrackEvent(getMappedClickEventPathName('profileTestLogout'));
      instance.logout();
    }
    onClose();
    navigate(path);
  };

  return (
    options && (
      <Menu
        anchorEl={anchor}
        keepMounted
        open={Boolean(anchor)}
        onClose={onClose}
        PaperProps={{
          className: className,
          style: {
            minWidth: 260,
            maxWidth: 300
          }
        }}
      >
        {options.map((item, index) => {
          const path = item.pathName && item.pathName in PathNames ? PathNames[item.pathName] : '';
          return (
            <MenuItem
              key={index}
              onClick={() => handleMenuClick(path)}
              className={item.className}
              selected={getSanitizedPathName(path) === activeSubmenuItem}
            >
              {item.Icon && <CustomIcon iconName={item.Icon} />}
              {item.id ? <span id={item.id}><Label id={item.id} /></span> : <span id={text}>{text.toUpperCase()}</span>}
              {item.postTextIcon && <CustomIcon className={item.postTextIconClassName} iconName={item.postTextIcon} />}
            </MenuItem>
          );
        }
        )}
      </Menu>
    )
  );
};

SubMenu.propTypes = {
  anchor: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  onClose: PropTypes.func.isRequired,
  options: PropTypes.array,
  navigate: PropTypes.func,
  activeSubmenuItem: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string
};

export default SubMenu;
