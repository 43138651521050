import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import headerLogo from '../../assets/asce_dell_childrens_health_plan_logo.svg';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles(() => ({
    mainMenuLabel: {
        fontWeight: 600,
        textTransform: 'uppercase',
        color: 'rgba(0, 0, 0, 0.6)',
        fontSize: 12
    },
    customAppBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        boxShadow: 'none'
    },
    headerWrapper: {
        paddingLeft: '48px',
        paddingRight: '48px'
    },
    topBarLinkWrapper: {
        padding: '11px 60px',
        backgroundColor: '#ffffff',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
    },
    topBarLinkLanguage: {
        fontSize: 12,
        color: 'rgba(0, 0, 0, 0.6)',
        letterSpacing: 1,
        cursor: 'pointer',
        textDecoration: 'underline'
    },
    topBarLink: {
        fontSize: 12,
        letterSpacing: 1,
        marginRight: 40,
        color: 'rgba(0, 0, 0, 0.6)',
        cursor: 'pointer',
        textDecoration: 'underline'
    },
    topBarContactLink: {
        fontSize: 12,
        letterSpacing: 1,
        marginRight: 40,
        color: 'rgba(0, 0, 0, 0.6)',
        cursor: 'auto',
        textDecoration: 'underline'
    }
}));


const BannerLogoOnly = () => {
    const classes = useStyles();
    return (
        <>
            <AppBar
                position="static"
                sx={{ backgroundColor: '#fff', padding: '12px' }}
                className={classes.customAppBar}
                elevation={0}
            >
                <Toolbar disableGutters className={classes.headerWrapper}>
                    <a href="/">
                        <img src={headerLogo} height="53px" alt="brand" />
                    </a>
                </Toolbar>
            </AppBar>
        </>
    );
};

export default BannerLogoOnly;
