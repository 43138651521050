import React from 'react';
import PropTypes from 'prop-types';
import Label from '../type/Label';
import { useSelector } from 'react-redux';
import { PathNames } from '../../../constants/AppConstants';
import { analyticsTrackEvent } from '../../../utils/analytics/analytics-utils';

function TextWithContact(props) {
    const tenantInfo = useSelector(state => state.tenant.tenantInfo);
    const tenantResource = useSelector(state => state.tenant.tenanantResource);
    var path = PathNames && PathNames[props.pathName];
    if (props.external) {
        path = tenantResource[props.pathName];
    }
    const handleContactClick = () => {
        /** Amplitude analytics implementation - Logging click events **/
        analyticsTrackEvent('home_header_contactsupport_clicked');
    };
    return (
        <a id={props.id} className={props.className} href={path} onClick={handleContactClick}><Label id={props.id}/> <span className={props.contactClassName}>{tenantInfo && tenantInfo[props.contact]}</span></a>
    );
}
TextWithContact.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string,
    contact: PropTypes.string,
    contactClassName: PropTypes.string,
    pathName: PropTypes.string,
    external: PropTypes.string,
};

export default TextWithContact;
