import * as React from 'react';
import UnidentifiedBanner from './unidentified/UnidentifiedBanner';
import { useMsal } from '@azure/msal-react';
import { Button, Card, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router';
import warningSvg from '../../assets/warning.svg';
import Label from '../common/type/Label';
import { useSelector } from 'react-redux';


const useStyles = makeStyles(theme => ({
 
  container: {
    boxShadow: '0px 4px 5px rgb(0 0 0 / 7%), 0px 1px 10px rgb(0 0 0 / 6%), 0px 2px 4px rgb(0 0 0 / 10%) !important',
    borderRadius: '8px !important',
    maxWidth: '700px',
    margin: '4% auto '
  },
  contentHeader: {
    margin: '50px 56px auto 56px'
  },
  warning: {
    color: '#FF7F00',
    textAlign: 'center',
    paddingBottom: '40px',
  },
  subTitle: {
    marginTop: '32px !important',
  },
  list: {
    paddingLeft: '12px',
    '& > li': {
      fontWeight: 'bold',
      marginBottom: '16px'
    }
  },
  phone: {
    fontWeight: 'bold',
    color: '#1E69D2',
    textDecoration: 'none'
  },
  buttonWrapper: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
    textAlign: 'right',
    
  },
  phoneWrapper: {
      paddingTop: '8px !important'
  },
  phoneButton: {
    color: '#1E69D2',
    marginRight: theme.spacing(3),
    '& svg': {
      marginRight: theme.spacing(1.5)
    },
    
  }
}));

const Unverified = () => {
    const classes = useStyles();
    const { instance } = useMsal();
    const navigate = useNavigate();
  
    const account = instance.getActiveAccount();
    const tenantInfo = useSelector(state => state.tenant.tenantInfo);
  
    const handleEditInformation = () =>{
      navigate('manageProfile');
    };
  
    const getDisplayName = acc => {
      if (acc && acc.idTokenClaims && acc.idTokenClaims.firstName) {
        return acc.idTokenClaims.firstName;
      } else {
        return '';
      }
    };

  return (
    <>
      <UnidentifiedBanner />
      <Card className={classes.container}>
          <CardContent className={classes.contentHeader}>
        <div className={classes.warning} id='WarningIcon'>
          <img src={warningSvg} alt="Warning" />
        </div>
        <Typography variant="h4" gutterBottom id='title'>
           <Label id='hello' /> {getDisplayName(account)},
        </Typography>
        <Typography id='unableTofindAccountInformation'>
            <Label id='unableTofindAccountInformation' />
        </Typography>
        <Typography className={classes.subTitle} id='multipleReason'>
            <Label id='multipleReason' />
        </Typography>
        <ol className={classes.list}>
          <li>
            <Typography style={{ marginBottom: '8px', fontWeight: 700 }} id='enrolledWithinLast5days'>
                <Label id='enrolledWithinLast5days' />
            </Typography>
            <Typography color="textSecondary" id='waitTill5Days'>
                <Label id='waitTill5Days' />
            </Typography>
          </li>
          <li>
            <Typography id='InformationNotExactlyMatch'  style={{ marginBottom: '8px', fontWeight: 700 }}>
                <Label id='InformationNotExactlyMatch' />
            </Typography>

            <Typography id='useLegalInformation' color="textSecondary">
                <Label id='useLegalInformation' />
            </Typography>
          </li>
        </ol>
        <Typography id='havingIssueCallSupport' className={classes.phoneWrapper}>
          <Label id='havingIssueCallSupport' />{' '}
          <a href="memberSuppport" className={classes.phone} id='supportNumber'>
            {tenantInfo && tenantInfo['otherNumber']}
          </a>
        </Typography>
        <div className={classes.buttonWrapper}>
          
          <Button
            variant="contained"
            color="primary"
            onClick={handleEditInformation}
            id='editInformation'
          >
            <Label id='editInformation' />
          </Button>
        </div>
        </CardContent>
      </Card>
      
    </>
  );
};

export default Unverified;
