import React from 'react';
import { useSelector } from 'react-redux';
import RowContainer from '../../common/CustomComponents/RowContainer';

const Benefits = () => {
  const tenantLayouts = useSelector(state => state.tenant.tenantLayout);
    return (
      tenantLayouts['benefits'] && 
        <div className={tenantLayouts['benefits'].className} id={tenantLayouts['benefits'].id}>
        {tenantLayouts['benefits'].sections.map((item,index) =>{
          return <RowContainer key={index} {...item} />;
        })}
        </div>
    );
};

export default Benefits;
