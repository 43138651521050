import { getLanguageCookies } from '../utils/content-utils';

const language = getLanguageCookies();

export let PathNames;

if (language === 'es') {
    PathNames = {
        'home': '/',
        'benefits': 'benefits',
        'findLoation': 'https://mysmarthealth.org/Location-Search ',
        'findDoctor':'https://mysmarthealth.org/Doctor-Search',
        'findADoctor':'https://web.healthsparq.com/healthsparq/public/#/one/insurerCode=HSQD_I&brandCode=HSQD&languageCode=ES',
        'benefitsOverview': 'benefits-overview',
        'benefitsWhatsCovered': 'benefits-whatscovered',
        'benefitsExtraBenefits': 'benefits-extrabenefits',
        'benefitsExtraBenefits#box-0': 'benefits-extrabenefits#box-0',
        'benefitsExtraBenefits#box-1': 'benefits-extrabenefits#box-1',
        'benefitsExtraBenefits#box-3': 'benefits-extrabenefits#box-3',
        'benefitsExtraBenefits#box-4': 'benefits-extrabenefits#box-4',
        'benefitsExtraBenefits#box-7': 'benefits-extrabenefits#box-7',
        'benefitsExtraBenefits#box-10': 'benefits-extrabenefits#box-10',
        'benefitsCoveredMembers': 'benefits-coveredmembers',
        'idCards': '/idCards',
        'settings': 'settings',
        'memberSupport': 'memberSuppport',
        'privacyPolicy': 'privacyPolicy',
        'termsOfUse': 'termsOfUse',
        'manageProfile': 'manageProfile',
        'customerCare':'tel:+1-855-921-6284',
        'medical':'medical',
        'pharmacy':'pharmacy',
        'active_direct':'active_direct',
        'truhearing':'truhearing',
        'findCare':'findCare',
        'payments':'payments',
        'claims':'claims',
        'support':'support',
        'testLogout':'testLogout'
    };
} else {
    PathNames = {
        'home': '/',
        'benefits': 'benefits',
        'findLoation': 'https://mysmarthealth.org/Location-Search ',
        'findDoctor':'https://mysmarthealth.org/Doctor-Search',
        'findADoctor':'https://dellchildrenstestweb.healthsparq.com/healthsparq/public/#/one/insurerCode=AGDCHP_I&brandCode=AGDCHP',
        'benefitsOverview': 'benefits-overview',
        'benefitsWhatsCovered': 'benefits-whatscovered',
        'benefitsExtraBenefits': 'benefits-extrabenefits',
        'benefitsExtraBenefits#box-0': 'benefits-extrabenefits#box-0',
        'benefitsExtraBenefits#box-1': 'benefits-extrabenefits#box-1',
        'benefitsExtraBenefits#box-3': 'benefits-extrabenefits#box-3',
        'benefitsExtraBenefits#box-4': 'benefits-extrabenefits#box-4',
        'benefitsExtraBenefits#box-7': 'benefits-extrabenefits#box-7',
        'benefitsExtraBenefits#box-10': 'benefits-extrabenefits#box-10',
        'benefitsCoveredMembers': 'benefits-coveredmembers',
        'idCards': '/idCards',
        'settings': 'settings',
        'memberSupport': 'memberSuppport',
        'privacyPolicy': 'privacyPolicy',
        'termsOfUse': 'termsOfUse',
        'manageProfile': 'manageProfile',
        'customerCare':'tel:+1-855-921-6284',
        'medical':'medical',
        'pharmacy':'pharmacy',
        'active_direct':'active_direct',
        'truhearing':'truhearing',
        'findCare':'findCare',
        'payments':'payments',
        'claims':'claims',
        'support':'support',
        'testLogout':'testLogout'
    };
}
