import React from 'react';
import CustomIcon from './CustomIcon';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { PathNames } from '../../../constants/AppConstants';

function IconComponent(props) {
    const tenantResource = useSelector(state => state.tenant.tenanantResource);

    var path = PathNames[props.pathName];
    if (props.external) {
        path = tenantResource[props.pathName];
    }

    return (
        <a href={path} className={props.className} id={props.id}>
            <CustomIcon iconName={props.icon} />
        </a>
    );
}
IconComponent.propTypes = {
    icon: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
    pathName: PropTypes.string,
    external: PropTypes.bool
};

export default IconComponent;
