import { Grid } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import ComponentMapper from './ComponentMapper';
import { useSelector } from 'react-redux';

function GridItem(props) {
    const tenantResource = useSelector(state => state.tenant.tenanantResource);

    return(
    props.items&&props.items.map((item,index)=>{
        const backgroundImage=item.backgroundImageId &&tenantResource[item.backgroundImageId];
       
        return (
            <Grid key={index} style={backgroundImage&&{backgroundImage:`url(${backgroundImage})`}} item xs={item.mobileGridWidth===''?'auto':item.mobileGridWidth} lg={item.desktopGridWidth===''?'auto':item.desktopGridWidth} className={item.className} id={item.id}>
               <ComponentMapper modules={item.props&&item.props}/>
            </Grid>
        );
    })
    );
}
GridItem.propTypes={
items:PropTypes.array,
mobileGridWidth:PropTypes.number,
desktopGridWidth:PropTypes.number,
className:PropTypes.string,
id:PropTypes.string,
backgroundImageId:PropTypes.string
};

export default GridItem;
