import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useLocation, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import RowContainer from './RowContainer';
import { useEffect } from 'react';
import { getSanitizedPathName } from '../functionUtils';
import Label from '../type/Label';
import { usePageTitle } from '../hooks/usePageTitle';
import CustomIcon from './CustomIcon';


function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <>
      {value === index && (
        children
      )}
    </>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

export default function TabsComponent(props) {
  const [value, setValue] = React.useState(0);
  const [tabConfig, setTabConfig] = React.useState(props.tabMenus && props.tabMenus[0]);
  const navigate = useNavigate();
  const location = useLocation();
  const activeSubmenuItem = getSanitizedPathName(location.pathname);
  usePageTitle(props.tabMenus[value].pageTitle);
  const handleChange = (event, newValue) => {
    navigate(`/${props.tabMenus[newValue].pathName}`);
    setValue(newValue);
    setTabConfig(props.tabMenus[newValue]);
  };

  useEffect(() => {
    const indexNumber = props.tabMenus.findIndex(tab => tab.pathName === activeSubmenuItem);
    const currentTabName = props.tabMenus.filter(tab => tab.pathName === activeSubmenuItem);
    setValue(indexNumber);
    setTabConfig(props.tabMenus[indexNumber]);
    
    /** Condition to avoid unnecessary call of navigate method **/
    currentTabName[0]?.pathName !== activeSubmenuItem && navigate(`/${activeSubmenuItem}`);
  }, [navigate]);

  const tenantLayouts = useSelector(state => state.tenant.tenantLayout);
  return (
    <>
      {
        tabConfig.banner && tenantLayouts[tabConfig.banner] &&
        tenantLayouts[tabConfig.banner].sections.map((item, index) => {
          return <RowContainer key={index} {...item} />;
        })}
      <Tabs value={value} className={props.className} id={props.id} onChange={handleChange}>
        {props.tabMenus.map((tab, index) => {
          return <Tab label={<Label id={tab.id} />} className={tab.className} id={tab.id} icon={tab.Icon &&<CustomIcon iconName={tab.Icon}/>} key={index} {...a11yProps(index)} />;
        })}
      </Tabs>

      { tabConfig.mainLayout && tenantLayouts[tabConfig.mainLayout] &&
        <div className={tenantLayouts[tabConfig.mainLayout].className} id={tenantLayouts[tabConfig.mainLayout].id}>
          {tenantLayouts[tabConfig.mainLayout].sections.map((item, index) => {
            return <RowContainer key={index} {...item} />;
          })
          }
        </div>
      }


    </>
  );
}
TabsComponent.propTypes = {
  tabMenus: PropTypes.array,
  className: PropTypes.string,
  id: PropTypes.string
};