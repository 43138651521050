import React from 'react';
import PropTypes from 'prop-types';
import { getLanguageCookies, setLanguageCookies } from '../../../utils/content-utils';
import { analyticsTrackEvent } from '../../../utils/analytics/analytics-utils';

function toggleLanguage(props) {
  const language = getLanguageCookies();
  const handleLanguage = (e) => {
    e.preventDefault();
    const text = e.target.innerHTML;

    /** Amplitude analytics implementation - Logging click events **/
    const eventName = text === props.label ? 'home_header_espanol_clicked' : 
    'home_header_english_clicked';
    analyticsTrackEvent(eventName);

    text === props.label
      ? setLanguageCookies(props.targetedLangugeType)
      : setLanguageCookies(props.defaultLanguageType);
    window.location.reload();
  };
  return (
    <>
      <span
        id={props.id}
        className={props.className}
        onClick={(e) => handleLanguage(e)}
      >
        {language.includes('en') ? props.label : props.targetlabel}
      </span>
    </>
  );
}
toggleLanguage.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  targetlabel: PropTypes.string,
};

export default toggleLanguage;
