import constants from './constants';

const { SET_ENROLLMENTS_DETAILS, SET_ENROLLMENTS_DETAILS_CLEANUP, SET_COVERAGES_DETAILS, SET_COVERAGES_DETAILS_CLEANUP } = constants;
export const initialState = [];

export const dependentEnrollmentsDetailsReducer = (
    state = initialState,
    { type, payload }
) => {
    switch (type) {
        case SET_ENROLLMENTS_DETAILS:
            return [...state, payload];

        case SET_ENROLLMENTS_DETAILS_CLEANUP:
            return initialState;

        default:
            return state;
    }
};

export const dependentCoveragesDetailsReducer = (
    state = initialState,
    { type, payload }
) => {
    switch (type) {

            case SET_COVERAGES_DETAILS:
                return [...state, payload];
    
            case SET_COVERAGES_DETAILS_CLEANUP:
                return initialState;

        default:
            return state;
    }
};
