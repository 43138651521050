
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import Label from '../../../type/Label';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const options = [
    'Family',
    'Martha Stewart',
    'Jimmy Stewart'
];

const PlanStatusMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const open = Boolean(anchorEl);
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box className="PlanStatusMenuWrapper" sx={isDesktop && {marginLeft: '20px'}}>
            <Typography variant="h3" className={isDesktop ? 'planStatusMenuText' : 'planStatusMenuTextMobile'}><Label id="planStatusMenuText"></Label>:</Typography>
            <Button
                variant="text"
                endIcon={<KeyboardArrowDownIcon fontSize="33" />}
                className={isDesktop ? 'planStatusMenuButton' : 'planStatusMenuButtonMobile'}
                id="plan-status-menu-button"
                aria-controls={open ? 'plan-status-menu-button' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                {options[selectedIndex]}
            </Button>

            <Menu
                id="plan-status-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'plan-status-menu',
                }}
            >

                {options.map((option, index) => (
                    <MenuItem
                        sx={{width: 300}}
                        key={option}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                    >
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};



export default PlanStatusMenu;