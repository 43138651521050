import React from 'react';
import PropTypes from 'prop-types';
import CustomIcon from './CustomIcon';
import Label from '../type/Label';
import { Box } from '@mui/material';


function BannerAdditionalInfo(props) {

    return (
        <>
            <Box className='bannerAddInfoWrapper'>
                <Box>
                    <CustomIcon iconName={props.icon} className='bannerAddInfoIcon'/>
                </Box>
                <Box>
                    <p className='bannerAddInfoTitle'><Label id={props?.title?.id} /></p>
                    <p className='bannerAddInfoSubTitle'><Label id={props?.subTitle?.id} /></p>
                </Box>
            </Box>


        </>
    );
}

BannerAdditionalInfo.propTypes = {
    icon: PropTypes.any,
    title: PropTypes.string,
    subTitle: PropTypes.string,
};

export default BannerAdditionalInfo;
