import React from 'react';
import { useSelector } from 'react-redux';
import RowContainer from '../common/CustomComponents/RowContainer';

const PrivacyPolicy = () => {
  const tenantLayouts = useSelector(state => state.tenant.tenantLayout);
    return (
      tenantLayouts['PrivacyPolicy'] && 
        <div className={tenantLayouts['PrivacyPolicy'].className} id={tenantLayouts['PrivacyPolicy'].id}>
        {tenantLayouts['PrivacyPolicy'].sections.map((item,index) =>{
          return <RowContainer key={index} {...item} />;
        })}
        </div>
    );
};

export default PrivacyPolicy;