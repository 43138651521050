import { memberApi } from '../config/axios-config';


export const getClaimsService = (tenantId,requestBody,pageNumber,pageSize) => {
  return memberApi.post(`/v3/my/claims?ppage=${pageNumber}&ppageSize=${pageSize}&pskipRows=0`, requestBody,{ headers: { 'tenant-id':tenantId  }});
};


export const getClaimDetailsService = (claimId, tenantId) => {
    return memberApi.get(`v3/my/claims/${claimId}`, {headers: { 'tenant-id':tenantId  }});
};