export const HYPER_LINK='hyperlink';
export const IMAGE='image';
export const MENU_BUTTON='button';
export const ICON_WITH_BUTTON='iconWithButton';
export const DIVIDER='divider';
export const TEXT='text';
export const ICON='icon';
export const NAVTAB='navTab';
export const TABS='tabs';
export const LANG='language';
export const TEXTWITHCONTACT='textWithContact';
export const HTMLSTRING='htmlText';
export const SIDENAVBAR='sideNavbar';
export const WELCOMETEXT='welcomeText';
