import constants from './constants';

const { SET_MEMBER_ACCOUNTS_BEGIN, SET_MEMBER_ACCOUNTS_SUCCESS, SET_MEMBER_ACCOUNTS_ERROR, SET_MEMBER_ACCOUNTS_CLEANUP, SET_MEMBER_ID_BEGIN, SET_MEMBER_ID_SUCCESS, SET_MEMBER_ID_ERROR, SET_MEMBER_ID_CLEANUP } = constants;
export const initialState = {
    memberAccountsDetails: null,
    memberIdCards: null,
    loading: false,
    error: null,
    isMemberAccountsUpdated: false
};

export const memberAccountsReducer = (state = initialState, { type, payload }) => {
    let isMemberAccountsUpdated = false;
    if (type === 'SET_MEMBER_ACCOUNTS_SUCCESS') {
        const selfMemberCheck = checkSelfResponseChange(state?.memberAccountsDetails?.self, payload?.data?.self);
        const dependentsCheck = checkDependentsResponseChange(state?.memberAccountsDetails?.dependents, payload?.data?.dependents);
        isMemberAccountsUpdated = state.memberAccountsDetails === null || !(selfMemberCheck && dependentsCheck);
    }
    switch (type) {
        case SET_MEMBER_ACCOUNTS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case SET_MEMBER_ACCOUNTS_SUCCESS:
            return {
                ...state,
                memberAccountsDetails: payload.data,
                loading: false,
                isMemberAccountsUpdated: isMemberAccountsUpdated
            };

        case SET_MEMBER_ACCOUNTS_ERROR:
            return {
                ...state,
                memberAccountsDetails: {},
                loading: false,
                error: payload.error
            };

        case SET_MEMBER_ACCOUNTS_CLEANUP:
            return initialState;

        case SET_MEMBER_ID_BEGIN:
                return {
                    ...state,
                    loading: true,
                    error: null
                };
    
        case SET_MEMBER_ID_SUCCESS:
                return {
                    ...state,
                    memberIdCards: payload.data,
                    loading: false
                };
    
        case SET_MEMBER_ID_ERROR:
                return {
                    ...state,
                    memberIdCards: {},
                    loading: false,
                    error: payload.error
                };
    
        case SET_MEMBER_ID_CLEANUP:
                return {
                    ...state,
                    memberIdCards: null,
                    loading: false
                };

        default:
            return state;
    }
};


const getMemberAccountId = (item) => {
    return `${item?.groupId}-${item?.subscriberId}-${item?.subscriberSuffix}`;
};


const checkSelfResponseChange = (stateValue, payloadValue) => {
    return getMemberAccountId(stateValue) === getMemberAccountId(payloadValue);
};


const checkDependentsResponseChange = (stateArray, payloadArray) => {
    const dependentsMAHeaderFromState = stateArray && stateArray?.map((item) => {
        return getMemberAccountId(item);
    });
    const dependentsMAHeaderFromPayload = payloadArray && payloadArray?.map((item) => {
        return getMemberAccountId(item);
    });
    return dependentsMAHeaderFromState?.sort()?.toString() === dependentsMAHeaderFromPayload?.sort()?.toString();
};