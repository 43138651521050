import React from 'react';
import PropTypes from 'prop-types';
import Label from '../type/Label';
import { Typography } from '@mui/material';

function CustomText (props)  {
    if(props.variant){
        return(
            <Typography className={props.className} variant={props.variant}>{props.id? <Label id={props.id} />: props.children}</Typography>
        );
    }
   
    return (
        <p className={props.className} id={props.id}>{props.id? <Label id={props.id} />: props.children}</p>
    );
}
CustomText.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.array,PropTypes.string]),
    variant: PropTypes.string
};

export default CustomText;
