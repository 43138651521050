import React from 'react';
import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import AscensionCard from './AscensionCard';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Label from '../../common/type/Label';
import CustomIcon from '../../common/CustomComponents/CustomIcon';
import { PathNames } from '../../../constants/AppConstants';
import { getLanguageCookies } from '../../../utils/content-utils';
import { analyticsTrackClickEvent } from '../../../utils/analytics/analytics-utils';


const ListCards = ({ config }) => {
  const language = getLanguageCookies();
  const tenantResource = language === 'es' ? useSelector(state => state.tenant.tenanantResourceES) : useSelector(state => state.tenant.tenanantResource);
  const tenantLayouts = useSelector(state => state.tenant.tenantLayout);
  const links = tenantLayouts && tenantLayouts[config];
  const customText = text => {
    return <p className={text.className}><Label id={text.id} /> </p>;
  };

  return (
    <AscensionCard {...links}>
      <List component="nav" >
        {links.listItems &&
          links.listItems.map((item, index) => (
            <ListItem
              button
              key={index}
              component="a"
              target={item.external === true ? '_blank' : ''}
              href={
                item.external === true
                  ? tenantResource[item.pathName]
                  : PathNames[item.pathName]
              }
              id={item.id}
              className={item.className}
              onClick={() => {
                analyticsTrackClickEvent(item?.eventName, {eventCategory: 'Home Page Pathing'});
              }}
            >
              <ListItemIcon>
                <CustomIcon iconName={item.preTextIcon} />
              </ListItemIcon>
              <ListItemText>
                <Label id={item.id} />
                {item.customText && customText(item.customText)}
              </ListItemText>

              <CustomIcon iconName={item.postTextIcon} />
            </ListItem>
          ))}
      </List>
    </AscensionCard>
  );
};

ListCards.propTypes = {
  config: PropTypes.any
};

export default ListCards;
