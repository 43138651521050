import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ClaimDetails from './ClaimDetails';
import { Box, Divider, Grid, List, Paper } from '@mui/material';
import TextComponent from '../../CustomComponents/TextComponent';
import IconComponent from '../../CustomComponents/IconComponent';
import { useDispatch, useSelector } from 'react-redux';
import CustomIcon from '../../CustomComponents/CustomIcon';
import {  getClaims } from '../../../../redux/claims/actions';

const RecentClaims = (props) => {
  const tenantLayouts = useSelector(state => state.tenant.tenantLayout);
  const config = tenantLayouts[props.config];
  const dispatch = useDispatch();
  var claimsData = useSelector(store => store.claims.claims);
  // dummy data to test
claimsData= [{
  'firstName': 'Sadia',
  'totCharge' : 600,
  'providerName':'Provider / Facility',
  'claimNumber':'11241515566',

  'claimDetails':{
    'totalYouOwe':'$200'
  }},
  {
    'firstName': 'Sadia',
  'providerName':'Provider / Facility',
  'claimNumber':'11241515566',
  'totCharge' : 600,
    'claimDetails':{
      'totalYouOwe':'$200'
    }
  }
];
   useEffect(() => {
    dispatch(getClaims(localStorage.getItem('tenantHandle'),{ orderByDate: 'DESC' }, 1, 3));
  }, [dispatch]);

  return ( config && 
    <Paper className={config.className} id={config.id} style={{height: '100%'}} >
      <Box className='homeRecentClaimsTitleHeading'>
        <TextComponent variant="h3" className='recentClaimCardTitleText' id='recentClaimCardTitle' />
        <IconComponent className='recentClaimCardTitleIcon' id='recentClaimCardTitleIcon' icon='NavigateNext' pathName='claims' />      </Box>
      <List className='homeClaimsDetailsWrapper'>
        {claimsData && !claimsData.length  ? (
          <Grid
            item
            xs={12}
            className='homeRecentClaimsNoClaimsHeader'
          >
            <CustomIcon iconName='custom-claimsMessageIcon' className='claimsMessageIcon' />
            <TextComponent className='homeNoClaimsText' id='homeNoClaimsText'  />
          </Grid>
        ) : (
          claimsData.map((claim, index) => (
            (index < 3) && (<div key={claim.claimNumber}>
           <ClaimDetails claim={claim} options={config.claimsDetailsMenu} />
           {index !== claimsData.length  && <Divider variant="inset" className='homeRecentClaimsDivider' />}
         </div>)
          ))
        )}
      </List>
    </Paper>
  );
};

RecentClaims.propTypes = {
  config: PropTypes.string
};

export default RecentClaims;
