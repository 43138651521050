import React from 'react';
import PropTypes from 'prop-types';
import { Box, ListItemText } from '@mui/material';
import {
  captilizeString,
  formatDate,
  mapPlanName
} from '../../../common/functionUtils';
import Label from '../../../common/type/Label';
import moment from 'moment';
import CustomIcon from '../../../common/CustomComponents/CustomIcon';
//Plan Details is Multi tenant
const PlanDetails = ({ enrollment, coverage, data, info, id }) => {
  const effectiveDate = coverage?.length && coverage[0].effectiveDate;
  const terminationDate = coverage?.length && coverage[0].terminationDate;

  const checkValidTerminationDateExist = (date) => {
    return moment(date).format('YYYY') !== '9999';
  };

  const checkValidEffectiveDateExist = (date) => {
    return date?.length ? true : false;
  };
  return (
    <Box>
      {data?.firstName && (
        <Box className={info.listItem.className}>
          <CustomIcon
            iconName={info.listItem.icon}
            className={info.listItem.iconClassName}
          />
          <ListItemText id={id}>
            <span> {captilizeString(data?.firstName)} {captilizeString(data?.lastName)} </span> <Label id={info.listItem.enrollmentPlanLabelId} /> {mapPlanName(enrollment?.planDescription)}{checkValidEffectiveDateExist(effectiveDate) ? <> <Label id={info.listItem.fromDateLabelId} /> <span className="boldContent">{formatDate(effectiveDate)}</span></> : ''} {checkValidTerminationDateExist(terminationDate) ? <><Label id={info.listItem.throughDateLabelId} /> <span>{formatDate(terminationDate)}</span></> : ''}
          </ListItemText>
        </Box>
      )}
    </Box>
  );
};

PlanDetails.propTypes = {
  enrollment: PropTypes.object,
  coverage: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  index: PropTypes.number,
  data: PropTypes.object,
  info: PropTypes.object,
  id:PropTypes.string,
};

export default PlanDetails;
