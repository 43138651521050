import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container,  Card, Grid, Box, Button, CircularProgress } from '@mui/material';
import { usePageTitle } from '../../common/hooks/usePageTitle';
import { captilizeString } from '../../common/functionUtils';
import { getMemberIDCard, setMemberIDClean } from '../../../redux/memberAccounts/action';
import Label from '../type/Label';
import { analyticsTrackEvent } from '../../../utils/analytics/analytics-utils';

const IdCardMemberCard=()=> {
    const dispatch = useDispatch();
  const [memberList, setMemberList] = useState([]);
  const [enrollmentsList, setEnrollmentsList] = useState([]);
  const memberAccounts = useSelector(
    state => state?.memberAccounts?.memberAccountsDetails
  );
  const selfEnrollmentsFromStore = useSelector(state => state?.enrollments?.enrollmentsDetails);
  const dependentEnrollments = useSelector(state => state?.dependentEnrollments);
  const medicalCard = useSelector(state => state?.memberAccounts?.memberIdCards);
  const medicalCardLoading = useSelector(state => state?.memberAccounts?.loading);
  const [buttonIndex, setButtonIndex] = useState(null);

  usePageTitle('ID Cards');

  useEffect(() => {
    if (medicalCard) {
      const img = `<title>Member ID Card</title><div><br /><div style="width: 600px; text-align: right"><a style="padding: 10px 20px; border: 1px solid #B40F87; background: #ffffff; color: #B40F87; border-radius: 3px; corsor: pointer; text-decoration: none;" title="Click here to download" download="DCHP-ID-card.png" href="data:image/png;base64,${medicalCard}">Download</a></div><br /><br /><div><img src="data:image/png;base64,${medicalCard}" height="800px"/></div><br /><br /></div>`;
      let element = window.open();
      element.document.write(img);
      dispatch(setMemberIDClean());
    }
  }, [medicalCard]);

  useEffect(() => {
    if (memberAccounts?.self || memberAccounts?.dependents)
      memberAccounts?.self ?
        setMemberList([{ ...memberAccounts.self }, ...memberAccounts.dependents]) :
        setMemberList([null, ...memberAccounts.dependents]);
  }, [memberAccounts]);

  useEffect(() => {
    dependentEnrollments.length ?
      setEnrollmentsList([selfEnrollmentsFromStore, ...dependentEnrollments]) :
      setEnrollmentsList([selfEnrollmentsFromStore]);
  }, [dependentEnrollments, selfEnrollmentsFromStore]);

  const handleViewId = (groupId, subscriberId, subscriberSuffix, i) => {
    setButtonIndex(i);
    const { self: selfFromStore } = memberAccounts;
    let memberAccountHeader = '';
    if (selfFromStore || memberAccounts?.dependents.length) {
      memberAccountHeader = `${groupId}-${subscriberId}-${subscriberSuffix}`;
    }
    dispatch(getMemberIDCard(memberAccountHeader, localStorage.getItem('tenantHandle')));
  };

    return (
        <Box sx={{ backgroundColor: '#FAFAFA' }}>
         <Container sx={{ marginBottom: 9, marginTop: 6 }}>
          <Box
            sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}
            className="tablet-desktop-view"
          >
            <Card
              sx={{
                filter:
                  'drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.07)) drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.06)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1))',
                paddingLeft: 3,
                paddingRight: 3,
                display: memberList?.length > 0 ? 'block' : 'none'
              }}
            >
              {memberList.map((item, i) => (
                <Box key={i}>
                  {item?.firstName && <Box
                    key={`healthcare-id-card-mobile-${i}`}
                    sx={[
                      { paddingTop: 3, paddingBottom: 3, width: 1 },
                      i < memberList.length - 1 && {
                        borderBottom: '1px solid #D3D3D3'
                      }
                    ]}
                  >
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item xs>

                        <Box>
                          <strong id={item?.firstName}>{`${captilizeString(item?.firstName)} ${captilizeString(item?.lastName)}`}</strong>
                        </Box>
                        {<Box>
                          <strong>ID Number</strong>
                          {'  '}
                          {item?.subscriberId}
                        </Box>}
                      </Grid>
                      <Grid item>
                        <Button
                          id={'btn-'+ item?.firstName}
                          disabled={!enrollmentsList[i]?.hasMedicalInsurance}
                          variant="outlined"
                          color="secondary"
                          onClick={e => {
                            e.preventDefault();
                            handleViewId(item?.groupId, item?.subscriberId, item?.subscriberSuffix);
                            analyticsTrackEvent('idcards_IDdownload_clicked', {eventCategory: 'Plan Benefits Pathing'});
                          }}
                        >
                          {enrollmentsList[i]?.hasMedicalInsurance ? <Label id='idCardsView' /> : <Label id='unavailable'/>}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>}
                </Box>
              ))}
            </Card>
          </Box>
          <Box
            sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
            className="tablet-desktop-view"
          >
            <Card
              sx={{
                filter:
                  'drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.07)) drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.06)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1))',
                paddingLeft: 3,
                paddingRight: 3,
                display: memberList?.length > 0 ? 'block' : 'none'
              }}
            >
              {memberList.map((item, i) => (
                <Box key={i}>
                  {item?.firstName && <Box
                    key={`healthcare-id-card-${i}`}
                    sx={[
                      { paddingTop: 3, paddingBottom: 3 },
                      i < memberList.length - 1 && {
                        borderBottom: '1px solid #D3D3D3'
                      }
                    ]}
                  >
                    <Grid container alignItems="center">
                      <Grid item xs>
                        <strong id={item?.firstName}>{`${captilizeString(item?.firstName)} ${captilizeString(item?.lastName)}`}</strong>
                      </Grid>
                      <Grid item xs={3}>
                        <Button
                          id={'btn-'+ item?.firstName}
                          disabled={!enrollmentsList[i]?.hasMedicalInsurance}
                          sx={{ width: '230px' }}
                          variant="outlined"
                          color="secondary"
                          onClick={e => {
                            e.preventDefault();
                            handleViewId(item?.groupId, item?.subscriberId, item?.subscriberSuffix, i);
                            analyticsTrackEvent('idcards_IDdownload_clicked', {eventCategory: 'Plan Benefits Pathing'});
                          }}
                        >
                          {buttonIndex === i && medicalCardLoading && <CircularProgress size="15px" color='secondary' sx={{ marginRight: '5px' }} />}
                          {enrollmentsList[i]?.hasMedicalInsurance ? <Label id='idCardsView' /> : <Label id='unavailable'/>}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>}
                </Box>
              ))}
            </Card>
          </Box>
        </Container>
      </Box>
    );
};

export default IdCardMemberCard;
