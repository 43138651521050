import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@mui/material';

function CustomDivider(props){
    return (
            <Divider  className={props.className} id={props.id}  flexItem />
    );
}

CustomDivider.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string
};

export default CustomDivider;
