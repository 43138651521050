import React from 'react';
import PropTypes from 'prop-types';
import { DIVIDER, HYPER_LINK, ICON, ICON_WITH_BUTTON, IMAGE, MENU_BUTTON, TEXT, NAVTAB, TABS, LANG,HTMLSTRING, TEXTWITHCONTACT,SIDENAVBAR, WELCOMETEXT } from './ComponentTypes';
import { BANNERADDITIONALINFO, PLANSTATUSWITHCARDS, FINDCARE } from './CustomComponentTypes';
import Image from './Image';
import HyperLink from './HyperLink';
import MenuButton from './MenuButton';
import IconWithButton from './IconWithButton';
import DividerComponent from './DividerComponent';
import TextComponent from './TextComponent';
import IconComponent from './IconComponent';
import NavTabComponent from './NavTabComponent';
import TabsComponent from './TabsComponent';
import toggleLanguage from './toggleLanguage';
import CustomComponent from './CustomComponent';
import TextWithContact from './TextWithContact';
import HtmlText from './HtmlComponent';
import SideNavbar from './SideNavbar';
import WelcomeText from './WelcomeText';
import BannerAdditionalInfo from './BannerAdditionalInfo';
import PlanStatusWithCards from './smartHealth/planStatusSection/PlanStatusWithCards';
import FindCare from './smartHealth/FindCare';

const ComponentsList={
    [IMAGE]:Image,
    [HYPER_LINK]:HyperLink,
    [MENU_BUTTON]:MenuButton,
    [ICON_WITH_BUTTON]:IconWithButton,
    [DIVIDER]:DividerComponent,
    [TEXT]:TextComponent,
    [ICON]:IconComponent,
    [NAVTAB]:NavTabComponent,
    [LANG]:toggleLanguage,
    [TABS]:TabsComponent,
    [TEXTWITHCONTACT]:TextWithContact,
    component:CustomComponent,
    [HTMLSTRING]:HtmlText,
    [SIDENAVBAR]:SideNavbar,
    [WELCOMETEXT]:WelcomeText,
    [BANNERADDITIONALINFO]: BannerAdditionalInfo,
    [PLANSTATUSWITHCARDS]: PlanStatusWithCards,
    [FINDCARE]: FindCare
};

function ComponentMapper(props) {
    return (props.modules && props.modules.map((module, index) => {
          const Component=  ComponentsList[module.type];
         return <Component  key={index} {...module}/>;
    })
    );
}
ComponentMapper.proptypes = {
    modules: PropTypes.array
};


export default ComponentMapper;
