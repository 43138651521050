import constants from './constants';
import { trackPromise } from 'react-promise-tracker';
import { memberVerificationService } from '../../services/memberAccountsService';

const { SET_MEMBER_VERIFICATION_BEGIN, SET_MEMBER_VERIFICATION_SUCCESS, SET_MEMBER_VERIFICATION_ERROR, SET_MEMBER_VERIFICATION_CLEANUP } = constants;

export const setMemberVerificationBegin = () => ({
    type: SET_MEMBER_VERIFICATION_BEGIN
});

export const setMemberVerificationSuccess = data => ({
    type: SET_MEMBER_VERIFICATION_SUCCESS,
    payload: { data }
});

export const setMemberVerificationError = error => ({
    type: SET_MEMBER_VERIFICATION_ERROR,
    payload: { error }
});

export const setMemberVerificationClean = () => ({
    type: SET_MEMBER_VERIFICATION_CLEANUP
});

export const memberVerification = (reqBody, tenantId) => {
    return (dispatch) => {
        dispatch(setMemberVerificationBegin());
        trackPromise(
            memberVerificationService(reqBody, tenantId)
                .then(res => dispatch(setMemberVerificationSuccess(res)))
                .catch(err => dispatch(setMemberVerificationError(err)))
        );
    };
};
