export default {
    SET_COVERAGES_BEGIN: 'SET_COVERAGES_BEGIN',
    SET_COVERAGES_SUCCESS: 'SET_COVERAGES_SUCCESS',
    SET_COVERAGES_ERROR: 'SET_COVERAGES_ERROR',
    SET_COVERAGES_CLEANUP: 'SET_COVERAGES_CLEANUP',
    SET_COVEREDMEMBER_BEGIN: 'SET_COVEREDMEMBER_BEGIN',
    SET_COVEREDMEMBER_SUCCESS: 'SET_COVEREDMEMBER_SUCCESS',
    SET_COVEREDMEMBER_ERROR: 'SET_COVEREDMEMBER_ERROR',
    SET_COVEREDMEMBER_CLEANUP: 'SET_COVEREDMEMBER_CLEANUP'
  };
  