import { useEffect } from 'react';
import { setupInterceptors } from '../../config/axios-config';


const AxiosInterceptors = () => {

  useEffect(() => {
    setupInterceptors();
  }, []);

  return null;
};

export default AxiosInterceptors;
